/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import _ from "lodash";
import { useQuery, useClient } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import { AppContext } from "../../context/AppContext";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from 'react-toast-notifications';
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
import { parseIdentificationType } from "../../helpers/helpers";

const DeliveryRegular = ( {location} ) => {
    const { addToast } = useToasts();



    const { showModal, manageModal, pagePermissions, currentUser, initialPageParams, updateInitialPageParams, setPageUrlParams } = React.useContext(AppContext);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [currentUserId, setCurrentUserId] = React.useState(0);
    const [searchQueryString, setSearchQueryString] = React.useState('');
    const token = localStorage.getItem('access_token');
    const allowNewApplicant = localStorage.getItem('allowNewApplicant');

    const [searchGoingOn, setSearchGoingOn] = React.useState(false);
    const [activePage, setactivePage] = React.useState(1);

    const history = useHistory();
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };


    let paginationSettings;
    if (searchGoingOn) {
      paginationSettings = {};
    } else {
      paginationSettings = {
        number: currentPageNumber,
        size: rowsPerPage,
      };
    }

    const deliveryRegularLists = useQuery(['applicant', 'profile', {
        filter: {
            type: 'delivery_regular',
            search: searchQueryString
        },
        page: paginationSettings
    }], { client });

    React.useEffect(() => {
      if (location) {
        updateInitialPageParams(location);
      }
    }, []);

    React.useEffect(() => {
      if(initialPageParams){
        setCurrentPageNumber(initialPageParams.initialPageNumber);
        setactivePage(initialPageParams.initialPageNumber);
        setSearchQueryString(initialPageParams.initialSearchKey)
      }
    },[initialPageParams]);

    const manageDeliveryRegular = () => {
        history.push(VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_MANAGE);
    };

    const updateDeliveryRegular = id => {
        history.push({
            pathname: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT,
            search: `?id=${id}`,
        });
    };

    const viewDeliveryRegular = id => {
        history.push({
            pathname: VW_ROUTES.APPLICANTS_APPLICANT_VIEW,
            search: `?id=${id}&type=delivery_regular`,
        });
    };

    const deleteUserDetails = async () => {
        const { error } = await client.delete(['applicant', 'profile', currentUserId]);
        if (error === undefined) {
            deliveryRegularLists.refetch();
            addToast(DISPLAY_TEXTS.DELIVERY_REGULAR_DELETED, { appearance: 'success' });
        } else {
            addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: 'warning' });
        }
        setCurrentUserId(0);
        manageModal(false);
        removeBodyClass('modal-open');
    };

    const manageDeletedOption = id => {
        setCurrentUserId(id);
        addBodyClass('modal-open');
        manageModal(true);
    };

    const doCloseModal = () => {
        setCurrentUserId(0);
        removeBodyClass('modal-open');
        manageModal(false);
    };

    const customStyles = {

        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '14px'
            },
        }
    };

    // const parseIdentificationType = user => {
    //     return user.nricAlpha !== "" ? `${user.nricNumber}${user.nricAlpha}(NRIC)` : user.finAlpha !== "" ? `${user.finNumber}${user.finAlpha}(FIN)` : `${user.passport}(Passport)`
    // };

    const columns = [
        {
            name: 'Full Name',
            selector: 'fullName',
            sortable: true,
        },
        // {
        //     name: 'Last Name',
        //     selector: 'lastName',
        //     sortable: true
        // },
        {
            name: 'Identification#',
            sortable: true,
            cell: deliveryRegular => <span className="text-uppercase text-left">{parseIdentificationType(deliveryRegular)}</span>
        },
        {
            name: 'Mobile#',
            selector: 'mobileNumber',
            sortable: true
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true
        },
        {
            name: 'Vehicle#',
            selector: 'vehicleNumber',
            sortable: false
        },
        {
            name: 'Designation',
            selector: 'designation',
            sortable: true
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true
        },
        {
            name: 'Actions',
            sortable: true,
            cell: deliveryRegular => <>
                {_.includes(pagePermissions, 'view') && <span className="simple-icon-eye user-role-action-icons view" onClick={() => viewDeliveryRegular(deliveryRegular.id)}></span>}
                {_.includes(pagePermissions, 'edit') && editPermissionAC(deliveryRegular.status) && <span className="iconsminds-pen user-role-action-icons edit" onClick={() => updateDeliveryRegular(deliveryRegular.id)}></span>}
                {_.includes(pagePermissions, 'delete') && <span onClick={() => manageDeletedOption(deliveryRegular.id)} className="simple-icon-trash user-role-action-icons delete"></span>}
            </>,
            right: true
        }
    ];


    const editPermissionAC = (status) => {
      if(currentUser.role_slug === 'account-coordinator'){
        if(status === "expired" || status === "pending" || status === "approved" || status === "rejected" || status === "deactivated"){
          return false
        }else{
          return true
        }
      }else{
        return true
      }
    }

    let paginationGoing = false;
    const onChangePage = page => {
      setSearchGoingOn(false);
      paginationGoing = true;
      setCurrentPageNumber(page);
      const setParams = {
        page : page,
        searchQueryString : searchQueryString,
        initialPageParams: initialPageParams
      }
      setPageUrlParams(setParams)
    }

    const onChangeRowsPerPage = rows => {
        setRowsPerPage(rows);
    }

    let searchGoing = false;
    const onSearchInputChange = (searchQuery) => {
      searchGoing = true;
      setSearchGoingOn(true);
      setSearchQueryString(searchQuery);
      setactivePage(1);
      const setParams = {
        page : 1,
        searchQueryString : searchQuery,
        initialPageParams: initialPageParams
      }
      setPageUrlParams(setParams)
    };


    window.onpopstate = (e) => {
      if(!searchGoing && !paginationGoing){
        const searchWord = window.location.hash.split("search=")[1];
        const wordString = decodeURI(searchWord);
        if (searchWord) {
          setSearchQueryString(wordString);
        }else{
          setSearchQueryString('');
        }

        const pageNumber = window.location.hash.split("page=")[1];
        if(pageNumber){
          const pageFromUrl = pageNumber.split("&")[0];
          setCurrentPageNumber(Number(pageFromUrl))
        }else{
          setCurrentPageNumber(1)
        }

      }else{
        searchGoing = false;
        paginationGoing = false
      }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>Delivery Regular</h1>
                    {_.includes(pagePermissions, 'create') && allowNewApplicant && <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => manageDeliveryRegular()}>ADD NEW</button>}
                    <div className="separator mb-5"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                subHeader={true}
                                subHeaderComponent={<SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/>}
                                progressPending={deliveryRegularLists.isLoading}
                                progressComponent={<Loader />}
                                columns={columns}
                                data={deliveryRegularLists.data}
                                customStyles={customStyles}
                                paginationServer={true}
                                striped={true}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                pagination={true}
                                paginationPerPage={rowsPerPage}
                                paginationTotalRows={deliveryRegularLists.meta && deliveryRegularLists.meta.pagination.count}
                                paginationDefaultPage={activePage}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <ModalComponent
                show={showModal}
                header={<b>Confirm Delete</b>}
                content={<p>Are you sure you want to delete the selected Delivery details ?</p>}
                onSubmitCallback={deleteUserDetails}
                onCloseCallback={doCloseModal}
            />

        </>
    );
};

export default DeliveryRegular;
