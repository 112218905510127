/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { getSession, userPermissionParser } from "../helpers/utils";
import TopNavigation from "../containers/TopNavigation/TopNavigation";
import NavigationSidebar from "../containers/NavigationSidebar/NavigationSidebar";
import Footer from "../components/Footer/Footer";
import { getNotifications } from "../services/appServices";
import { VW_ROUTES } from "../constants";
import { useClient, useQuery } from "jsonapi-react";
import Loader from "../components/common/Loader/Loader";

const DataFetchingComponent = ({ children }) => {
  const history = useHistory();
  const { pathname } = history.location;
  const [currentUserRole, setCurrentUserRole] = React.useState(undefined);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const { data: rolePermissions } = useQuery(
    ["system", "menu", currentUserRole],
    { client }
  );
  const { data: systemInfo } = useQuery(["system", "info"], { client });
  const { data: systemLimit } = useQuery(["system", "config"], { client });

  React.useEffect(() => {
    calculateAddUsaer();
  }, [systemLimit]);

  const calculateAddUsaer = () => {
    if (systemInfo && systemLimit) {
      let userLimit = systemLimit.userLimit;
      let applicantLimit = systemLimit.applicantLimit;

      let userCount = systemInfo.userCount;
      let applicantCount = systemInfo.applicantCount;

      if (applicantCount < applicantLimit) {
        localStorage.setItem("allowNewApplicant", true);
      } else {
        localStorage.setItem("allowNewApplicant", false);
      }
      if (userCount < userLimit) {
        localStorage.setItem("allowNewUser", true);
      } else {
        localStorage.setItem("allowNewUser", false);
      }
    }
  };

  React.useEffect(() => {
    hideSubSidebar(true);
    const user = getSession();
    if (!user) {
      history.push(VW_ROUTES.LOGIN);
    } else {
      updateCurrentUser(user);
      const resp = getNotifications();
      updateNotifications(resp);
    }
  }, []);

  const {
    currentUser,
    notifications,
    mainSidebarHidden,
    subSidebarHidden,
    updateCurrentUser,
    hideMainSidebar,
    hideSubSidebar,
    showLoader,
    //userPermissions,
    updateNotifications,
    //updateFileAlarmCounter,
    //updateUserPermissions,
    updatePagePermissionBasedOnRole,
  } = React.useContext(AppContext);

  React.useEffect(() => {
    if (rolePermissions && rolePermissions.config) {
      const permissions = userPermissionParser(rolePermissions.config);
      if (pathname !== "") {
        updatePagePermissionBasedOnRole({ permissions, pathname });
      }
    }
  }, [rolePermissions, pathname]);
  const { showModalBackDrop } = React.useContext(AppContext);

  React.useEffect(() => {
    hideSubSidebar(true);
  }, [children]);

  React.useEffect(() => {
    setCurrentUserRole(currentUser.role);
  }, [currentUser]);

  const checkExpiryNotificationVisibility = () => {
    if (
      systemLimit &&
      systemLimit.licenseNotifier &&
      systemLimit.licenseNotifier.showNotification &&
      systemLimit.licenseNotifier.applicableRolesSlug &&
      currentUser
    ) {
      if (systemLimit.licenseNotifier.applicableRolesSlug.indexOf(currentUser.role_slug) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        id="app-container"
        className={`ltr rounded menu-sub-hidden ${
          mainSidebarHidden === true ? "main-hidden" : ""
        } ${subSidebarHidden ? "sub-hidden" : "sub-show-temporary"}`}
      >
        {checkExpiryNotificationVisibility() && (
          <div className="expiry-notification">
            {systemLimit.licenseNotifier.notification}
          </div>
        )}

        {currentUser && currentUser.role && (
          <>
            <TopNavigation
              user={currentUser}
              notifications={notifications}
              mainSidebarHidden={mainSidebarHidden}
              subSidebarHidden={subSidebarHidden}
              hideMainSidebar={hideMainSidebar}
              hideSubSidebar={hideSubSidebar}
            />
            <NavigationSidebar
              user={currentUser}
              subSidebarHidden={subSidebarHidden}
              hideSubSidebar={hideSubSidebar}
            />
            <main>
              <div className="container-fluid">{children}</div>
            </main>
            <Footer />
          </>
        )}
      </div>
      <div
        className={`${showModalBackDrop ? "modal-backdrop fade  show" : ""}`}
      ></div>
      <div
        className={`main-loader ${
          showLoader ? "modal-backdrop fade  show" : ""
        }`}
      >
        {showLoader && <Loader />}
      </div>
    </>
  );
};

export default DataFetchingComponent;
