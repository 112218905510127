import React from "react";
import _ from "lodash";
import Select from 'react-select';
import { AppContext } from "../../../context/AppContext";
import DatePicker from "react-datepicker";
import moment from "moment";



const ReportFilter = ({ onSearchInputChange, onNationalityChange, onCompanyChange, onContractorChange, onApplicantTypeChange, onBeforeDateChange, onAfterDateChange,  nationality, companies, contracts, onExport, buttonVisibility }) => {
    const [searchText, setSearchText] = React.useState('');
    const [nation, setNation] = React.useState(null);
    const [company, setCompany] = React.useState('');
    const [contract, setContract] = React.useState('');
    const [type, setType] = React.useState('');
    //const delayedQuery = React.useCallback(_.debounce(q => onSearchInputChange(q), 500), []);
    const onSubmitSearch = () => {
        onSearchInputChange(searchText);
    };
    const { pagePermissions } = React.useContext(AppContext);
    const parseToSelectFormat = (options, keys) => _.map(options, option => ({label:option[keys.label] , value: option[keys.value]}));
    // const updateSerachText = value => {
    //     setSearchText(value);
    // };

    const [beforeDate, setBeforeDate] = React.useState(undefined);
    const [afterDate, setAfterDate] = React.useState(undefined);

    const applicantType = [
        { label: "Visitor", value: 'visitor' },
        { label: "Staff", value: 'staff' },
        { label: "Delivery Regular", value: 'delivery_regular' },
        { label: "Delivery Adhoc", value: 'delivery_adhoc' }
      ];

    const onFilterChange = option => {
        const { name, value, label } = option;
        //console.log(option)
        switch (name) {
            case 'nationality':
                setNation({label, value});
                onNationalityChange(value === "" ? undefined : value);
                break;
            case 'type':
                setType({label, value});
                onApplicantTypeChange(value === "" ? undefined : value);
                break;
            case 'company':
                setCompany({label, value});
                onCompanyChange(value === "" ? undefined : value);
                break;
            case 'contract':
                setContract({label, value});
                onContractorChange(value === "" ? undefined : value);
                break;
            case 'before':
              setBeforeDate(value === null ? undefined : moment(value).format("YYYY-MM-DD"));
              onBeforeDateChange(value === null ? undefined : moment(value).format("YYYY-MM-DD"));
              break;
            case 'after':
              setAfterDate(value === null ? undefined : moment(value).format("YYYY-MM-DD"));
              onAfterDateChange(value === null ? undefined : moment(value).format("YYYY-MM-DD"));
              break;
            default:
                break;
        }
    };

    const resetFilterOptions = () => {
        setNation(null);
        onNationalityChange(undefined);
        setCompany(null);
        onCompanyChange(undefined);
        setType(null);
        onApplicantTypeChange(undefined);
        setContract(null);
        onContractorChange(undefined);
        onSearchInputChange("");
        setSearchText("");
        setBeforeDate(undefined);
        setAfterDate(undefined);
        onBeforeDateChange(undefined);
        onAfterDateChange(undefined);
    };

    return (
        <div className="reports-filter-section pb-3">
            <div className="row mb-2">
                <div className="col-6">
                    <span>Nationality</span>
                    <Select value={nation} options={parseToSelectFormat(nationality, {label: 'display_name', value: 'value'})} onChange={opt => onFilterChange({name: 'nationality', value: opt.value, label: opt.label})}  name="nationality" placeholder="Select" />
                </div>
                <div className="col-6">
                    <span>Applicant Type</span>
                    <Select value={type} options={applicantType} onChange={opt => onFilterChange({name: 'type', value: opt.value, label: opt.label})}  name="type" placeholder="Select" />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-6">
                    <span>Contractor</span>
                    <Select value={contract} options={parseToSelectFormat(contracts, {label: 'name', value: 'id'})} onChange={opt => onFilterChange({name: 'contract', value: opt.value, label: opt.label})}  name="contract" placeholder="Select" />
                </div>
                <div className="col-6">
                    <span>Company</span>
                    <Select value={company} options={parseToSelectFormat(companies, {label: 'name', value: 'id'})} onChange={opt => onFilterChange({name: 'company', value: opt.value, label: opt.label})}  name="company" placeholder="Select" />
                </div>
            </div>

            <div className="row mb-2">
              <div className="col-6">
                <span>Profile Created Between</span>
                <div className="d-flex w-100">
                  <div className={`w-100 ${!beforeDate && afterDate ? 'hasValidationError' : ''}`}>
                      <DatePicker maxDate={afterDate ? new Date(afterDate) : moment().toDate()} selected={beforeDate === undefined ? "" : new Date(beforeDate)} onChange={date => onFilterChange({ name: 'before', value: date, label:'' })} className="form-control" placeholderText="YYYY-MM-DD" dateFormat="yyyy-MM-dd" />
                  </div>
                  <div className="p-2"> - </div>
                  <div className={`w-100 ${beforeDate && !afterDate ? 'hasValidationError' : ''}`}>
                      <DatePicker minDate={beforeDate === undefined ? "" : new Date(beforeDate)} maxDate={moment().toDate()} selected={afterDate === undefined ? "" : new Date(afterDate)} onChange={date => onFilterChange({ name: 'after', value: date, label:'' })} className="form-control" placeholderText="YYYY-MM-DD" dateFormat="yyyy-MM-dd" />
                  </div>
                </div>
              </div>



              <div className="col-6">
                    <span>Search</span>
                    <div className="report-search-container">
                    <input type="text" className="form-control" name="search" value={searchText}
                        id="search" placeholder="Type minimum two characters for searching." onChange={(e) => setSearchText(e.target.value)}  />
                    <button className="" onClick={(e) => onSubmitSearch()}><span className="simple-icon-magnifier"></span></button>
                    </div>
                </div>

            </div>


            <div className="row mb-2">

                <div className="col-12 d-flex align-items-end justify-content-end">
                    {_.includes(pagePermissions, 'download') && <button onClick={onExport} disabled={buttonVisibility} className="btn btn-primary float-right rounded-0">Export</button>}
                    <button onClick={resetFilterOptions} className="btn btn-primary float-right rounded-0 ml-1">Reset</button>
                </div>
            </div>

        </div>

    );
};

export default ReportFilter;
