import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from 'jsonapi-react'
import { VW_ROUTES } from '../../constants';
import { parseAPIResponseError } from "../../helpers/helpers";

export const ResetPassword = () => {
    const initialState = {
        new_password1: '',
        new_password2:''
    }
    const history = useHistory();
    const [formState, updateFormState] = React.useState(initialState);
    const [resetUserPassword] = useMutation(['auth','password','change']);
    const [error, updateErrorMessage] = React.useState({}); 
    const onInputChangeEvent = (e) => {
        updateFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };
    
    const onResetPasswordSubmit = async event => {
        event.preventDefault();
        const {error, data} = await resetUserPassword(formState);
        if(error){
            updateErrorMessage(parseAPIResponseError(error));
         }else if(data){
            history.push(VW_ROUTES.LOGIN);
            //console.log(data)
            //addToast(data.detail, { appearance: 'success' });
         }
        // if(data !== undefined){
        //     history.push(VW_ROUTES.LOGIN);
        // }
        //console.log(getAPIFormDataFormat('change_password', formState))
        // doForgotPassword(formState).then((response, error) => {
        //     if(response.status === 200){
        //         history.push(VW_ROUTES.LOGIN);
        //     }
        // });
    };
    return (
        <>
            <div className="fixed-background login-background"></div>
            <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">
                                    <p className="text-white h2">Welcome to TWRP Registration Portal</p>
                                    <p className="white mb-0">Please reset your password. <br /></p>
                                </div>
                                <div className="form-side"><span className="logo-single"></span>
                                    <h6 className="my-4">Reset Password</h6>
                                    <form onSubmit={onResetPasswordSubmit}>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="new_password1" type="password" className="form-control" />
                                            <span>Password</span>
                                            <p className="login-error-message">{error && error.field === 'new_password1' ? error.errorMsg : ''}</p>
                                        </label>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="new_password2" type="password" className="form-control" />
                                            <span>Confirm Password</span>
                                            <p className="login-error-message">{error && error.field === 'new_password2' ? error.errorMsg : ''}</p>
                                        </label>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit">SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ResetPassword;