import React from "react";

const EditTrainingStatus = ({ passData, onRadioInputChange }) => {
    return (
        <div className="profile-details-wrapper">
            <div className="row">
                <div className="col-12">
                <span>Completed Training? &nbsp;</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-check-inline">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" value={true} onChange={(e) => onRadioInputChange(e.target)} name="has_attend" />Yes
                     </label>
                    </div>
                </div>
                <div className="col-md-6 pl-0">
                    <div className="form-check-inline">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" value={false} onChange={(e) => onRadioInputChange(e.target)} name="has_attend" />No
                     </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTrainingStatus;
