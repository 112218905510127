/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import { AppContext } from "../../context/AppContext";
import {
  addBodyClass,
  removeBodyClass,
  userPermissionParser,
} from "../../helpers/utils";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  VW_ROUTES,
  API_BASE_URL,
  SET_PAGE_COUNT,
  DEVICE_SERVICE_BASE_URL,
} from "../../constants";
import queryString from "query-string";
import axios from "axios";
import { validatorModule } from "../../helpers/helpers";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { useToasts } from "react-toast-notifications";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import CaptureImageComponent from "../../components/CaptureImageComponent/CaptureImageComponent";
import ViewGatePass from "../../components/common/ViewGatePass/ViewGatePass";
import EditGatePass from "../../components/common/EditGatePass/EditGatePass";
import AddGatePass from "../../components/common/AddGatePass/AddGatePass";
import profileimagePlaceholder from "../../../src/assets/images/profileimagePlaceholder.jpg";
import EditTrainingStatus from "../../components/common/EditTrainingStatus/EditTrainingStatus";
import leadingzero from "leadingzero";
import ReactTooltip from "react-tooltip";
import ApproveWithCourse from "../../components/common/ApproveWithCourse/ApproveWithCourse";
import RejectApplication from "../../components/common/RejectApplication/RejectApplication";
import DataTable from "react-data-table-component";
import Loader from "../../components/common/Loader/Loader";
import { setTimeout } from "core-js";

const ViewVisitor = ({ location }) => {
  const toolTipRef = React.useRef(null);
  const [pageParams] = React.useState(queryString.parse(location.search));
  const [passData, setPassData] = React.useState({});
  const [editPassData, setEditPassData] = React.useState({});
  const [addPassData, setAddPassData] = React.useState({});
  //const [activePass, setActivePass] = React.useState({});
  const [confirmModalType, setConfirmModalType] = React.useState(0);
  const [selectedTainingDetails, setSelectedTrainingDetails] = React.useState(
    {}
  );
  const [trainingStatusData, setTrainingStatusData] = React.useState(false);
  const [trainingStatusSelected, setTrainingStatusSelected] =
    React.useState(false);
  const [editOption, setEditOption] = React.useState(false);
  const [showGatePassModal, setShowGatePassModal] = React.useState(false);
  const [editGatePassModal, setEditGatePassModal] = React.useState(false);
  const [addGatePassModal, setAddGatePassModal] = React.useState(false);
  const [addTrainingModal, setAddTrainingModal] = React.useState(false);
  const [addConfirmationModal, setAddConfirmationModal] = React.useState(false);
  const [editUpdateTrainingModal, setEditUpdateTrainingModal] =
    React.useState(false);
  const [gatePassApprovalModal, setGatePassApprovalModal] =
    React.useState(false);
  const [gatePassRejectionModal, setGatePassRejectionModal] =
    React.useState(false);
  const [rejectData, setRejectData] = React.useState({ reject_note: "" });
  const [nationality, setNationality] = React.useState([]);
  const [addPassErrors, setAddPassErrors] = React.useState([]);
  const [editPassErrors, setEditPassErrors] = React.useState([]);
  const [trainingData, setTrainingData] = React.useState({ training: "" });
  const [validationMessage, setValidationMessage] = React.useState({});
  const [rejectValidation, setRejectValidation] = React.useState({ msg: "" });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [synConfrimationModal, setSynConfrimationModal] = React.useState(false);
  const [showSyncsuccessPopup, setShowSyncsuccessPopup] = React.useState(false);
  const [spinnerReady, setSpinnerReady] = React.useState(false);

  const { addToast } = useToasts();
  const pageHeaders = {
    visitor: "Visitor",
    staff: "Staff",
    delivery_regular: "Delivery Regular",
    delivery_adhoc: "Delivery Adhoc",
  };
  const validationFormat = [
    {
      field: "trainingDateTime",
      isRequired: true,
      fieldName: "Training Date",
    },
  ];
  const routeArray = {
    staff: VW_ROUTES.APPLICANTS_STAFF,
    visitor: VW_ROUTES.APPLICANTS_VISITIOR,
    delivery_regular: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR,
    delivery_adhoc: VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC,
  };
  const editRouteArray = {
    staff: VW_ROUTES.APPLICANTS_STAFF_EDIT,
    visitor: VW_ROUTES.APPLICANTS_VISITIOR_EDIT,
    delivery_regular: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT,
    delivery_adhoc: VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT,
  };
  // React.useEffect(() => {
  //     let params = queryString.parse(location.search);
  //     setPageParams(params);
  // }, []);
  const history = useHistory();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  const { showModal, manageModal, currentUser } = React.useContext(AppContext);
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const [approveGatePass] = useMutation(
    [
      "applicant",
      "profile",
      pageParams.id,
      "gate-pass",
      passData.id,
      "approve",
    ],
    { method: "PUT", client }
  );
  const [rejectGatePass] = useMutation(
    ["applicant", "profile", pageParams.id, "gate-pass", passData.id, "reject"],
    { method: "PUT", client }
  );
  const [updateGatePass] = useMutation(
    ["applicant", "profile", pageParams.id, "gate-pass", passData.id],
    { method: "PUT", client }
  );
  const gatePasses = useQuery(
    [
      "applicant",
      "profile",
      pageParams.id,
      "gate-pass",
      {
        page: {
          number: currentPageNumber,
          size: rowsPerPage,
        },
        include: "contract",
      },
    ],
    { client }
  );

  const [createGatePass] = useMutation([
    "applicant",
    "profile",
    pageParams.id,
    "gate-pass",
  ]);
  const [updateTraining] = useMutation(
    [
      "applicant",
      "profile",
      selectedTainingDetails.profileId,
      "training",
      selectedTainingDetails.trainingId,
      "attended",
    ],
    { method: "PUT", client }
  );
  const [toggleApplicantProfile] = useMutation(
    ["applicant", "profile", pageParams.id, "convert_profile"],
    { method: "PUT", client }
  );
  const [blacklistApplicantProfile] = useMutation(
    ["applicant", "profile", pageParams.id, "deactivate"],
    { method: "PUT", client }
  );
  const { data: trainings } = useQuery(
    [
      "training",
      "schedule",
      "upcoming",
      {
        page: {
          number: 1,
          size: SET_PAGE_COUNT,
        },
      },
    ],
    { client }
  );
  const { data: rolePermissions } = useQuery(
    ["system", "menu", currentUser.role],
    { client }
  );
  const [updateApplicantTraining] = useMutation(
    ["applicant", "profile", pageParams.id, "training"],
    { client }
  );
  const { data: contracts } = useQuery(
    [
      "organisation",
      "contract",
      {
        page: {
          number: 1,
          size: SET_PAGE_COUNT,
        },
      },
    ],
    { client }
  );
  React.useEffect(() => {
    if (rolePermissions && rolePermissions.config) {
      const permissions = userPermissionParser(rolePermissions.config);
      const pathname =
        pageParams.type === "visitor"
          ? VW_ROUTES.APPLICANTS_VISITIOR
          : pageParams.type === "staff"
          ? VW_ROUTES.APPLICANTS_STAFF
          : pageParams.type === "delivery_regular"
          ? VW_ROUTES.APPLICANTS_DELIVERY_REGULAR
          : VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC;
      const pagePermissions =
        _.keyBy(permissions, "route")[_.invert(VW_ROUTES)[pathname]] &&
        _.keyBy(permissions, "route")[_.invert(VW_ROUTES)[pathname]].actions;
      setEditOption(_.includes(pagePermissions, "edit"));
      //   if (pathname !== "") {
      //     updatePagePermissionBasedOnRole({ permissions, pathname });
      //   }
    }
  }, [rolePermissions]);
  React.useEffect(() => {
    const options = {
      method: "OPTIONS",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${API_BASE_URL}/applicant/profile`,
    };
    axios(options).then((response) => {
      const {
        data: {
          data: {
            actions: {
              POST: {
                // applicantType: {
                //     choices: applicantTypeChoices
                // },
                nationality: { choices: nationalityChoices },
              },
            },
          },
        },
      } = response;
      setNationality(_.keyBy(nationalityChoices, "value"));
    });
  }, []);

  const updateGatePassData = async () => {
    let emptyFields = [];
    for (let [key, value] of Object.entries(editPassData)) {
      if (pageParams.type === "delivery_adhoc") {
        if (key !== "poNumber" && key !== "doNumber" && value === "") {
          emptyFields.push(key);
        }
      } else {
        if (
          key !== "vehicleNumber" &&
          key !== "poNumber" &&
          key !== "doNumber" &&
          value === ""
        ) {
          emptyFields.push(key);
        }
      }

      // if (key !== "vehicleNumber" && value === "") {
      //     emptyFields.push(key);
      // }
    }
    setEditPassErrors(emptyFields);
    if (_.isEmpty(emptyFields)) {
      const { data, error } = await updateGatePass({
        ...editPassData,
        id: passData.id,
      });
      if (data && error === undefined) {
        applicantProfile.refetch();
        addToast(DISPLAY_TEXTS.PASS_UPDATED, { appearance: "success" });
      } else if (error !== undefined && error.detail) {
        addToast(error.detail, { appearance: "error" });
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
      manageModalSettings(false, 1);
    }
  };

  const applicantProfile = useQuery(
    [
      "applicant",
      "profile",
      pageParams.id,
      {
        include:
          "applicant_photo,applicant_pass,applicant_training,company,contract,trade-information",
      },
    ],
    { client }
  );

  React.useEffect(() => {
    if (applicantProfile.error) {
      addToast("Account not found", { appearance: "error" });
      let path = VW_ROUTES.DASHBOARD;
      switch (pageParams.from) {
        case "expiry":
          path = `${VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING}?type=${pageParams.type}&list=${pageParams.list}`;
          break;
        case "all_applicants":
          path = `${VW_ROUTES.APPLICANTS_HISTORY}`;
          break;
        case "dashboard":
          path = VW_ROUTES.DASHBOARD;
          break;
        case "pending_applicants":
          path = `${VW_ROUTES.APPLICANTS_PENDING}?type=${pageParams.type}`;
          break;
        default:
          path = routeArray[pageParams.type];
      }
      history.push(path);
    }
  }, [applicantProfile]);

  const addGatePassData = async () => {
    var formData =
      pageParams.type === "delivery_adhoc"
        ? addPassData
        : _.omit(addPassData, ["poNumber", "doNumber"]);
    let emptyFields = [];
    for (let [key, value] of Object.entries(formData)) {
      if (pageParams.type === "delivery_adhoc") {
        if (key !== "poNumber" && key !== "doNumber" && value === "") {
          emptyFields.push(key);
        }
      } else {
        if (
          key !== "vehicleNumber" &&
          key !== "poNumber" &&
          key !== "doNumber" &&
          value === ""
        ) {
          emptyFields.push(key);
        }
      }
    }
    setAddPassErrors(emptyFields);
    if (_.isEmpty(emptyFields)) {
      const { data, error } = await createGatePass(formData);
      if (data && error === undefined) {
        applicantProfile.refetch();
        addToast(DISPLAY_TEXTS.PASS_ADDED, { appearance: "success" });
        manageModalSettings(false, 3);
      } else if (error !== undefined && error.detail) {
        addToast(error.detail, { appearance: "error" });
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    }
  };

  const addTrainingData = async () => {
    let errors = validatorModule(trainingData, validationFormat);
    setValidationMessage(errors);
    if (Object.keys(errors).length === 0) {
      const { data, error } = await updateApplicantTraining({
        ...trainingData,
        applicant: pageParams.id,
      });
      if (data && error === undefined) {
        addToast(DISPLAY_TEXTS.USER_TRAINING_ADDED, { appearance: "success" });
        manageModalSettings(false, 4);
      } else if (error !== undefined && error.detail) {
        addToast(error.detail, { appearance: "error" });
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    }
    setTrainingData({ training: "" });
  };

  /*
    React.useEffect(() => {
        if (applicantProfile.data) {
            console.log(applicantProfile.data)
            var activePassData = _.map(applicantProfile.data.applicantPass, pass => {
                if (pass.status === "active") return pass;
            });

            setActivePass(activePassData);
        }
    }, [applicantProfile.data]);
    */
  const managePhotoBooth = (status) => {
    manageModal(status);
  };
  const refreshOnCaptureImage = () => {
    applicantProfile.refetch();
  };

  const manageModalSettings = (status, identifier) => {
    if (identifier === 0) {
      setShowGatePassModal(status);
    } else if (identifier === 1) {
      setEditGatePassModal(status);
    } else if (identifier === 2) {
      setEditUpdateTrainingModal(status);
    } else if (identifier === 3) {
      setAddGatePassModal(status);
    } else if (identifier === 4) {
      setAddTrainingModal(status);
    } else if (identifier === 5) {
      setAddConfirmationModal(status);
    } else if (identifier === 6) {
      setGatePassApprovalModal(status);
    } else if (identifier === 7) {
      setGatePassRejectionModal(status);
    } else if (identifier === 8) {
      setSynConfrimationModal(status);
    }
    if (status) {
      addBodyClass("modal-open");
    } else {
      removeBodyClass("modal-open");
    }
  };

  const viewSelectedGatePass = (pass) => {
    setPassData(pass);
    manageModalSettings(true, 0);
  };

  const onAddGatePass = () => {
    setAddPassData({
      fromDateTime: "",
      hostName: "",
      purposeOfVisit: "",
      poNumber: "",
      doNumber: "",
      vehicleNumber: "",
      applicant: pageParams.id,
      contract: "",
    });
    manageModalSettings(true, 3);
  };

  const onAddTraining = () => {
    setTrainingData({
      training: "",
    });
    manageModalSettings(true, 4);
  };

  const editSelectedGatePass = (pass) => {
    setPassData(pass);

    setEditPassData({
      id: pass.id,
      fromDateTime: pass.fromDateTime,
      hostName: pass.hostName,
      purposeOfVisit: pass.purposeOfVisit,
      poNumber: pass.poNumber,
      doNumber: pass.doNumber,
      vehicleNumber: pass.vehicleNumber,
      applicant: pageParams.id,
      contract: pass.contract && pass.contract.id ? pass.contract.id : "",
    });
    manageModalSettings(true, 1);
  };

  const onInputChange = (target) => {
    setEditPassData({
      ...editPassData,
      [target.name]:
        target.name === "fromDateTime"
          ? target.value
            ? moment(new Date(target.value).setHours(0, 0, 0, 0))
                .utcOffset("+0800", true)
                .format("")
            : moment(new Date().setHours(0, 0, 0, 0))
                .utcOffset("+0800", true)
                .format("")
          : target.value,
    });
  };

  const onAddGatePassInputChange = (target) => {
    setAddPassData({
      ...addPassData,
      [target.name]:
        target.name === "fromDateTime"
          ? moment(new Date(target.value).setHours(0, 0, 0, 0))
              .utcOffset("+0800", true)
              .format("")
          : target.value,
    });
  };

  const onRadioInputChange = (target) => {
    setTrainingStatusSelected(true);
    setTrainingStatusData(target.value === "true" ? true : false);
  };

  const onActionChange = (action, pass) => {
    setPassData(pass);
    if (action === "approve") {
      manageModalSettings(true, 6);
    } else {
      manageModalSettings(true, 7);
    }
  };

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const columns = [
    {
      name: "Date",
      selector: "fromDateTime",
      sortable: true,
      format: (row) =>
        moment(row.fromDateTime).utcOffset("+0800").format("DD-MM-YYYY"),
    },

    {
      name: "Contract",
      sortable: true,
      cell: (row) => (
        <span>
          {row.contract && row.contract.name ? row.contract.name : "--"}
        </span>
      ),
    },

    {
      name: "Purpose",
      selector: "purposeOfVisit",
      sortable: true,
    },

    {
      name: "Actions",
      sortable: false,
      cell: (pass) => (
        <div className="actionContainer">
          <button
            type="button"
            onClick={() => viewSelectedGatePass(pass)}
            className="btn btn-round-light icon-button"
          >
            <i className="simple-icon-eye"></i>
          </button>

          {pass.status === "pending" &&
            currentUser.dashboard !== "ac_dashboard" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <button
                type="button"
                onClick={() => editSelectedGatePass(pass)}
                className="btn btn-round-light icon-button"
              >
                <i className="iconsminds-pen"></i>
              </button>
            )}
          {pass.status === "pending" &&
            currentUser.dashboard !== "ac_dashboard" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <>
                <span
                  className="approve-reject approve simple-icon-check"
                  onClick={() => onActionChange("approve", pass)}
                ></span>
                <span
                  className="approve-reject reject simple-icon-close"
                  onClick={() => onActionChange("reject", pass)}
                ></span>
              </>
            )}
          {pass.status === "deactivated" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <span className="deactivated statusIndicator">Deactivated</span>
            )}
          {pass.status === "approved" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <span className="isApproved statusIndicator">Approved</span>
            )}
          {pass.status === "rejected" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <span className="isRejected statusIndicator">Rejected</span>
            )}
          {pass.status === "expired" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <span className="isExpired statusIndicator">Expired</span>
            )}
          {pass.status === "pending" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <span className="isExpired statusIndicator">Pending</span>
            )}
          {pass.status === "active" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <span className="isActive statusIndicator">Active</span>
            )}
        </div>
      ),
      right: true,
    },
  ];

  const updateTrainingStatusModal = (trainingId, profileId) => {
    setSelectedTrainingDetails({
      trainingId,
      profileId,
    });
    setEditUpdateTrainingModal(true);
    manageModalSettings(true, 2);
  };

  const getTrainingStructure = (trainings) => {
    return _.map(_.orderBy(trainings, "created"), (training) => {
      //console.log(training)
      return (
        <tr key={_.uniqueId("tr-")}>
          <td>{training.topic}</td>
          {/* <td>--</td> */}
          <td>{moment(training.trainingDateTime).format("DD-MM-YYYY")}</td>
          <td>
            {training.hasAttend === true && <span>{training.expiresOn}</span>}
            {training.hasAttend === null && (
              <>
                <span>Not Attended</span>{" "}
                {currentUser.role_slug !== "pub-jacobs" &&
                  currentUser.role_slug !== "account-coordinator" && (
                    <button
                      type="button"
                      onClick={() =>
                        updateTrainingStatusModal(
                          training.id,
                          training.applicant.id
                        )
                      }
                      className="btn btn-round-light icon-button"
                    >
                      <i className="iconsminds-pen"></i>
                    </button>
                  )}
              </>
            )}
            {training.hasAttend === false && (
              <>
                <span>Not Attended</span>
              </>
            )}
          </td>
        </tr>
      );
    });
  };

  const updateTrainingStatus = async () => {
    const updateData = {
      hasAttend: trainingStatusData,
      id: selectedTainingDetails.trainingId,
    };

    const { data } = await updateTraining(updateData);
    if (data) {
      setEditUpdateTrainingModal(false);
      manageModalSettings(false, 2);
      addToast(DISPLAY_TEXTS.TRAINING_STATUS_UPDATED, {
        appearance: "success",
      });
    } else {
      setEditUpdateTrainingModal(false);
      manageModalSettings(false, 2);
    }
  };

  const onBackButtonClick = () => {
    history.goBack();
    // let path = VW_ROUTES.DASHBOARD;
    // switch (pageParams.from) {
    //     case 'expiry':
    //         path = `${VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING}?type=${pageParams.type}&list=${pageParams.list}`;
    //         break;
    //     case 'all_applicants':
    //         path = `${VW_ROUTES.APPLICANTS_HISTORY}`;
    //         break;
    //     case 'dashboard':
    //         path = VW_ROUTES.DASHBOARD;
    //         break;
    //     case 'pending_applicants':
    //         path = `${VW_ROUTES.APPLICANTS_PENDING}?type=${pageParams.type}`;
    //         break;
    //     default:
    //         path = routeArray[pageParams.type];
    // }
    // history.push(path);
  };

  const popConfirmModal = (type) => {
    setConfirmModalType(type);
    manageModalSettings(true, 5);
  };

  const syncProfile = () => {
    manageModalSettings(true, 8);
  };

  const confirmSyncProfile = () => {
    const options = {
      url: `${DEVICE_SERVICE_BASE_URL}/task/device/profile/sync`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        data: {
          attributes: {
            profile_id:
              applicantProfile.data && applicantProfile.data.applicantId,
          },
          type: "device",
        },
      },
    };

    axios(options)
      .then((response) => {
        if (response.status === 200) {
          setShowSyncsuccessPopup(true);
          manageModalSettings(false, 8);
          setTimeout(() => {
            setSpinnerReady(true);
          }, 2000);
        } else {
          manageModalSettings(false, 8);
          addToast(DISPLAY_TEXTS.RESYNC_FAILED, { appearance: "warning" });
        }
      })
      .catch(() => {
        manageModalSettings(false, 8);
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      });
  };

  const confirmSyncProfileOk = () => {
    setShowSyncsuccessPopup(false);
    setSpinnerReady(false);
  };

  const toggleProfile = async () => {
    const applicant_type =
      pageParams.type === "staff" ? "delivery_regular" : "staff";
    const { data, error } = await toggleApplicantProfile({
      applicant_type,
      id: pageParams.id,
    });
    if (data) {
      addToast(DISPLAY_TEXTS.APPLICANT_PROFILE_CONVERTED, {
        appearance: "success",
      });
      setTimeout(() => {
        history.push({
          pathname: VW_ROUTES.APPLICANTS_APPLICANT_VIEW,
          search: `?id=${pageParams.id}&type=${applicant_type}`,
        });
        window.location.reload();
      }, 500);
    } else {
      addToast(error.detail, { appearance: "error" });
    }
  };

  const blacklistProfile = async () => {
    const { data, error } = await blacklistApplicantProfile({
      deactivate: applicantProfile.data.status === "deactivated" ? false : true,
      id: pageParams.id,
    });
    if (data) {
      addToast(
        applicantProfile.data.status === "deactivated"
          ? DISPLAY_TEXTS.APPLICANT_PROFILE_UNBLOCKED
          : DISPLAY_TEXTS.APPLICANT_PROFILE_BLOCKED,
        { appearance: "success" }
      );
    } else {
      addToast(error.detail, { appearance: "error" });
    }
  };

  const onAddTrainingInputChange = (target) => {
    setTrainingData({
      ...trainingData,
      [target.name]: target.value,
    });
  };

  const redirectToEditPageBasedonType = () => {
    const editRoutePath = `${editRouteArray[pageParams.type]}?id=${
      pageParams.id
    }`;
    history.push(editRoutePath);
  };

  const confirmSwitchOption = () => {
    if (confirmModalType === 0) {
      toggleProfile();
    } else {
      blacklistProfile();
    }
    manageModalSettings(false, 5);
  };

  const confirmApproveGatePass = async () => {
    const approveData = {
      approve: true,
      id: pageParams.id,
    };
    const { error } = await approveGatePass(approveData);
    applicantProfile.refetch();
    manageModalSettings(false, 6);
    if (error === undefined) {
      addToast(DISPLAY_TEXTS.APPROVE_GATE_PASS, { appearance: "success" });
    } else {
      addToast(error.detail, { appearance: "error" });
    }
  };

  const confirmRejectGatePass = () => {
    if (rejectData.reject_note === "") {
      setRejectValidation({ msg: "Reject note required." });
    } else {
      const { error } = rejectGatePass(rejectData);
      applicantProfile.refetch();
      manageModalSettings(false, 7);
      if (error === undefined) {
        addToast(DISPLAY_TEXTS.REJECT_GATE_PASS, { appearance: "success" });
      } else {
        addToast(error.detail, { appearance: "error" });
      }
    }
  };

  const onRejectInputChange = (value) => {
    setRejectValidation({ msg: "" });
    setRejectData({
      id: passData.id,
      reject_note: value,
    });
  };

  const editPermissionAC = () => {
    if (currentUser.role_slug === "account-coordinator") {
      if (
        applicantProfile.data &&
        (applicantProfile.data.status === "expired" ||
          applicantProfile.data.status === "pending" ||
          applicantProfile.data.status === "approved" ||
          applicantProfile.data.status === "rejected" ||
          applicantProfile.data.status === "deactivated")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const normalizeApplicantType = (type) => {
    return type.replace(/_/g, " ");
  };

  //console.log(gatePasses)
  //console.log(applicantProfile.data)
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>View {pageHeaders[pageParams.type]}</h1>
          <button
            type="button"
            className="btn btn-primary btn-sm float-right"
            onClick={() => onBackButtonClick()}
          >
            BACK
          </button>
          {currentUser.dashboard !== "ac_dashboard" &&
            (pageParams.type === "staff" ||
              pageParams.type === "delivery_regular") &&
            currentUser.role_slug !== "pub-jacobs" && (
              <button
                onClick={() => popConfirmModal(0)}
                className="float-right btn btn-primary mr-1"
              >
                Switch Profile to{" "}
                {pageParams.type === "staff" ? "Delivery Regular" : "Staff"}
              </button>
            )}

          {(currentUser.role_slug === "super-admin" ||
            currentUser.role_slug === "nsg-admin") && (
            <button
              onClick={() => syncProfile()}
              className="float-right btn btn-primary mr-1"
            >
              Re-sync profile
            </button>
          )}

          {currentUser.dashboard !== "ac_dashboard" &&
            currentUser.role_slug !== "pub-jacobs" && (
              <button
                onClick={() => popConfirmModal(1)}
                className={`float-right btn mr-1 ${
                  applicantProfile.data &&
                  applicantProfile.data.status === "deactivated"
                    ? "btn-success"
                    : "btn-danger"
                }`}
              >
                {applicantProfile.data &&
                applicantProfile.data.status === "deactivated"
                  ? "Activate Profile"
                  : "Inactivate Profile"}
              </button>
            )}
          {editOption && editPermissionAC() && (
            <button
              type="button"
              onClick={() => redirectToEditPageBasedonType()}
              className="btn btn-round-light icon-button viewEditIcon"
            >
              <i className="iconsminds-pen"></i>
            </button>
          )}
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-5 mb-4">
                  <h5 className="mb-4">Profile Details</h5>
                  <div className="card mb-4">
                    {currentUser.dashboard !== "ac_dashboard" &&
                      currentUser.role_slug !== "pub-jacobs" &&
                      pageParams.type !== "delivery_adhoc" &&
                      pageParams.type !== "visitor" && (
                        <div className="position-absolute card-top-buttons">
                          <button
                            type="button"
                            className="btn btn-header-light icon-button"
                            onClick={() => managePhotoBooth(true)}
                          >
                            <i className="simple-icon-camera"></i>
                          </button>
                        </div>
                      )}
                    <div className="card-body">
                      <h5 className="card-title">{""}</h5>
                      <div className="profile-details-wrapper row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 pr-md-0 profile-image-wrapper">
                              <div className="image-holder">
                                {applicantProfile.data &&
                                applicantProfile.data.applicantType !==
                                  "visitor" &&
                                applicantProfile.data.applicantType !==
                                  "delivery_adhoc" ? (
                                  <div
                                    className={
                                      "profileStatus " +
                                      (applicantProfile.data &&
                                        applicantProfile.data.status)
                                    }
                                  >
                                    {applicantProfile.data &&
                                      applicantProfile.data.status}
                                  </div>
                                ) : (
                                  <>
                                    {applicantProfile.data &&
                                      applicantProfile.data.status ===
                                        "deactivated" && (
                                        <div
                                          className={
                                            "profileStatus " +
                                            (applicantProfile.data &&
                                              applicantProfile.data.status)
                                          }
                                        >
                                          {applicantProfile.data &&
                                            applicantProfile.data.status}
                                        </div>
                                      )}
                                  </>
                                )}

                                <img
                                  src={
                                    applicantProfile.data &&
                                    applicantProfile.data.applicantPhoto[
                                      applicantProfile.data.applicantPhoto
                                        .length - 1
                                    ]
                                      ? applicantProfile.data.applicantPhoto[
                                          applicantProfile.data.applicantPhoto
                                            .length - 1
                                        ].avatar
                                      : profileimagePlaceholder
                                  }
                                  alt=""
                                  className="profile-image"
                                />
                              </div>

                              <div className="status-details">
                                <div className="applicant-type">
                                  <i className="iconsminds-box-full"></i>
                                  {applicantProfile.data &&
                                    normalizeApplicantType(
                                      applicantProfile.data.applicantType
                                    )}{" "}
                                </div>
                                {/* <div className="applicant-position in">
                                                                    In
                                                                </div>


                                                                <div className="applicant-position out">
                                                                    Out
                                                                </div> */}
                              </div>
                            </div>
                            <div className="profile-main-content col-md-6 col-sm-6 bg-primary">
                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Full Name{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.fullName}{" "}
                                </h5>
                              </div>

                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Email{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.email}{" "}
                                </h5>
                              </div>

                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Date Of Birth{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.dateOfBirth}{" "}
                                </h5>
                              </div>

                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Gender{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold text-capitalize">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.gender}{" "}
                                </h5>
                              </div>

                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Mobile{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    (applicantProfile.data.mobileNumber === ""
                                      ? "-"
                                      : applicantProfile.data
                                          .mobileNumber)}{" "}
                                </h5>
                              </div>

                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Nationality{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {applicantProfile.data &&
                                    nationality &&
                                    nationality[
                                      applicantProfile.data.nationality
                                    ] &&
                                    nationality[
                                      applicantProfile.data.nationality
                                    ].display_name}{" "}
                                </h5>
                              </div>
                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Profile Created Date{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {applicantProfile.data &&
                                    moment(
                                      applicantProfile.data.created.split(
                                        "T"
                                      )[0]
                                    ).format("DD-MM-YYYY")}{" "}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Applicant Id
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.applicantId}{" "}
                                </h5>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Employer/Company Name{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.company &&
                                    applicantProfile.data.company.name}{" "}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {applicantProfile.data &&
                              applicantProfile.data.nricNumber !== null && (
                                <div className="col-md-6">
                                  <div className="profile-container">
                                    <div className="profile-label mt-2">
                                      {" "}
                                      NRIC Number{" "}
                                    </div>
                                    <h5 className="profile-label-value font-weight-bold text-uppercase">
                                      {" "}
                                      {applicantProfile.data &&
                                        leadingzero(
                                          applicantProfile.data.nricNumber,
                                          3
                                        ) +
                                          applicantProfile.data.nricAlpha}{" "}
                                    </h5>
                                  </div>
                                </div>
                              )}
                            {applicantProfile.data &&
                              applicantProfile.data.finNumber !== null && (
                                <div className="col-md-6">
                                  <div className="profile-container">
                                    <div className="profile-label mt-2">
                                      {" "}
                                      Fin Number{" "}
                                    </div>
                                    <h5 className="profile-label-value font-weight-bold text-uppercase">
                                      {" "}
                                      {applicantProfile.data &&
                                        leadingzero(
                                          applicantProfile.data.finNumber,
                                          3
                                        ) + applicantProfile.data.finAlpha}{" "}
                                    </h5>
                                  </div>
                                </div>
                              )}
                            {applicantProfile.data &&
                              applicantProfile.data.passport !== null &&
                              applicantProfile.data.passport !== "" && (
                                <div className="col-md-6">
                                  <div className="profile-container">
                                    <div className="profile-label mt-2">
                                      {" "}
                                      Passport Number{" "}
                                    </div>
                                    <h5 className="profile-label-value font-weight-bold text-uppercase">
                                      {" "}
                                      {applicantProfile.data &&
                                        applicantProfile.data.passport}{" "}
                                    </h5>
                                  </div>
                                </div>
                              )}
                            {/* <div className="col-md-6">
                                                            <div className="profile-container">
                                                                <div className="profile-label mt-2"> Vehicle Number </div>
                                                                <h5 className="profile-label-value font-weight-bold"> {applicantProfile.data && (applicantProfile.data.vehicleNumber === '' ? '-' : applicantProfile.data.vehicleNumber)} </h5>
                                                            </div>
                                                        </div> */}
                          </div>

                          <div className="row">
                            {(pageParams.type === "staff" ||
                              pageParams.type === "delivery_regular") && (
                              <div className="col-md-6">
                                <div className="profile-container">
                                  <div className="profile-label mt-2">
                                    {" "}
                                    TWRP Contract Name{" "}
                                  </div>
                                  <h5 className="profile-label-value font-weight-bold">
                                    {" "}
                                    {applicantProfile.data &&
                                      applicantProfile.data.contract &&
                                      applicantProfile.data.contract.name}
                                  </h5>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="profile-container">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Job Appointment/Designation{" "}
                                </div>
                                <h5 className="profile-label-value font-weight-bold">
                                  {" "}
                                  {applicantProfile.data &&
                                    applicantProfile.data.designation}{" "}
                                </h5>
                              </div>
                            </div>
                            {applicantProfile.data &&
                              applicantProfile.data.finNumber != null && (
                                <div className="col-md-6">
                                  <div className="profile-container">
                                    <div className="profile-label mt-2">
                                      {" "}
                                      Expiry Date of Work Permit{" "}
                                    </div>
                                    <h5 className="profile-label-value font-weight-bold">
                                      {applicantProfile.data &&
                                        applicantProfile.data.workPermitExpiry}
                                    </h5>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="row">
                            {applicantProfile.data &&
                              (pageParams.type === "staff" ||
                                pageParams.type === "delivery_regular") && (
                                <div className="col-md-6">
                                  <div className="profile-container">
                                    <div className="profile-label mt-2">
                                      {" "}
                                      Trade Information{" "}
                                    </div>
                                    <h5 className="profile-label-value font-weight-bold">
                                      {applicantProfile.data &&
                                        applicantProfile.data
                                          .tradeInformation &&
                                        applicantProfile.data.tradeInformation
                                          .name}
                                    </h5>
                                  </div>
                                </div>
                              )}
                            {/*applicantProfile.data && (pageParams.type === 'visitor' || pageParams.type === 'delivery_adhoc') &&
                                                            <div className="col-md-6">
                                                                <div className="profile-container">
                                                                    <div className="profile-label mt-2"> Purpose Of Visit </div>
                                                                    <h5 className="profile-label-value font-weight-bold"> {activePass && activePass[0] && activePass[0].purposeOfVisit} </h5>
                                                                </div>
                                                            </div>
                                                    */}
                          </div>

                          <div className="row">
                            {pageParams.type === "delivery_regular" && (
                              <div className="col-md-6">
                                <div className="profile-container">
                                  <div className="profile-label mt-2">
                                    {" "}
                                    Vehicle Number{" "}
                                  </div>
                                  <h5 className="profile-label-value font-weight-bold">
                                    {" "}
                                    {applicantProfile.data &&
                                      applicantProfile.data.vehicleNumber &&
                                      applicantProfile.data.vehicleNumber}
                                  </h5>
                                </div>
                              </div>
                            )}
                          </div>

                          {/*applicantProfile.data && (pageParams.type === 'visitor' || pageParams.type === 'delivery_adhoc') &&
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="profile-container">
                                                                    <div className="profile-label mt-2"> Date and Time Of Visit </div>
                                                                    <h5 className="profile-label-value font-weight-bold"> {activePass && activePass[0] && moment(activePass[0].fromDateTime).format('lll')} </h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-container">
                                                                    <div className="profile-label mt-2"> Host Name </div>
                                                                    <h5 className="profile-label-value font-weight-bold"> {activePass && activePass[0] && activePass[0].hostName}  </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    */}

                          {/* <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="profile-container">
                                                                <div className="profile-label mt-2"> Purpose Of Visit </div>
                                                                <h5 className="profile-label-value font-weight-bold"> {activePass && activePass[0] && activePass[0].purposeOfVisit} </h5>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {applicantProfile.data &&
                  (pageParams.type === "visitor" ||
                    pageParams.type === "delivery_adhoc") && (
                    <div className="col-md-12 col-lg-7 mb-4">
                      <h5 className="mb-4">
                        Gate Pass History{" "}
                        {applicantProfile.data &&
                          applicantProfile.data.status !== "deactivated" &&
                          currentUser.role_slug !== "pub-jacobs" && (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right"
                              onClick={() => onAddGatePass()}
                            >
                              Add Gate Pass
                            </button>
                          )}
                      </h5>

                      <div className="card mb-4">
                        <div className="card-body pass-history">
                          <DataTable
                            subHeader={false}
                            progressPending={gatePasses.data ? false : true}
                            progressComponent={<Loader />}
                            columns={columns}
                            data={gatePasses.data}
                            customStyles={customStyles}
                            paginationServer={true}
                            striped={true}
                            onChangePage={onChangePage}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            pagination={true}
                            paginationPerPage={rowsPerPage}
                            paginationTotalRows={
                              gatePasses.meta &&
                              gatePasses.meta.pagination.count
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {applicantProfile.data &&
                  (pageParams.type === "staff" ||
                    pageParams.type === "delivery_regular") && (
                    <div className="col-md-12 col-lg-7 mb-4">
                      <h5 className="mb-4">
                        Training History{" "}
                        {currentUser.dashboard !== "ac_dashboard" &&
                          currentUser.role_slug !== "pub-jacobs" && (
                            <button
                              style={{ height: "35px" }}
                              onMouseEnter={() => {
                                ReactTooltip.show(toolTipRef.current);
                              }}
                              onMouseLeave={() => {
                                ReactTooltip.hide(toolTipRef.current);
                              }}
                              ref={toolTipRef}
                              data-tip="Add new training for renewing applicant permissions"
                              type="button"
                              className="btn btn-primary btn-sm float-right"
                              onClick={() => onAddTraining()}
                            >
                              Add Training
                            </button>
                          )}
                      </h5>
                      {/* <p ref={toolTipRef} data-tip='Add new training for renewing applicant permissions'></p> */}
                      <div className="card mb-4">
                        <div className="card-body">
                          <table className="table">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Name</th>
                                {/* <th scope="col">Attended On</th> */}
                                <th scope="col">Scheduled</th>
                                <th scope="col">Expires</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getTrainingStructure(
                                applicantProfile.data.applicantTraining
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
      <ModalComponent
        show={showModal}
        header={<b>Profile Picture</b>}
        content={
          <CaptureImageComponent
            applicantId={pageParams.id}
            profileImages={
              applicantProfile.data && applicantProfile.data.applicantPhoto
            }
            status={showModal}
            onPictureCapture={refreshOnCaptureImage}
          />
        }
        onSubmitCallback={() => {
          manageModal(false);
        }}
        onCloseCallback={() => {
          manageModal(false);
        }}
      />

      <ModalComponent
        show={showGatePassModal}
        header={<b>View Gate Pass</b>}
        content={
          <ViewGatePass
            nationality={nationality}
            applicant={applicantProfile.data}
            currentUser={currentUser}
            applicantType={pageParams.type}
            passData={passData}
          />
        }
        showSubmit={false}
        onCloseCallback={() => {
          manageModalSettings(false, 0);
        }}
      />

      <ModalComponent
        show={editGatePassModal}
        header={<b>Edit Gate Pass</b>}
        content={
          <EditGatePass
            errors={editPassErrors}
            applicantType={pageParams.type}
            onInputChange={onInputChange}
            passData={editPassData}
            contracts={contracts}
          />
        }
        showSubmit={true}
        onSubmitCallback={() => updateGatePassData()}
        onCloseCallback={() => {
          manageModalSettings(false, 1);
        }}
      />

      <ModalComponent
        show={editUpdateTrainingModal}
        header={<b>Edit Training Status</b>}
        content={<EditTrainingStatus onRadioInputChange={onRadioInputChange} />}
        showSubmit={trainingStatusSelected}
        onSubmitCallback={() => updateTrainingStatus()}
        onCloseCallback={() => {
          manageModalSettings(false, 2);
        }}
      />

      <ModalComponent
        show={addGatePassModal}
        header={<b>Add Gate Pass</b>}
        content={
          <AddGatePass
            errors={addPassErrors}
            applicantType={pageParams.type}
            onInputChange={onAddGatePassInputChange}
            passData={addPassData}
            contracts={contracts}
          />
        }
        showSubmit={true}
        onSubmitCallback={() => addGatePassData()}
        onCloseCallback={() => {
          manageModalSettings(false, 3);
        }}
      />

      <ModalComponent
        show={addTrainingModal}
        header={<b>Add Training</b>}
        content={
          <ApproveWithCourse
            formState={trainingData}
            trainings={trainings}
            errors={validationMessage}
            onInputChange={onAddTrainingInputChange}
          />
        }
        showSubmit={true}
        onSubmitCallback={() => addTrainingData()}
        onCloseCallback={() => {
          manageModalSettings(false, 4);
        }}
      />

      <ModalComponent
        show={addConfirmationModal}
        header={`${
          confirmModalType === 0
            ? "Confirm Profile Switching!"
            : "Confirm Profile Activation/Deactivation!"
        }`}
        content={
          confirmModalType === 0
            ? `Confirm Profile Switching to ${
                pageParams.type === "staff" ? "Delivery Regular" : "Staff"
              }!`
            : `Confirm ${
                applicantProfile.data.status === "deactivated"
                  ? "Activate"
                  : "Inactivate"
              } User Profile!`
        }
        showSubmit={true}
        onSubmitCallback={() => confirmSwitchOption()}
        onCloseCallback={() => {
          manageModalSettings(false, 5);
        }}
      />

      <ModalComponent
        show={gatePassRejectionModal}
        header={<b>Confirm Gate Pass Rejection!</b>}
        content={
          <RejectApplication
            updateInputChange={onRejectInputChange}
            validation={rejectValidation}
          />
        }
        showSubmit={true}
        onSubmitCallback={() => confirmRejectGatePass()}
        onCloseCallback={() => {
          manageModalSettings(false, 7);
        }}
      />

      <ModalComponent
        show={synConfrimationModal}
        header="Confirm Re-sync profile"
        content={`${
          applicantProfile.data && applicantProfile.data.status === "active"
            ? "Do you need to re-sync this profile ?"
            : "The profile is in " +
              (applicantProfile.data && applicantProfile.data.status) +
              " status, Do you need to re-sync this profile ?"
        }`}
        showSubmit={true}
        onSubmitCallback={() => confirmSyncProfile()}
        onCloseCallback={() => {
          manageModalSettings(false, 8);
        }}
      />

      <ModalComponent
        show={gatePassApprovalModal}
        header={<b>Confirm</b>}
        content={"Are you sure you want to approve Gate Pass?"}
        showSubmit={true}
        onSubmitCallback={() => confirmApproveGatePass()}
        onCloseCallback={() => {
          manageModalSettings(false, 6);
        }}
      />

      {showSyncsuccessPopup && (
        <div className="sync-success sendLoader">
          <div className="sendLoaderWarpper">
            <div class="box">
              <div
                className={spinnerReady ? "spinner active" : "spinner"}
              ></div>
            </div>

            <div className="text">Your request is being processed</div>
            <div className={spinnerReady ? "sub active" : "sub"}>
              It will take <b>10 minutes</b> to complete
            </div>
            <div className={spinnerReady ? "okBtn active" : "okBtn"}>
              <button type="button" onClick={confirmSyncProfileOk}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewVisitor;
