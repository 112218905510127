
//get notifications
export const getNotifications = () => {
    return ([
        {
          "id": 1,
          "notification": "User1 waits approval",
          "image": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png",
          "date": "09.04.2018 - 12:45",
          "type": "User" 
        },
        {
          "id": 2,
          "notification": "User2 waits approval",
          "image": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png",
          "date": "08.04.2018 - 11:45",
          "type": "User" 
        },
        {
          "id": 3,
          "notification": "User3 waits approval",
          "image": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png",
          "date": "07.04.2018 - 10:45",
          "type": "User" 
        }
    
      ]);
};

export const getCustomMenuItems = () => [
  {
    title: "Support",
    route: "SUPPORT",
    id: "d9b7f82e7c6c40bdb4369fb3697880df1246",
    icon: "iconsminds-support",
    actions: ["view"],
  }
];