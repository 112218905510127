import { useEffect } from 'react';
import _ from 'lodash';
import moment from "moment";

/* session maintainance starts here  */
export const getSession = () => {
    if (localStorage.getItem('access_token') === null) {
        return false;
    }
    if (isAuthenticated) {
        return parseJwt(localStorage.getItem('access_token'));
    } else {
        return false;
    }
};

export const setSession = (authResult) => {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.token);
    localStorage.setItem('expires_at', expiresAt);
};

export const logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('allowNewApplicant');
    localStorage.removeItem('allowNewUser');
};

export const isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
};

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

/* session maintainance ends here  */

/* add remove class to body tag starts here  */

export const addBodyClass = className => document.body.classList.add(className);
export const removeBodyClass = className => document.body.classList.remove(className);

export const useBodyClass = (className) => {
    useEffect(
        () => {
            // Set up
            className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

            // Clean up
            return () => {
                className instanceof Array
                    ? className.map(removeBodyClass)
                    : removeBodyClass(className);
            };
        },
        [className]
    );
}
/* add remove class to body tag ends here  */

/**
 * Theme Colors
 */

export const ThemeColors = () => {
    return {
        themeColor1: '#145388',
        themeColor2: '#2a93d5',
        themeColor3: '#6c90a1',
        primaryColor: '#212121',
        foregroundColor: '#ffffff',
        separatorColor: '#d7d7d7'
    };
}


/**
 * Create post data format
 * parameters: @type, @attributes
 * return json
 */

export const getAPIFormDataFormat = (type, attributes) => ({
    "data": {
        "type": type,
        "attributes": attributes
    }
});

/**
 * Update post data format
 * parameters: @type, @id, @attributes
 * return json
 */

export const getAPIUpdateFormDataFormat = (type, id, attributes) => ({
    data: {
        type,
        id,
        attributes
    }
});

export const userPermissionParser = permissions => {
    const parsedPermissions = [];
    const permissionParser = newPermissions => {
        _.forEach(newPermissions, value => {
            if (value.subMenu) {
                permissionParser(value.subMenu);
            }
            parsedPermissions.push({
                route: value.route,
                actions: value.actions
            });
        });
    };
    permissionParser(permissions);
    return parsedPermissions;
};


export const schema = {
    'auth_login': {
        type: 'user_auth',
        status: {
            resolve: status => {
                return status.toUpperCase()
            },
        },
    }
    // todos: {
    //   type: 'todos',
    //   fields: {
    //     title: 'string', // shorthand
    //     status: {
    //       resolve: status => {
    //         return status.toUpperCase()
    //       },
    //     },
    //     created: {
    //       type: 'date', // converts value to a Date object
    //       readOnly: true // removes field for mutations
    //     }
    //   },
    //   relationships: {
    //     user: {
    //       type: 'users',
    //     }
    //   }
    // },
}

export const formatDate = (date) =>  {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = seconds < 10 ? '0'+seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds+ ' ' + ampm;
    return date.getDate() + "/" + (date.getMonth()+1) + "/" +  date.getFullYear() + "  " + strTime;
  }


  export const getLastMonths = (n) => {
    var m =['January','February','March','April','May','June','July','August','September','October','November','December'];
    var last_n_months =[];
    var d = moment(new Date());
    for(var i=0;i<n;i++){
      last_n_months[i] = m[d.month()]+ ' - ' + d.year().toString();
      d = d.subtract(1, 'months');
    }
    return last_n_months;
  }
