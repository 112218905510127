import React from "react";
import Slider from "react-slick";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { VW_ROUTES } from "../../constants";


const DashboardContractorSliderComponent = ({ sliderData }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    const excelData = [];

    // const fileName = "Contractor_live_People-"+moment().format("DD:MM:YYYY_h:mm_a");

    _.map(sliderData[0], item => {
      const obj = {
        "Package Name" : item.name,
        "Live person Count" : item.count
      }
      excelData.push(obj);
    });


    const history = useHistory();

    const getSliderRow = (items) => (
        <div key={_.uniqueId('row-')} className="contractor-slide">
          <div className="row">
          {_.map(items, item => getContractorBoxItem(item))}
          </div>

        </div>
    );



    const getContractorSlides = (sliderData) => _.map(sliderData, items => {

      var loopsNumber = Math.ceil(items.length/9)

      let sliderRow = [];
        if (items.length > 9) {

          for (let i = 0; i < loopsNumber; i++) {
            var countFrom;
            if(i === 0){
              sliderRow.push(getSliderRow(items.slice(i, 9)));
              countFrom = 9
            }
            else{
              sliderRow.push(getSliderRow(items.slice(countFrom, parseInt(countFrom + 6))));
              countFrom = countFrom + 9
            }
          }


        } else {
            sliderRow.push(getSliderRow(items.slice(0, items.length)));
        }
        return (
           sliderRow
        );

    });

    const redirectToContractorApplicantListing = id => {
        history.push({
        pathname: VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING,
        search: `?id=${id}`,
      });
    };

    const getContractorBoxItem = (item) => (
        <div key={_.uniqueId('box-')} className="contractorBox col-md-4">
            <div onClick={()=> redirectToContractorApplicantListing(item.id)} className="countBox d-flex align-self-center position-relative card progress-banner gradient-orange">
                <span className="m-auto">{item.name}</span>
                <span className="position-absolute badge-top-right badge badge-danger badge-pill">{item.count}</span>
            </div>
            {/*<div className="bottomText">{item.title}</div>*/}
        </div>

    );

    return (
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="d-flex justify-content-between">
            <h5 className="mb-4">Contractor Based Live People Counter</h5>
            {/* <ExportExcel csvData={excelData} fileName={fileName} /> */}
          </div>

            <div className="card mb-4" style={{height:'265px'}}>
                <div className="card-body Contractor-slide-wrap">
                    <Slider {...settings}>
                        {getContractorSlides(sliderData)}
                    </Slider>

                </div>
            </div>
        </div>






        // <div className="row justify-content-md-center my-4">
        //     <div className="col-md-12"><h2 className="dashboard-heading">Contractors Details</h2></div>
        //     <div className="col-md-auto col-lg-11">
        //         <Slider {...settings}>
        //             {getContractorSlides(sliderData)}
        //         </Slider>
        //     </div>

        // </div>

    );
}
export default DashboardContractorSliderComponent;
