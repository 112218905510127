/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { AppContext } from "../../context/AppContext";
import { useClient } from 'jsonapi-react';
import _ from "lodash";
import { useMutation } from 'jsonapi-react';
import { useToasts } from 'react-toast-notifications';
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import ChangePassword from "../../components/common/ChangePassword/ChangePassword";
import { validatorModule } from "../../helpers/helpers";
import DISPLAY_TEXTS from "../../helpers/displayTexts";


const UserProfile = () => {
    const initialState = {
        new_password1: '',
        new_password2:''
    }

    const initialErrorState = {
        new_password1: '',
        new_password2:''
    };

    const validationFormat = [
        {
            field: "new_password1",
            isRequired: true,
            fieldName: "Password"
        },
        {
            field: "new_password2",
            fieldName: "Confirm Password",
            isSameAs: {
                field: 'new_password1',
                fieldName: "Password"
            }
        }
    ];
    const {
        currentUser
    } = React.useContext(AppContext);
    const { addToast } = useToasts();
    const token = localStorage.getItem('access_token');
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };
    
    //const {data: roles} = useQuery(['accounts', 'roles'], { client });
    const [changePassword] = useMutation(['auth','password','change'], { client });
    const { showModal, manageModal } = React.useContext(AppContext);
    const [errors, setErrors] = React.useState(initialErrorState);
    const [passData, setPassData] = React.useState(initialState);

    const onPassDataChange = target => {
        setPassData({
            ...passData,
            [target.name]: target.value
        });
    };

    const onChangePasswordSubmit = async() => {
        let errors = validatorModule(passData, validationFormat);
        setErrors(errors);
        if(_.isEmpty(Object.values(errors))){
            const {error, data} = await changePassword(passData);
            if(error){
                addToast(error.detail, { appearance: 'error' });
             }else if(data){
                addToast(DISPLAY_TEXTS.PASSWORD_UPDATED, { appearance: 'success' });
                setPassData(initialState);
                manageModal(false);
             }
        }
    };
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>Profile</h1>
                    <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => manageModal(true)}>Change Password</button>
                    <div className="separator mb-5"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="fieldName col-4">
                                    Full Name
                            </div>
                                <div className="fieldValue font-weight-bold col-8">
                                    {`${currentUser.full_name}`}
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="fieldName col-4">
                                    User Name
                            </div>
                                <div className="fieldValue font-weight-bold col-8">
                                    {currentUser.username}
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="fieldName col-4">
                                    Email
                            </div>
                                <div className="fieldValue font-weight-bold col-8">
                                    {currentUser.email}
                                </div>
                            </div>

                            

                            {/* <div className="row">
                                <div className="fieldName col-4">
                                    Role
                            </div>
                                <div className="fieldValue font-weight-bold col-8">
                                    
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>

            <ModalComponent
                show={showModal}
                header={<b>Change Password</b>}
                content={
                    <ChangePassword passData={passData} errors={errors} onInputChange={onPassDataChange} />
                }
                onSubmitCallback={() => onChangePasswordSubmit() }
                onCloseCallback={() => manageModal(false) }
            />


        </>
    );
};

export default UserProfile;
