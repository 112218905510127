import React from "react";
import { AppReducer, ACTION_TYPES } from "../reducers/AppReducer";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";


const initialState = {
  currentUser: {},
  mainSidebarHidden: false,
  subSidebarHidden: true,
  notifications:[],
  showModalBackDrop: false,
  showRightModal: false,
  showModal: false,
  showLoader: false,
  fireAlarmCounter: 0,
  userPermissions: [],
  pagePermissions: [],
  initialPageParams:{}
};

export const AppContext = React.createContext();
export const AppProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(AppReducer, initialState);
  const updateCurrentUser = (user) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_USER,
      payload: user
    });
  };

  const hideMainSidebar = (status) => {
    dispatch({
      type: ACTION_TYPES.HIDE_MAIN_SIDEBAR,
      payload: status
    });
  };

  const hideSubSidebar = (status) => {
    dispatch({
      type: ACTION_TYPES.HIDE_SUB_SIDEBAR,
      payload: status
    });
  };

  const updateNotifications = (notifications) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_NOTIFICATIONS,
      payload: notifications
    });
  };

  const manageRightModal = (status) => {
    dispatch({
      type: ACTION_TYPES.SHOW_RIGHT_MODAL,
      payload: status
    });
  };

  const manageModal = (status) => {
    dispatch({
      type: ACTION_TYPES.SHOW_MODAL,
      payload: status
    });
  };

  const updateFileAlarmCounter = counter => {
    dispatch({
      type: ACTION_TYPES.UPDATE_FILE_ALARM_COUNTER,
      payload: counter
    });
  };

  const updateUserPermissions = payload => {
    dispatch({
      type: ACTION_TYPES.UPDATE_USER_PERMISSIONS,
      payload
    });
  };

  const updatePagePermissionBasedOnRole = payload => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PAGE_PERMISSIONS,
      payload
    });
  };

  const manageLoader = status => {
    dispatch({
      type: ACTION_TYPES.MANAGE_LOADER,
      payload: status
    });
  };


  const updateInitialPageParams = (location) => {
      const qry = queryString.parse(location.search);
      let url;
      if(location.search.split('&page=')){
        url = location.search.split('&page=')[0]
      }
      else{
        url = location.search
      }
      const params = {
        initialPageNumber : Number(qry.page?qry.page:1),
        currentParams : url,
        initialSearchKey : qry.search?qry.search:'',
        applicantType : qry.applicant?qry.applicant:''
      }
      dispatch({
        type: ACTION_TYPES.UPDATE_INITIAL_PAGE_PARAMS,
        payload: params
      });
  };

  const setPageUrlParams = (setParams) => {
      if(setParams.page > 1){
        history.push({
          search: `${setParams.initialPageParams.currentParams}&page=${setParams.page}${setParams.applicantType?'&applicant='+encodeURI(setParams.applicantType):''}${setParams.searchQueryString?'&search='+encodeURI(setParams.searchQueryString):''}`
        });
      }else{
        history.push({
          search: `${setParams.initialPageParams.currentParams}&page=${1}${setParams.applicantType?'&applicant='+encodeURI(setParams.applicantType):''}${setParams.searchQueryString?'&search='+encodeURI(setParams.searchQueryString):''}`
        });
      }
  }


  return (
    <AppContext.Provider
      value={{
        currentUser: state.currentUser,
        mainSidebarHidden: state.mainSidebarHidden,
        subSidebarHidden: state.subSidebarHidden,
        notifications: state.notifications,
        showRightModal: state.showRightModal,
        showModal: state.showModal,
        showModalBackDrop: state.showModalBackDrop,
        fireAlarmCounter: state.fireAlarmCounter,
        userPermissions: state.userPermissions,
        pagePermissions: state.pagePermissions,
        showLoader: state.showLoader,
        initialPageParams:state.initialPageParams,
        updateCurrentUser,
        hideMainSidebar,
        hideSubSidebar,
        updateNotifications,
        manageRightModal,
        manageModal,
        updateFileAlarmCounter,
        updateUserPermissions,
        updatePagePermissionBasedOnRole,
        manageLoader,
        updateInitialPageParams,
        setPageUrlParams
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
