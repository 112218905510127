import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from 'jsonapi-react';
import _ from  'lodash';
import queryString from 'query-string';
import { VW_ROUTES } from '../../constants';
import { useToasts } from 'react-toast-notifications';
import { validatorModule } from "../../helpers/helpers";

export const ResetForgotPassword = ({location}) => {
    const initialState = {
        new_password1: '',
        new_password2:''
    }

    const initialErrorState = {
        new_password1: '',
        new_password2:''
    };

    const validationFormat = [
        {
            field: "new_password1",
            isRequired: true,
            fieldName: "Password"
        },
        {
            field: "new_password2",
            fieldName: "Confirm Password",
            isSameAs: {
                field: 'new_password1',
                fieldName: "Password"
            }
        }
    ];

    const history = useHistory();
    const { addToast } = useToasts();
    const [formState, updateFormState] = React.useState(initialState);
    const [resetUserPassword] = useMutation(['auth','password','reset']);
    const [errorState, setErrorState] = React.useState(initialErrorState);
    const onInputChangeEvent = (e) => {
        updateFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    React.useEffect(() => {
        let params = queryString.parse(location.search);
        updateFormState({...formState, ...params})
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const onResetPasswordSubmit = async event => {
        event.preventDefault();
        let errors = validatorModule(formState, validationFormat);
        setErrorState(errors);
        if(_.isEmpty(Object.values(errors))){
            const {error, data} = await resetUserPassword(formState);
            if(error){
                addToast(error.detail, { appearance: 'error' });
             }else if(data){
                history.push(VW_ROUTES.LOGIN);
             }
        }
    };

    
    return (
        <>
            <div className="fixed-background login-background"></div>
            <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">
                                    <p className="text-white h2">Welcome to TWRP Registration Portal</p>
                                    <p className="white mb-0">Please reset your password. <br /></p>
                                </div>
                                <div className="form-side"><span className="logo-single"></span>
                                    <h6 className="my-4">Reset Password</h6>
                                    <form onSubmit={onResetPasswordSubmit}>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="new_password1" type="password" className="form-control" />
                                            <span>Password</span>
                                            <p className="error-message">{errorState && errorState.new_password1 ? errorState.new_password1 : ''}</p>
                                        </label>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="new_password2" type="password" className="form-control" />
                                            <span>Confirm Password</span>
                                            <p className="error-message">{errorState && errorState.new_password2 ? errorState.new_password2 : ''}</p>
                                        </label>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit">SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ResetForgotPassword;