import _ from "lodash";
import { getLastMonths } from "../helpers/utils";
//manage slide data group by 10

export const manageSlidesData = data => {
    let slicedArray = [];
    if(data === undefined){
        return [];
    }
    const rowsCount = Math.ceil(data.length/9);
    for (let i = 1; i <= rowsCount; i++) {
        if(i===rowsCount){
            slicedArray.push(data.slice(((i-1)*9), data.length));
        }else{
            slicedArray.push(data.slice(((i-1)*9), (i*9)));
        }
    }
    return (slicedArray);
};

export const manageCompanySlidesData = data => {
    let slicedArray = [];
    if(data === undefined){
        return [];
    }
    const rowsCount =  Math.ceil(data.length/4);
    for (let i = 1; i <= rowsCount; i++) {
        if(i===rowsCount){
            slicedArray.push(data.slice(((i-1)*4), data.length));
        }else{
            slicedArray.push(data.slice(((i-1)*4), (i*4)));
        }
    }
    return (slicedArray);
};

export const mapThreeMonthsVisitData = data => {


    let plotValues = [];
    const plotKeys = {
        staff: 'Staff',
        deliveryAdhoc: 'Delivery Adhoc',
        deliveryRegular: 'Delivery Regular',
        visitor: 'Visitor'
      };
      const colors = {
        staff: '#ff816b',
        deliveryAdhoc: '#3dc0bb',
        deliveryRegular: '#2979dc',
        visitor: '#e673ec'
      };

      let responseData= {};
      let last3Months = getLastMonths(3).map(item => item.substr(0,3));
      responseData['labels'] = last3Months.reverse();
      const plotData = visitsData => {
        return _.map(last3Months, month => {
            const visitData = _.keyBy(visitsData,'monthString');
            plotValues.push(visitData[month]? visitData[month].visits : 0);
            return visitData[month]? visitData[month].visits : 0;
        });
      };

      const dataSets = Object.entries(data).map(([key, value]) => {
        return(
        {
            label: plotKeys[key],
            borderColor: colors[key],
            backgroundColor: colors[key],
            data: plotData(value),
            borderWidth: 2
          });
    });
    responseData['datasets'] = dataSets;
    return  {
        maxValue: _.max(plotValues),
        graphData: responseData
    };
}

export const mapWeeklyVisitData = data => {

    let plotValues = [];
    const plotKeys = {
        staff: 'Staff',
        deliveryAdhoc: 'Delivery Adhoc',
        deliveryRegular: 'Delivery Regular',
        visitor: 'Visitor'
      };
      const colors = {
            staff: '#ff816b',
            deliveryAdhoc: '#3dc0bb',
            deliveryRegular: '#2979dc',
            visitor: '#e673ec'
      };

      let responseData= {};
      const getLabels = labelData => _.map(labelData, label => label.date+"("+label.day+")");
      responseData['labels'] = getLabels(data['staff']);

      const plotData = visitsData => {
        return _.map(visitsData, visitData => {
            plotValues.push(visitData.visits)
            return visitData.visits;
        });
      };

      const dataSets = Object.entries(data).map(([key, value]) => {
        return(
        {
            label: plotKeys[key],
            data: plotData(value),
            borderColor: colors[key],
            pointBackgroundColor: '#ffffff',
            pointBorderColor: colors[key],
            pointHoverBackgroundColor: colors[key],
            pointHoverBorderColor: '#ffffff',
            pointRadius: 6,
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            fill: false
          });
    });
    responseData['datasets'] = dataSets;
    return  {
        maxValue: _.max(plotValues),
        graphData: responseData
    };
};
