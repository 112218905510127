import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
//import { createHashHistory } from "history";
import AppRoute from "./AppRoute";
import ContextRoute from "./ContextRoute";
import AppLayout from "../../layout/AppLayout";
import UserLayout from "../../layout/UserLayout";
import Login from '../../containers/Login/Login';
import ForgotPassword from '../../containers/ForgotPassword/ForgotPassword';
import ResetPassword from '../../containers/ResetPassword/ResetPassword';
import Dashboard from "../../containers/Dashboard/Dashboard";
import VerifyUser from "../../containers/VerifyUser/VerifyUser";
import UserRoles from "../../containers/UserRoles/UserRoles";
import { DashboardProvider } from "../../context/DashboardContext";
import { UserGroupProvider } from "../../context/UserGroupContext";
import { VW_ROUTES, API_BASE_URL } from "../../constants";
import { schema } from "../../helpers/utils"
import { ApiClient, ApiProvider } from 'jsonapi-react';
import Company from "../../containers/Company/Company";
import UserManagement from "../../containers/UserManagement/UserManagement";
import UserProfile from "../../containers/UserProfile/UserProfile";
import Visitor from "../../containers/Visitor/Visitor";
import AddVisitor from "../../containers/Visitor/AddVisitor";
import EditVisitor from "../../containers/Visitor/EditVisitor";
import Staff from "../../containers/Staff/Staff";
import ManageStaff from "../../containers/Staff/ManageStaff";
import EditStaff from "../../containers/Staff/EditStaff";
import DeliveryAdHoc from "../../containers/DeliveryAdHoc/DeliveryAdHoc";
import ManageDeliveryAdHoc from "../../containers/DeliveryAdHoc/ManageDeliveryAdHoc";
import DeliveryRegular from "../../containers/DeliveryRegular/DeliveryRegular";
import ManageDeliveryRegular from "../../containers/DeliveryRegular/ManageDeliveryRegular";
import ViewVisitor from "../../containers/Visitor/ViewVisitor";
import EditDeliveryRegular from "../../containers/DeliveryRegular/EditDeliveryRegular";
import EditDeliveryAdHoc from  "../../containers/DeliveryAdHoc/EditDeliveryAdHoc";
import ApplicantsPending from "../../containers/ApplicantsPending/ApplicantsPending";
import UserTraining from "../../containers/UserTraining/UserTraining";
import ExpiryStatus from "../../containers/ExpiryStatus/ExpiryStatus";
//import PlainLayout from "../../layout/PlainLayout";
import Applicants from "../../containers/Applicants/Applicants";
import ResetForgotPassword from "../../containers/ResetForgotPassword/ResetForgotPassword";
import Notifications from "../../containers/Notifications/Notifications";
import ContractorBasedApplicantsLists from "../../containers/ContractorBasedApplicantsLists/ContractorBasedApplicantsLists";
import GeneralReport from "../../containers/Reports/GeneralReport";
import AcsTransactionReport from "../../containers/Reports/AcsTransactionReport";
import BCAReport from "../../containers/Reports/BCAReport";

const client = new ApiClient({
  url: API_BASE_URL,
  schema,
  mediaType: 'application/json'
});

const NotFound = () => (<div>Not Found</div>);  

const Routes = () => {
  //const history = createHashHistory();
  return (  
    <ApiProvider client={client}>
      <HashRouter hashType="noslash" >
        <Switch>
        <AppRoute path={VW_ROUTES.PROFILE} component={UserProfile} layout={AppLayout} />
          <AppRoute path={VW_ROUTES.VERIFY_USER} component={VerifyUser} layout={UserLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING} context={UserGroupProvider} component={ExpiryStatus} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_PENDING} context={UserGroupProvider} component={ApplicantsPending} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC} context={UserGroupProvider} component={DeliveryAdHoc} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_MANAGE} context={UserGroupProvider} component={ManageDeliveryAdHoc} layout={AppLayout} /> 
          <ContextRoute path={VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT} context={UserGroupProvider} component={EditDeliveryAdHoc} layout={AppLayout} /> 
          <ContextRoute path={VW_ROUTES.APPLICANTS_DELIVERY_REGULAR} context={UserGroupProvider} component={DeliveryRegular} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_MANAGE} context={UserGroupProvider} component={ManageDeliveryRegular} layout={AppLayout} /> 
          <ContextRoute path={VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT} context={UserGroupProvider} component={EditDeliveryRegular} layout={AppLayout} /> 
          <ContextRoute path={VW_ROUTES.APPLICANTS_APPLICANT_VIEW} context={UserGroupProvider} component={ViewVisitor} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_STAFF} context={UserGroupProvider} component={Staff} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_STAFF_MANAGE} context={UserGroupProvider} component={ManageStaff} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_STAFF_EDIT} context={UserGroupProvider} component={EditStaff} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_VISITIOR} context={UserGroupProvider} component={Visitor} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_VISITIOR_ADD} context={UserGroupProvider} component={AddVisitor} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_VISITIOR_EDIT} context={UserGroupProvider} component={EditVisitor} layout={AppLayout} />
          <AppRoute path={VW_ROUTES.FORGOT_PASSWORD} component={ForgotPassword} layout={UserLayout} />
          <AppRoute path={VW_ROUTES.RESET_PASSWORD} component={ResetPassword} layout={UserLayout} />
          <AppRoute path={VW_ROUTES.RESET_FORGOT_PASSWORD} component={ResetForgotPassword} layout={UserLayout} />
          <AppRoute path={VW_ROUTES.LOGIN} component={Login} layout={UserLayout} />
          <ContextRoute path={VW_ROUTES.DASHBOARD} context={DashboardProvider} component={Dashboard} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.ROLES_AND_PERMISSIONS_ROLES} context={UserGroupProvider} component={UserRoles} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.COMPANY} context={UserGroupProvider} component={Company} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.USER_MANAGEMENT} context={UserGroupProvider} component={UserManagement} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.TRAINING} context={UserGroupProvider} component={UserTraining} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.APPLICANTS_HISTORY} context={UserGroupProvider} component={Applicants} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.INFO_DISPLAY_PAGE} context={UserGroupProvider} component={Notifications} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING} context={UserGroupProvider} component={ContractorBasedApplicantsLists} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.REPORTS_GENERAL_REPORTS} context={UserGroupProvider} component={GeneralReport} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.REPORTS_TRANSACTION_REPORT} context={UserGroupProvider} component={AcsTransactionReport} layout={AppLayout} />
          <ContextRoute path={VW_ROUTES.REPORTS_BCA_REPORTS} context={UserGroupProvider} component={BCAReport} layout={AppLayout} />
          <Route path="/notfound" component={NotFound} />
          <Redirect from="/" to={VW_ROUTES.DASHBOARD} />
          <Route component={NotFound} />

        </Switch>
      </HashRouter>
    </ApiProvider>
  )
};

export default Routes;

