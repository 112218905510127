import React from "react";
import DatePicker from "react-datepicker";
import { validatorModule } from "../../../helpers/helpers";
import moment from "moment";
import _ from "lodash";

const GatePassInfoComponent = ({applicantType, isEdit, previousStep, manageStepper, onStepComplete, nextStep, visitorProfile, contracts, currentStep}) => {
    const validationFormat = [
        {
            field: "validFromDate",
            isRequired: true,
            fieldName: "Valid From Date"
        },
        // {
        //     field: "validFromTime",
        //     isRequired: true,
        //     fieldName: "Valid From Time"
        // },
        {
            field: "contract",
            isRequired: true,
            fieldName: "Contract"
        },
        // {
        //     field: "validToDate",
        //     isRequired: true
        // },
        // {
        //     field: "validToTime",
        //     isRequired: true
        // },
        {
            field: "hostName",
            isRequired: true,
            fieldName: "Host Name"
        },
        {
            field: "purpose",
            isRequired:  true,
            fieldName: "Purpose Of Visit"
        }

    ];



    const initialState = {
        validFromDate: '',
        validFromTime: '00:00:00',
        //validToDate: '',
        //validToTime: '',
        contract: '',
        hostName: '',
        poNumber: '',
        doNumber: '',
        purpose:''
    };

    const initialErrorState = {
        validFromDate: '',
        validFromTime: '',
        //validToDate: '',
        //validToTime: '',
        contract: '',
        hostName: '',
        poNumber: '',
        doNumber: '',
        purpose:''
    };

    const [formState, setFormState] = React.useState(initialState);
    const [errorState, setErrorState] = React.useState(initialErrorState);
    const onInputChange = ({ name, value }) => {
        let formData = { ...formState };
        formData[name] = value;
        setFormState(formData);

        // if(name === "validFromDate"){
        //   dateField.current.focus();
        // }
    };

    const onPreviousClick = () => {
        previousStep();
        manageStepper('prev');
    };

    const onFocusChange = () => {
        if (_.filter(Object.values(errorState)).length !== 0) {
            let errors = validatorModule(formState, validationFormat);
            setErrorState(errors);
        }
    };

    const onNextClick = () => {
        let errors = validatorModule(formState, validationFormat);
        setErrorState(errors);
        if (Object.keys(errors).length === 0) {
            onStepComplete(formState);
            nextStep();
            manageStepper('next');
        }
    };
    // const dateField = React.useRef();
    return (
        <div id="step-3" className="step700">
            <form id="form-step-3" className="tooltip-label-right">
                <div className="form-row">
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.validFromDate ? 'hasValidationError' : ''}`}>
                    <label htmlFor="vehicle-number w-100">Valid From*</label>
                        <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            //startDate={new Date("01/01/1950")}
                            minDate={moment().toDate()}
                            selected={formState.validFromDate}
                            onBlur={() => onFocusChange()}
                            onChange={date => onInputChange({ name: 'validFromDate', value: date})}
                        />

                        <p className="error-message">{errorState && errorState.validFromDate ? errorState.validFromDate : ''}</p>
                    </div>
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.contract ? 'hasValidationError' : ''}`}>
                        <label htmlFor="email">TWRP Contract Name*</label>
                        <select onBlur={() => onFocusChange()} value={formState.contract} onChange={(e) => onInputChange(e.target)} name="contract" className="form-control select2-single select2-hidden-accessible" data-width="100%" aria-hidden="true">
                            <option value="">Select</option>
                            {_.map(contracts, contract => <option key={_.uniqueId('contract-')} value={contract.id}>{contract.name}</option>)}
                        </select>
                        <p className="error-message">{errorState && errorState.contract ? errorState.contract : ''}</p>
                    </div>
                    {/* <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.validFromTime ? 'hasValidationError' : ''}`}>
                        <DatePicker
                            selected={formState.validFromTime}
                            className="form-control"
                            onChange={time => onInputChange({ name: 'validFromTime', value: time })}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            placeholderText="HH:MM"
                            dateFormat="h:mm aa"
                            onBlur={() => onFocusChange()}
                        />
                        <p className="error-message">{errorState && errorState.validFromTime ? errorState.validFromTime : ''}</p>
                    </div> */}
                </div>


                { applicantType !== "visitor" &&
                <div className="form-row">
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.poNumber ? 'hasValidationError' : ''}`}>
                        <label htmlFor="poNumber">PO Number</label>
                        <input type="text" className="form-control" name="poNumber"
                            onBlur={() => onFocusChange()}  id="poNumber" placeholder="PO Number" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.poNumber ? errorState.poNumber : ''}</p>
                    </div>
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.doNumber ? 'hasValidationError' : ''}`}>
                        <label htmlFor="doNumber">DO Number</label>
                        <input type="text" className="form-control" name="doNumber"
                            onBlur={() => onFocusChange()}  id="doNumber" placeholder="DO Number" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.doNumber ? errorState.doNumber : ''}</p>
                    </div>
                </div>
}
                <div className="form-row">
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.hostName ? 'hasValidationError' : ''}`}>
                        <label htmlFor="hostName">Host Name*</label>
                        <input type="text" className="form-control" name="hostName"
                            onBlur={() => onFocusChange()}  id="hostName" placeholder="Host Name" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.hostName ? errorState.hostName : ''}</p>
                    </div>

                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.purpose ? 'hasValidationError' : ''}`}>
                        <label htmlFor="designation">Purpose of visit*</label>
                        <input type="text" className="form-control" name="purpose"
                            onBlur={() => onFocusChange()}  id="purpose" placeholder="Purpose of visit" onChange={(e) => onInputChange(e.target)} value={formState.purpose}  />
                        <p className="error-message">{errorState && errorState.purpose ? errorState.purpose : ''}</p>
                    </div>
                </div>


                {/* <div className="form-row">
                <div className={`form-group position-relative error-l-100 col-md-6 ${errorState.contract ? 'hasValidationError' : ''}`}>
                        <label htmlFor="email">TWRP Contract Name</label>
                        <select onBlur={() => onFocusChange()} value={formState.contract} onChange={(e) => onInputChange(e.target)} name="contract" className="form-control select2-single select2-hidden-accessible" data-width="100%" tabIndex="-1" aria-hidden="true">
                            <option value="">Select</option>
                            {_.map(contracts, contract => <option key={_.uniqueId('contract-')} value={contract.id}>{contract.name}</option>)}
                        </select>
                        <p className="error-message">{errorState && errorState.contract ? errorState.contract : ''}</p>
                    </div>
                    </div> */}

            </form>
            <div className="btn-toolbar custom-btn-toolbar text-center">
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onPreviousClick()}>Previous</button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onNextClick()}>Next</button>
            </div>
        </div>);
};

export default GatePassInfoComponent;
