import React from "react";
import _ from "lodash";
import NotificationCardItem from "../../components/common/NotificationCardItem/NotificationCardItem";

const DashboardCounterComponent = ({counters}) => {
    const getNotificationCounters = (counters) => (_.map(counters, item => <NotificationCardItem key={_.uniqueId('counter-')} cardSize={3} item={item} />));
    return ( 
    		<div className="row">
    			<div className="col-md-12"><h2 className="dashboard-heading">Individual Notification Details</h2></div>
        		{getNotificationCounters(counters)}
    		</div>
    		);
};
export default DashboardCounterComponent;

