import React from "react";
import _ from "lodash";
import moment from 'moment';
import { AppContext } from "../../context/AppContext";
import NotificationCardItem from "../../components/common/NotificationCardItem/NotificationCardItem";
import NotificationAccountStatus from "../../components/common/NotificationAccountStatus/NotificationAccountStatus"

const DashboardTopNotificationBar = ({dashboardStats, pCounter, tCounter, aCounter, dashboard }) => {
	//const getNotificationCounters = (counters) => (_.map(counters, item => <NotificationCardItem key={_.uniqueId('counter-')} cardSize={4} item={item} />));
	const [peopleCounter, setPeopleCounter] = React.useState({});
	const [trafficCounter, setTrafficCounter] = React.useState({});
	const [alertCounter, setAlertCounter] = React.useState({});
  const [dashboardStatsData, setdashboardStatsData] = React.useState({});
	const {
		fireAlarmCounter,
    currentUser
	} = React.useContext(AppContext);
	React.useEffect(() => {
		if (pCounter) {
			const toLocalFromDateTime = new Date(pCounter.lastUpdated);
			setPeopleCounter({
				"title": "people counter",
				"type": "standard",
				"count": pCounter.peopleCount,
				"metaData": [{
					"isLink": false,
					"key": "Last Update on:",
					"value": moment(toLocalFromDateTime).format('DD/MM/YYYY hh:mm A')
				}]
			})
		}
	}, [pCounter]);

	React.useEffect(() => {
		if (tCounter) {
			const toTLocalFromDateTime = new Date(tCounter.lastUpdated);
			setTrafficCounter({
				"title": "traffic counter",
				"type": "standard",
				"count": tCounter.trafficCount,
				"metaData": [{
					"isLink": false,
					"key": "Last Update on:",
					"value": moment(toTLocalFromDateTime).format('DD/MM/YYYY hh:mm A')
				}]
			})
		}
	}, [tCounter]);

	React.useEffect(() => {
		setAlertCounter({
			"title": "Overnight Stay",
			"type": "alert",
			"count": fireAlarmCounter,
			"hasLink": false,
			"metaData": [{
				"isLink": true,
				"key": "View Details",
				"value": "#info"
			}]
		})
	}, [fireAlarmCounter]);

  React.useEffect(() => {
    setdashboardStatsData(dashboardStats)
  }, [dashboardStats]);




	React.useEffect(() => {
		if (aCounter) {
			setAlertCounter({
				"title": "Overnight Stay",
				"type": "alert",
				"count": aCounter.personCount,
				"hasLink": false,
				"metaData": [{
					"isLink": true,
					"key": "View Details",
					"value": "#info"
				}]
			});
		}
	}, [aCounter]);

  const ColSize = () => {
    if(currentUser.role_slug === 'super-admin' || currentUser.role_slug === 'nsg-admin'){
      return 3;
    }else{
      return 4
    }
  }

	return (

		<div className="row">
			<div className="col-md-12"><h5 className="dashboard-heading">Notification Details</h5></div>
			<NotificationCardItem key={_.uniqueId('counter-')} cardSize={ColSize()} item={peopleCounter} />
			<NotificationCardItem key={_.uniqueId('counter-')} cardSize={ColSize()} item={trafficCounter} />
			{dashboard === "nsg_dashboard" &&
				<NotificationCardItem key={_.uniqueId('counter-')} cardSize={ColSize()} item={alertCounter} />
			}
    { (currentUser.role_slug === 'super-admin' || currentUser.role_slug === 'nsg-admin') &&
      <NotificationAccountStatus key={_.uniqueId('counter-')} cardSize={3} item={dashboardStatsData} />
    }

			{/* {getNotificationCounters(counters)} */}
		</div>);
};
export default DashboardTopNotificationBar;

