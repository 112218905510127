export const VW_ROUTES = {
  RESET_FORGOT_PASSWORD: "/reset-forgot-password",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  RESET_PASSWORD: "/reset-password",
  COMPANY: "/company",
  ROLES_AND_PERMISSIONS_ROLES: "/rolespermissions-roles",
  USER_MANAGEMENT: "/user-management",
  TRAINING: "/applicant-training",
  PROFILE: "/profile",
  VERIFY_USER: "/verification/:email_key",
  APPLICANTS: "/applicants",
  APPLICANTS_HISTORY: "/all-applicants",
  APPLICANTS_VISITIOR: "/visitor",
  APPLICANTS_VISITIOR_ADD: "/add-visitor",
  APPLICANTS_VISITIOR_EDIT: "/edit-visitor",
  APPLICANTS_APPLICANT_VIEW: "/applicant/view/",
  APPLICANTS_STAFF: "/staff",
  APPLICANTS_STAFF_MANAGE: "/manage-staff",
  APPLICANTS_STAFF_VIEW: "/applicant/view/",
  APPLICANTS_STAFF_EDIT: "/applicant/staff/edit",
  APPLICANTS_DELIVERY_AD_HOC: "/delivery-adhoc",
  APPLICANTS_DELIVERY_AD_HOC_MANAGE: "/manage-delivery-adhoc",
  APPLICANTS_DELIVERY_AD_HOC_EDIT: "/applicant/delivery-adhoc/edit",
  APPLICANTS_DELIVERY_REGULAR: "/delivery-regular",
  APPLICANTS_DELIVERY_REGULAR_MANAGE: "/manage-delivery-regular",
  APPLICANTS_DELIVERY_REGULAR_EDIT: "/applicant/delivery-regular/edit",
  APPLICANTS_PENDING: "/applicant/profile/pending",
  APPLICANTS_EXPIRY_STATUS_LISTING: "/applicant/expiry-status/lists",
  CONTRACTOR_APPLICANTS_LISTING: "/contractor/applicant/lists",
  INFO_DISPLAY_PAGE: "/info",
  REPORTS: "",
  REPORTS_GENERAL_REPORTS: "/reports/general",
  //APPLICANTS_OVERNIGHT_STAY_DETAILS:'/applicants/overnight/stay/details',
  REPORTS_TRANSACTION_REPORT: "/reports/transaction",
  REPORTS_BCA_REPORTS: "/reports/bca",
  SUPPORT: "[new-tab]https://sms.vwatch.ai/",
};

// export const API_BASE_URL = "https://vwatch.v2.staging.0x1235.pw/api/v1";
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://twrp-dev.vwatch.sg/api/v1";
export const CCTV_URL = "http://202.55.92.163:36100/portal/";
export const DEVICE_SERVICE_BASE_URL =
  "https://twrp.vwatch.pro/services/device";
export const SET_PAGE_COUNT = 5000;
