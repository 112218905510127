import React from "react";
import _ from "lodash";
import { VW_ROUTES } from "../../../constants";


const NavigationLink = ({ item, currentPage, onNavigationItemClickCallback }) => {
  const applicants = [
    VW_ROUTES.APPLICANTS_HISTORY,
    VW_ROUTES.APPLICANTS_VISITIOR, 
    VW_ROUTES.APPLICANTS_VISITIOR_ADD, 
    VW_ROUTES.APPLICANTS_VISITIOR_EDIT, 
    VW_ROUTES.APPLICANTS_APPLICATION_VIEW, 
    VW_ROUTES.APPLICANTS_STAFF, 
    VW_ROUTES.APPLICANTS_STAFF_MANAGE, 
    VW_ROUTES.APPLICANTS_STAFF_VIEW, 
    VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC, 
    VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_MANAGE, 
    VW_ROUTES.APPLICANTS_DELIVERY_REGULAR, 
    VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_MANAGE, 
    VW_ROUTES.APPLICANTS_PENDING, 
    VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT,
    VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT,
    VW_ROUTES.APPLICANTS_STAFF_EDIT,
    VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING,
    VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING,
    VW_ROUTES.INFO_DISPLAY_PAGE,
    VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING
  ];
  if (_.includes(applicants, currentPage)) {
    currentPage = VW_ROUTES.APPLICANTS
  }

  const reports = [
    VW_ROUTES.REPORTS_GENERAL_REPORTS,
    VW_ROUTES.REPORTS_TRANSACTION_REPORT
  ];

  if (_.includes(reports, currentPage)) {
    currentPage = VW_ROUTES.REPORTS
  }

  return (
    <li className={VW_ROUTES[item.route] === currentPage ? "active" : ""}>
      { /* eslint-disable-next-line */}
      <a onClick={(e) => onNavigationItemClickCallback(e, item.id)} href="#">
        <i className={item.icon}></i>
        <span>{item.title}</span>
      </a>
    </li>
  )
};

export default NavigationLink;