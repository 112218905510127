/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from 'jsonapi-react';
import _ from "lodash";
import axios from 'axios';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { API_BASE_URL, SET_PAGE_COUNT } from "../../constants";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import StepperComponent from "../../components/common/StepperComponent/StepperComponent";
import StepWizard from 'react-step-wizard';
import PersonalComponent from "../../components/common/PersonalComponent/PersonalComponent";
import AdditionalInfoComponent from "../../components/common/AdditionalInfoComponent/AdditionalInfoComponent";
import VerificationComponent from "../../components/common/VerificationComponent/VerificationComponent";
import FinalStepperComponent from "../../components/common/FinalStepperComponent/FinalStepperComponent";
import GatePassComponent from "../../components/common/GatePassComponent/GatePassComponent";

const ManageDeliveryAdHoc = () => {
    const initialStepper = [
        {
            id: 1,
            title: 'Personal Details',
            isCurrent: true,
            isCompleted: false
        }, {
            id: 2,
            title: 'Additional Info',
            isCurrent: false,
            isCompleted: false
        }, {
            id: 3,
            title: 'Gate Pass Info',
            isCurrent: false,
            isCompleted: false
        }, {
            id: 4,
            title: 'Verification Info',
            isCurrent: false,
            isCompleted: false
        }, {
            id: 5,
            title: 'Completed',
            isCurrent: false,
            isCompleted: false
        }];

    const history = useHistory();
    const { addToast } = useToasts();
    const [currentStep, setCurrentStep] = React.useState(1);
    //const [applicantId, setApplicantId] = React.useState('');
    const [stepper, setStepper] = React.useState(initialStepper);
    const [nationality, setNationality] = React.useState([]);
    const [wizardInstance, setWizardInstance] = React.useState();
    const [formState, setFormState] = React.useState({});
    const token = localStorage.getItem('access_token');
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };
    const [createDeliveryAdHoc] = useMutation(['applicant', 'profile']);
    //const [ createGatePass ] = useMutation(['applicant', 'profile', applicantId, 'gate-pass']);
    const companies = useQuery(['organisation', 'company',{page: {
        number: 1,
        size: SET_PAGE_COUNT
      }}], { client });
    const contracts = useQuery(['organisation', 'contract',{page: {
        number: 1,
        size: SET_PAGE_COUNT
      }}], { client });

    React.useEffect(() => {
        const options = {
            method: 'OPTIONS',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `JWT ${token}`
            },
            url: `${API_BASE_URL}/applicant/profile`,
        };
        axios(options).then(response => {
            const { data: {
                data: {
                    actions: {
                        POST: {
                            // applicantType: {
                            //     choices: applicantTypeChoices
                            // },
                            nationality: {
                                choices: nationalityChoices
                            }
                        }
                    }
                }
            } } = response;
            setNationality(nationalityChoices);
        });
    }, []);

    React.useEffect(() => {
        let stepperData = [...stepper];
        const updatedStepperData = _.map(stepperData, item => {
            if (currentStep === stepperData.length) {
                item['isCurrent'] = true;
                item['isCompleted'] = true;
            } else if (item.hasOwnProperty('id') && item.id < currentStep) {
                item['isCurrent'] = false;
                item['isCompleted'] = true;
            } else if (item.hasOwnProperty('id') && item.id === currentStep) {
                item['isCurrent'] = true;
                item['isCompleted'] = false;
            } else {
                item['isCurrent'] = false;
                item['isCompleted'] = false;
            }
            return item;
        });
        setStepper(updatedStepperData);
    }, [currentStep]);

    const manageStepper = async (direction) => {
        if (direction === 'prev') {
            setCurrentStep(step => step === 1 ? 1 : step - 1);
        } else {
            setCurrentStep(step => step === stepper.length ? step : step + 1);
        }
    };

    const mergeDataOnStepCompletion = (formData) => {
        const mergedData = { ...formState, ...formData }
        setFormState(mergedData);
        if(currentStep === 4){
            onSubmitForm();
        }


    };

    const manageStepperWizard = (e) => {
        setWizardInstance(e);
       // wizardInstance = e;
    };

    const getDatesOnRequiredFormat = (dateValue, timeValue) => {
        //const timeVal = moment.parseZone(timeValue).format('LT');
        const createdDate= moment(dateValue).format("YYYY-MM-DD")+" "+ moment(timeValue, ["h:mm A"]).format("HH:mm");
        var pDate = new Date(createdDate);
        return moment.utc(moment(pDate)).format();
    };

    const onSubmitForm = async() => {
        let extraFields = ['identification_type', 'purpose'];
        if (formState.identification_type === 'nric') {
            extraFields.push('finNumber', 'finAlpha', 'passport');
        } else if (formState.identification_type === 'fin') {
            extraFields.push('nricNumber', 'nricAlpha', 'passport');
        } else if (formState.identification_type === 'passport') {
            extraFields.push('nricNumber', 'nricAlpha', 'finNumber', 'finAlpha');
        }
        var formData = _.omit(formState, extraFields);
        // const fromTime = moment.parseZone(formState.validFromTime).format('LT');
        // const fromDate= moment(formState.validFromDate).format("YYYY-MM-DD")+" "+ moment(fromTime, ["h:mm A"]).format("HH:mm");
        // var pDate = new Date(nDate);

        // let utcDate = moment.utc(moment(pDate)).format()

        // //gte existing UTC date and convert to localtime
        // var stillUtc = moment.utc(utcDate).toDate();
        // var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        formData['dateOfBirth'] = moment(formData.dateOfBirth).format("DD-MM-YYYY");
        formData['workPermitExpiry'] = formData.workPermitExpiry !== "" ? moment(formData.workPermitExpiry).format("DD-MM-YYYY"): null;
        const { data, error } = await createDeliveryAdHoc(formData);
        //console.log('data', formData)
        if(error === undefined){
            //setApplicantId(data.id);
            //console.log(formData)
            const gatePassData = {
                "data": {
                    "type": "applicant",
                    "attributes": {
                "fromDateTime": getDatesOnRequiredFormat(formData.validFromDate, formData.validFromTime),
			    //"toDateTime": getDatesOnRequiredFormat(formData.validToDate, formData.validToTime),
			    "hostName": formState.hostName,
                "purposeOfVisit": formState.purpose,
                "poNumber": formState.poNumber,
			    "doNumber": formState.doNumber,
			    "vehicleNumber": formState.vehicleNumber,
                "applicant": data.id,
                "contract": formState.contract
            }}};


            // console.log(gatePassData)
            // const options = {
            //     method: 'POST',
            //     headers: {
            //         'Authorization': `JWT ${token}`
            //     },
            //     url: `${API_BASE_URL}/applicant/${data.id}/profile`,
            // };

            // const { error: gatePassError } = await createGatePass(gatePassData);
            // console.log(gatePassError);
            axios.post(`${API_BASE_URL}/applicant/profile/${data.id}/gate-pass`, gatePassData, {
                headers: {
                    'Authorization': `JWT ${token}`
                }
              })
              .then((response) => {
                wizardInstance.nextStep();
                manageStepper('next');
                addToast(DISPLAY_TEXTS.DELIVERY_ADHOC_ADDED, { appearance: 'success' });
              })
              .catch((error) => {
                console.log(error)
              })

            //const { error: gatePassError } = await createGatePass(gatePassData);
        //     if(gatePassError === undefined){

        //     }else{
        //         addToast(gatePassError.detail, { appearance: 'error' });
        //    }

       }else{
            addToast(error.detail, { appearance: 'error' });
       }

    };

    const onBackButtonClick = () => {
      history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="col-12">

                    <h1>New Delivery AdHoc</h1>


                    <button type="button" className="btn btn-primary btn-sm float-right" onClick={onBackButtonClick}>BACK</button>
                    <div className="separator mb-5"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <StepperComponent stepper={stepper} />
                            <StepWizard instance={(e) => manageStepperWizard(e)} className="customStepperWizard" initialStep={1} >
                                <PersonalComponent applicantType={"delivery_adhoc"} onStepComplete={mergeDataOnStepCompletion} nationality={nationality} manageStepper={manageStepper} acRestrictedParam={false} currentStep={currentStep}/>
                                <AdditionalInfoComponent identificationType={formState.identification_type} applicantType={"delivery_adhoc"} companies={companies.data}  onStepComplete={mergeDataOnStepCompletion} manageStepper={manageStepper} acRestrictedParam={false} currentStep={currentStep}/>
                                <GatePassComponent onStepComplete={mergeDataOnStepCompletion} manageStepper={manageStepper} contracts={contracts.data} currentStep={currentStep}/>
                                <VerificationComponent applicantType={"delivery_adhoc"} companies={companies.data} contracts={contracts.data} nationality={nationality} onFinish={onSubmitForm} verificationData={formState} manageStepper={manageStepper} />
                                <FinalStepperComponent manageStepper={manageStepper} />
                            </StepWizard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageDeliveryAdHoc;
