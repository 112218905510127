
import React from "react";
import { useMutation } from 'jsonapi-react';
import { parseAPIResponseError } from "../../helpers/helpers";
import { useToasts } from 'react-toast-notifications';
import DISPLAY_TEXTS from "../../helpers/displayTexts";

export const ForgotPassword = () => {
    const initialState = {
        email: ''
    };
    const { addToast } = useToasts();
    const [formState, updateFormState] = React.useState(initialState);
    const [error, updateErrorMessage] = React.useState({}); 
    const [forgotUserPassword] = useMutation(['auth','password','forgot']);

    const onInputChangeEvent = (e) => {
        updateFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };
    const onForgotPasswordSubmit = async event => {
        event.preventDefault();
        const {error, data} = await forgotUserPassword(formState);
         updateErrorMessage(parseAPIResponseError(error));
         if(error){
            updateErrorMessage(parseAPIResponseError(error));
         }else if(data){
            updateFormState(initialState);
            addToast(DISPLAY_TEXTS.PASSWORD_RESET_MAIL_SUCCESS, { appearance: 'success' });
         }
        // if (data !== undefined) {
        //          setSession(data);
        //     }
        // doForgotPassword(formState).then((response, error) => {
        //     if(response.status === 200){
        //         history.push(VW_ROUTES.LOGIN);
        //     }
        // });
    };
    return (
        <>
            <div className="fixed-background login-background"></div>
            <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">
                                    <p className="text-white h2">Welcome to TWRP Registration Portal</p>
                                    <p className="white mb-0">Please use your e-mail to reset your password. <br /> {/*If you are not a member, 
                                please 
                                <a href="#" className="white">register</a>.*/}</p>
                                </div>
                                <div className="form-side"><span className="logo-single"></span>
                                    <h6 className="my-4">Forgot Password</h6>
                                    <form onSubmit={onForgotPasswordSubmit}>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} value={formState.email} name="email" type="text" className="form-control" />
                                            <span>E-mail</span>
                                            <p className="login-error-message">{error && error.field === 'email' ? error.errorMsg : ''}</p>
                                        </label>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit">RESET</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ForgotPassword;