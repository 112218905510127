import React from "react";

const FinalStepperComponent = ({isEdit}) => {
    React.useEffect(() => {
       // props.manageStepper('next');
    },[]);
    
    // const onPreviousClick = () => {
    //     props.previousStep();
    //     manageStepper('pre');
    // };

    // const onNextClick = () => {
    //     props.nextStep();
    // };

    return (
        <div id="step-5">
        <div className="glyph-icon simple-icon-check profile-created-icon"></div>
        <h2 className="text-center">Thank You</h2>
        <p className="muted text-center p-4 font-weight-bold">
            {
                isEdit === true && 'Profile updated successfully'
            }
            {
                isEdit !== true && 'Profile created successfully & Waiting for Approval process'
            }
        </p>
    </div>);
};

export default FinalStepperComponent;
