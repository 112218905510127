import React, { useEffect } from "react";
import _ from "lodash";
import Select from 'react-select';
import { AppContext } from "../../../context/AppContext";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactTooltip from 'react-tooltip';


const ReportFilter = ({ onSearchInputChange, onNationalityChange, onCompanyChange, onContractorChange, onStartDateChange, onEndDateChange, nationality, companies, contracts, onExport, buttonVisibility, exportButtonForceDisabled, enableExport }) => {
    const [searchText, setSearchText] = React.useState('');
    const [nation, setNation] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [contract, setContract] = React.useState('');
    const [fromDate, setFromDate] = React.useState(moment().subtract(1, 'M').toDate());
    const [toDate, setToDate] = React.useState(moment().toDate());
    const [reportMaxDate, setreportMaxDate] = React.useState(moment().toDate());
    //const [type, setType] = React.useState('');
    //const delayedQuery = React.useCallback(_.debounce(q => onSearchInputChange(q), 500), []);


    const setFilterData = () => {
      onSearchInputChange(searchText);
      if(nation){
        onNationalityChange(nation.value === "" ? undefined : nation.value);
      }
      if(company){
        onCompanyChange(company.value === "" ? undefined : company.value);
      }
      if(contract){
        onContractorChange(contract.value === "" ? undefined : contract.value);
      }
      onStartDateChange(fromDate === "" ? undefined : fromDate );
      onEndDateChange(toDate === "" ? undefined : toDate );
    }

    const onSubmitSearch = () => {
      setFilterData();
    };

    const exportData = () => {
      onExport();
    }

    const { pagePermissions } = React.useContext(AppContext);
    const parseToSelectFormat = (options, keys) => _.map(options, option => ({label:option[keys.label] , value: option[keys.value]}));
    const onFilterChange = option => {
        exportButtonForceDisabled();
        const { name, value, label } = option;
        switch (name) {
            case 'nationality':
                setNation({label, value});
                break;
            case 'company':
                setCompany({label, value});
                break;
            case 'contract':
                setContract({label, value});
                break;
            case 'fromDate':
              setFromDate(value === null ? undefined : value);
              break;
            case 'toDate':
                setToDate(value === null ? undefined : value);
                break;
            default:
                break;
        }
    };

    const resetFilterOptions = () => {
        setNation('');
        onNationalityChange(undefined);
        setCompany('');
        onCompanyChange(undefined);
        setContract('');
        onContractorChange(undefined);
        onSearchInputChange(undefined);
        setSearchText("");
        setFromDate(moment().subtract(1, 'M').toDate());
        setToDate(moment().toDate());
        onStartDateChange(moment().subtract(1, 'M').toDate());
        onEndDateChange(moment().toDate());
        enableExport()
    };

    useEffect(() => {
      if(fromDate){
        if(moment(fromDate).add(1, 'M').toDate() > moment().toDate()){
          setreportMaxDate(moment().toDate());
          setToDate(moment(fromDate).toDate());
        }else{
          setreportMaxDate(moment(fromDate).add(1, 'M').toDate());
          setToDate(moment(fromDate).add(1, 'M').toDate());
        }
      }else{
        setToDate(undefined);
      }

    },[fromDate]);

    const searchByText = option => {
      exportButtonForceDisabled();
      setSearchText(option)
    }


    return (
      <>
        <div className="reports-filter-section pb-3">
            <div className="row mb-2">
                <div className="col-6">
                    <span>Nationality</span>
                    <Select value={nation} options={parseToSelectFormat(nationality, {label: 'display_name', value: 'value'})} onChange={opt => onFilterChange({name: 'nationality', value: opt.value, label: opt.label})}  name="nationality" placeholder="Select" />
                </div>




                <div className="col-6">
                    <span>Contractor</span>
                    <Select value={contract} options={parseToSelectFormat(contracts, {label: 'name', value: 'id'})} onChange={opt => onFilterChange({name: 'contract', value: opt.value, label: opt.label})}  name="contract" placeholder="Select" />
                </div>
            </div>

            <div className="row mb-2">

                <div className="col-6">
                    <span>Company</span>
                    <Select value={company} options={parseToSelectFormat(companies, {label: 'name', value: 'id'})} onChange={opt => onFilterChange({name: 'company', value: opt.value, label: opt.label})}  name="company" placeholder="Select" />
                </div>

                <div className="col-6">
                    <span>Report Range <i className="infoButton" data-tip="Range should be maximum one month">i</i></span>

                    <div className="d-flex w-100">
                    <div className={`w-100 ${!fromDate ? 'hasValidationError' : ''}`}>
                        <DatePicker maxDate={moment().toDate()} selected={fromDate} onChange={date => onFilterChange({ name: 'fromDate', value: date, label:'' })} className="form-control" placeholderText="YYYY-MM-DD" dateFormat="yyyy-MM-dd" />
                      </div>
                      <div className="p-2">
                        -
                      </div>
                      <div className={`w-100 ${!toDate ? 'hasValidationError' : ''}`}>
                        <DatePicker disabled={fromDate ? false : true} minDate={fromDate} maxDate={reportMaxDate} selected={toDate} onChange={date => onFilterChange({ name: 'toDate', value: date, label:'' })} className="form-control" placeholderText="YYYY-MM-DD" dateFormat="yyyy-MM-dd" />
                      </div>
                    </div>

                </div>
            </div>

            <div className="row mb-2">
                <div className="col-6">
                    <span>Search</span>
                    <div className="report-search-container">
                    <input type="text" className="form-control" name="search" value={searchText}
                        id="search" placeholder="Type minimum two characters for searching." onChange={(e) => searchByText(e.target.value)}  />

                    </div>
                </div>
                <div className="col-6 d-flex align-items-end">
                    <button disabled={!fromDate && !toDate ? true : false} className="btn btn-primary float-right rounded-0" onClick={(e) => onSubmitSearch()} >Filter</button>
                    <button onClick={resetFilterOptions} className="btn btn-danger float-right rounded-0 ml-1">Reset</button>
                    {_.includes(pagePermissions, 'download') && <button onClick={exportData} className="btn btn-primary float-right rounded-0 ml-1" disabled={buttonVisibility}>Export</button>}
                </div>
            </div>

        </div>
        <ReactTooltip />
      </>
    );
};

export default ReportFilter;
