import React from "react";
import { Route } from "react-router-dom";
import { isAuthenticated } from "../../helpers/utils";
import { Redirect } from "react-router-dom";

const ContextRoute = ({
    context: Provider,
    component: Component,
    layout: Layout,
    ...rest
}) => {
return(<Route exact {...rest} render={(props) =>{
    if(!isAuthenticated){
        return <Redirect to="/login" />
    }
    return(<Layout {...props}>
        <Provider>
        <Component {...props} />
        </Provider>

    </Layout>)}
} />);};

export default ContextRoute;

