/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import { AppContext } from "../../context/AppContext";
import _ from "lodash";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from 'react-toast-notifications';
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { parseIdentificationType } from "../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";

const Applicants = () => {
  const { addToast } = useToasts();

  let page;

  const pageNumber = window.location.hash.split("page=")[1];
  if(pageNumber){
    page = Number(pageNumber.split("&")[0])
  }else{
    page = 1
  }

  let initialSearchKey;
  const searchWord = window.location.hash.split("search=")[1];
  if(searchWord){
    initialSearchKey = decodeURI(searchWord)
  }else{
    initialSearchKey = '';
  }


  let initialApplicantType;
  const applicantTypeUrl = window.location.hash.split("applicant=")[1];
  if(applicantTypeUrl){
    const applicant = applicantTypeUrl.split('&')[0]
    initialApplicantType = decodeURI(applicant)
  }else{
    initialApplicantType = undefined;
  }



  let initialfilterWithPhotos;
  const filterWithPhotosUrl = window.location.hash.split("profilePic=")[1];
  if(filterWithPhotosUrl){
    initialfilterWithPhotos = 'True'
  }else{
    initialfilterWithPhotos = 'False';
  }

  const { showModal, manageModal, currentUser, pagePermissions } = React.useContext(AppContext);
  const [currentUserId, setCurrentUserId] = React.useState(0);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQueryString, setSearchQueryString] = React.useState(initialSearchKey);
  const [applicantType, setApplicantType] = React.useState(initialApplicantType);
  const [filterWithPhotos, setFilterWithPhotos] = React.useState(initialfilterWithPhotos);

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(page);

  const token = localStorage.getItem('access_token');
  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`
  };


  let paginationSettings;
  if(searchGoingOn){
    paginationSettings = {}
  }else{
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage
    }
  }

  const applicants = useQuery(['applicant', 'profile', {
    filter: {
      type: applicantType,
      search: searchQueryString,
      no_photo: filterWithPhotos
    },
    page: paginationSettings,
    include: "applicant_photo"
  }], { client });



  const deleteUserDetails = async () => {
    const { error } = await client.delete(['applicant', 'profile', currentUserId]);
    if (error === undefined) {
      applicants.refetch();
      addToast(DISPLAY_TEXTS.APPLICANT_DELETED, { appearance: 'success' });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: 'warning' });
    }
    setCurrentUserId(0);
    manageModal(false);
    removeBodyClass('modal-open');
  };

  const manageDeletedOption = id => {
    setCurrentUserId(id);
    addBodyClass('modal-open');
    manageModal(true);
  };

  const viewApplicant = (id, type) => {
    history.push({
      pathname: VW_ROUTES.APPLICANTS_APPLICANT_VIEW,
      search: `?id=${id}&type=${type}&from=all_applicants`,
    });
  };

  const doCloseModal = () => {
    setCurrentUserId(0);
    removeBodyClass('modal-open');
    manageModal(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    }
  };

  const updateApplicant = (id, type) => {
    const routeArray = {
      staff: VW_ROUTES.APPLICANTS_STAFF_EDIT,
      visitor: VW_ROUTES.APPLICANTS_VISITIOR_EDIT,
      delivery_regular: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT,
      delivery_adhoc: VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT
   };
    history.push({
      pathname: routeArray[type],
      search: `?id=${id}&from=all_applicants`,
    });
  };


  const searchByApplicantType = (e) =>{
    setApplicantType(e.target.value === "" ? undefined : e.target.value);
    if(e.target.value !== ""){
      history.push({
        search: `?page=${1}${e.target.value?'&applicant='+encodeURI(e.target.value):''}${filterWithPhotos === 'True'?'&profilePic=True':''}${searchQueryString?'&search='+encodeURI(searchQueryString):''}`
      });

    }else{
      history.push({
        search: `?page=${1}${filterWithPhotos === 'True'?'&profilePic=True':''}${searchQueryString?'&search='+encodeURI(searchQueryString):''}`
      });
    }
  }


  const searchWithProfilepic = (e) => {
    setFilterWithPhotos(e.target.checked=== true ? 'True': 'False');

    if(e.target.checked=== true){
      history.push({
        search: `?page=${1}${applicantType?'&applicant='+applicantType:''}${e.target.checked=== true?'&profilePic=True':''}${searchQueryString?'&search='+encodeURI(searchQueryString):''}`
      });

    }else{
      history.push({
        search: `?page=${1}${applicantType?'&applicant='+applicantType:''}${searchQueryString?'&search='+encodeURI(searchQueryString):''}`
      });
    }
  }

  const FilterSection = () => {
    return (<div className="advance-filter mr-1">
      {currentUser.dashboard !== "ac_dashboard" &&
      <div className="hasProfilePic">
        <input checked={filterWithPhotos === 'True' ?  true : false} onChange={(e) => searchWithProfilepic(e)} type="checkbox" /><span>Without Profile Picture</span>
      </div>
     }
      <select value={applicantType} onChange={e => searchByApplicantType(e)} className="form-control">
        <option value="">Filter By Type</option>
        <option value="visitor">Visitor</option>
        <option value="staff">Staff</option>
        <option value="delivery_adhoc">Delivery Adhoc</option>
        <option value="delivery_regular">Delivery Regular</option>
      </select>
    </div>);
  };

  // const parseIdentificationType = user => {
  //   const regex = /^NULL?/gi;
  //   let resp =  user.nricAlpha !== "" ? `${user.nricNumber}${user.nricAlpha}(NRIC)` : user.finAlpha !== "" ? `${user.finNumber}${user.finAlpha}(FIN)` : `${user.passport}(Passport)`;
  //   return resp.match(regex) === null ? resp : '-';
  // };



  const ac_dashboard_columns = [
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: true,
      cell: applicant => <span className="text-capitalize">{`${applicant.fullName}`}</span>
    },
    {
      name: 'Identification#',
      sortable: true,
      cell: applicant => <span className="text-uppercase text-left">{parseIdentificationType(applicant)}</span>
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Designation',
      selector: 'designation',
      sortable: true
    },
    {
      name: 'Applicant Type',
      cell: applicant => <span className="text-capitalize text-left">{_.replace(applicant.applicantType,'_', ' ')}</span>
    },
    {
      name: 'Status',
      cell: applicant => (
        <>
          {applicant.applicantType !== 'visitor' && applicant.applicantType !== 'delivery_adhoc' ?
            <span className="text-capitalize text-left">{applicant.status}</span>:'--'
          }
        </>

      )
    },
    {
      name: 'Actions',
      sortable: false,
      cell: applicant => <div  data-tag="allowRowEvents">
        {_.includes(pagePermissions, 'view') && <span className="simple-icon-eye user-role-action-icons view" onClick={() => viewApplicant(applicant.id, applicant.applicantType)}></span>}
        {_.includes(pagePermissions, 'edit') && editPermissionAC(applicant.status) && <span className="iconsminds-pen user-role-action-icons edit" onClick={() => updateApplicant(applicant.id, applicant.applicantType)}></span> }
        {_.includes(pagePermissions, 'delete') && <span onClick={() => manageDeletedOption(applicant.id)} className="simple-icon-trash user-role-action-icons delete"></span>}
        </div>,
      right: true
    }
  ];





  const columns = [
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: true,
      cell: applicant => <span className="text-capitalize">{`${applicant.fullName}`}</span>
    },
    {
      name: 'Identification#',
      sortable: true,
      cell: applicant => <span className="text-uppercase text-left">{parseIdentificationType(applicant)}</span>
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Designation',
      selector: 'designation',
      sortable: true
    },
    {
      name: 'Applicant Type',
      cell: applicant => <span className="text-capitalize text-left">{_.replace(applicant.applicantType,'_', ' ')}</span>
    },
    {
      name: 'Status',
      cell: applicant => (
        <>
          {applicant.applicantType !== 'visitor' && applicant.applicantType !== 'delivery_adhoc' ?
            <span className="text-capitalize text-left">{applicant.status}</span>:'--'
          }
        </>

      )

    },
    {
      name: 'Profile Pic',
      sortable: false,
      center: true,
      cell: applicant => <div className="actionContainer text-center">
        { applicant.applicantPhoto.length ?  <span className="approve-reject approve simple-icon-check m-auto"></span> : <span className="approve-reject reject simple-icon-close m-auto"></span>}
      </div>
    },
    {
      name: 'Actions',
      sortable: false,
      cell: applicant => <>
        {_.includes(pagePermissions, 'view') && <span className="simple-icon-eye user-role-action-icons view" onClick={() => viewApplicant(applicant.id, applicant.applicantType)}></span>}
        {_.includes(pagePermissions, 'edit') && editPermissionAC(applicant.status) && <span className="iconsminds-pen user-role-action-icons edit" onClick={() => updateApplicant(applicant.id, applicant.applicantType)}></span> }
        {_.includes(pagePermissions, 'delete') && <span onClick={() => manageDeletedOption(applicant.id)} className="simple-icon-trash user-role-action-icons delete"></span>}
        </>,
      right: true
    }
  ];


  const editPermissionAC = (status) => {
    if(currentUser.role_slug === 'account-coordinator'){
      if(status === "expired" || status === "pending" || status === "approved" || status === "rejected" || status === "deactivated"){
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }

//   const onChangePage = (page) => {
//     setCurrentPageNumber(page)
//   }

//   const onSearchInputChange = searchQuery => {
//     setSearchQueryString(searchQuery);
// };

// const onChangeRowsPerPage = rows => {
//   setRowsPerPage(rows);
// }

let paginationGoing = false;

const onChangePage = page => {
  setSearchGoingOn(false);
  paginationGoing = true;
  setCurrentPageNumber(page);
    if(page > 1){
      history.push({
        search: `?page=${page}${applicantType?'&applicant='+applicantType:''}${filterWithPhotos === 'True'?'&profilePic=True':''}${searchQueryString?'&search='+encodeURI(searchQueryString):''}`
      });
    }else{
      history.push({
        search: `${applicantType?'?applicant='+applicantType:''}${filterWithPhotos === 'True'?'&profilePic=True':''}${searchQueryString?'&search='+encodeURI(searchQueryString):''}`
      });
    }
}

const onChangeRowsPerPage = rows => {
    setRowsPerPage(rows);
}

let searchGoing = false;
const onSearchInputChange = (searchQuery) => {
  searchGoing = true;
  setSearchGoingOn(true);
  setSearchQueryString(searchQuery);
  setactivePage(1);
  if(searchQuery){
    history.push({
      search: `?page=${1}${applicantType?'&applicant='+applicantType:''}${filterWithPhotos === 'True'?'&profilePic=True':''}&search=${encodeURI(searchQuery)}`
    });
  }else{
    history.push({
      search: `?page=${1}${applicantType?'&applicant='+applicantType:''}${filterWithPhotos === 'True'?'&profilePic=True':''}`
    });

  }

};


window.onpopstate = (e) => {

  if(!searchGoing && !paginationGoing){
    const searchWord = window.location.hash.split("search=")[1];
    const wordString = decodeURI(searchWord);
    if (searchWord) {
      setSearchQueryString(wordString);
    }else{
      setSearchQueryString('');
    }

    const applicantType = window.location.hash.split("applicant=")[1];
    if(applicantType){
      const applicant = applicantType.split("&")[0];
      setApplicantType(applicant)
    }else{
      setApplicantType(undefined)
    }

    const filterWithPhotosUrl = window.location.hash.split("profilePic=")[1];
    if(filterWithPhotosUrl){
      setFilterWithPhotos('True')
    }else{
      setFilterWithPhotos('False')
    }
    const pageNumber = window.location.hash.split("page=")[1];
    if(pageNumber){
      const pageFromUrl = pageNumber.split("&")[0];
      setCurrentPageNumber(Number(pageFromUrl))
    }else{
      setCurrentPageNumber(1)
    }

  }else{
    searchGoing = false;
    paginationGoing = false
  }
};


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>All Applicants</h1>
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <DataTable
              subHeader={true}
              subHeaderComponent={<><FilterSection/> <SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/></>}
                progressPending={applicants.isLoading}
                progressComponent={<Loader/>}
                columns={currentUser.dashboard === "ac_dashboard" ? ac_dashboard_columns : columns}
                data={applicants.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={applicants.meta && applicants.meta.pagination.count}
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>


      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={<p>Are you sure you want to delete the selected Applicant details ?</p>}
        onSubmitCallback={deleteUserDetails}
        onCloseCallback={doCloseModal}
      />

    </>
  );
};

export default Applicants;
