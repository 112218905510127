import React from "react";
import branding from "../../assets/images/branding-w.png";

const Footer = () => (
  <footer className="page-footer">
    <div className="footer-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-6 m-auto">
            <a
              href="https://ssgroupz.com/vwatchpro/"
              target="_blank"
              className="btn-link"
              rel="noopener noreferrer"
            >
              <img src={branding} alt="v-watch" className="footer-logo" />
            </a>
          </div>
          <div className="col-sm-6 d-none d-sm-block">
            <ul className="breadcrumb pt-0 pr-0 float-right">
              <li className="breadcrumb-item mb-0">
                {/* eslint-disable-next-line */}
                Powered by{" "}
                <a
                  href="https://ssgroupz.com/vwatchpro/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-link"
                >
                  © Copyright 2024 SS Group International Sdn Bhd. All Rights
                  Reserved.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
