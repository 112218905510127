import React from "react";

const ChangePassword = ({ passData, onInputChange, errors }) => {
    return (
        <div className="profile-password-update-wrapper">
            <div className="form-row">
                <div className={`form-group position-relative error-l-75 col-md-12 ${errors.new_password1 ? 'hasValidationError' : ''}`}>
                    <label htmlFor="firstName">Password<i>*</i></label>
                    <input type="password" className="form-control" name="new_password1" value={passData.new_password1} onChange={(e) => onInputChange(e.target)}
                        id="new_password1" placeholder="Password" />
                    <p className="error-message">{errors && errors.new_password1 ? errors.new_password1 : ''}</p>
                </div>
            </div>

            <div className="form-row">
                <div className={`form-group position-relative error-l-75 col-md-12 ${errors.new_password2 ? 'hasValidationError' : ''}`}>
                    <label htmlFor="firstName">Confirm Password<i>*</i></label>
                    <input type="password" className="form-control" name="new_password2" value={passData.new_password2} onChange={(e) => onInputChange(e.target)}
                        id="new_password2" placeholder="Confirm Password" />
                    <p className="error-message">{errors && errors.new_password2 ? errors.new_password2 : ''}</p>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
