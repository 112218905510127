import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation, useClient } from 'jsonapi-react'
import { VW_ROUTES } from '../../constants';
import { setSession, parseJwt } from '../../helpers/utils';
import { parseAPIResponseError } from "../../helpers/helpers";


export const Login = () => {
    const initialState = {
        email: '',
        password: ''
    };
    const history = useHistory();
    const [formState, updateFormState] = React.useState(initialState);
    const [error, updateErrorMessage] = React.useState({});
    const client = useClient();
    client.config.headers = {};

    const [logInUser] = useMutation(['auth', 'login'], { client });
    const onInputChangeEvent = (e) => {
        updateFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const doLoginUser = async event => {
        event.preventDefault();
        // console.log(getAPIFormDataFormat('user_auth',formState))
        const { error, data } = await logInUser(formState);
        if (error) {
            updateErrorMessage(parseAPIResponseError(error));
        }

        if (data !== undefined) {

            const resp = parseJwt(data.token);
            setSession(data);
            if (resp.dashboard === "administrator") {
                history.push(VW_ROUTES.USER_MANAGEMENT);
            }else if (resp.dashboard === "ac_dashboard") {
                history.push(VW_ROUTES.APPLICANTS_HISTORY);
            } else {
                history.push(VW_ROUTES.DASHBOARD);
            }

        }
    };
    return (
        <>
            <div className="fixed-background login-background"></div>
            <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">
                                    <p className="text-white h2">Welcome to TWRP Registration Portal</p>


                                </div>
                                <div className="form-side">
                                    <a href="Dashboard.Default.html">
                                        <div className="logo-container">
                                            <span className="logo-single"></span>
                                            {/* <span className="jacobs-logo"></span> */}
                                        </div>


                                    </a>
                                    <p className="nonFieldError">{error && error.field === 'nonFieldErrors' ? error.errorMsg : ''}</p>
                                    <h6 className="mb-4">Login</h6>
                                    <form onSubmit={doLoginUser}>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="email" type="text" className="form-control" />
                                            <span>E-mail</span>
                                            <p className="login-error-message">{error && error.field === 'email' ? error.errorMsg : ''}</p>
                                        </label>

                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="password" className="form-control" type="password" placeholder="" />
                                            <span>Password</span>
                                            <p className="login-error-message">{error && error.field === 'password' ? error.errorMsg : ''}</p>
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a href={`#${VW_ROUTES.FORGOT_PASSWORD}`}>Forgot password?</a>
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit">LOGIN</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Login;
