import React from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import Loader from "../Loader/Loader";

const ExportButton = ({ exportDetails }) => {
  const token = localStorage.getItem("access_token");
  const [apiUrlSpec, setApiUrlSpec] = React.useState("");
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showLoader, setshowLoader] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  React.useEffect(() => {
    if (exportDetails.page === "expiry") {
      let filter;
      if(exportDetails.filters.search !== ''){
        filter = `filter[type]=${exportDetails.params.type}&include=company&filter[search]=${exportDetails.filters?.search}`;
      }else{
        filter = `filter[type]=${exportDetails.params.type}&include=company`;
      }
      if (exportDetails.params.list === "workpermit") {
        let apiSpecs = `${API_BASE_URL}/applicant/profile/export_wp_expiry_list?&${filter}`;
        setApiUrlSpec(apiSpecs);
      } else if (exportDetails.params.list === "training") {
        let apiSpecs = `${API_BASE_URL}/applicant/profile/export_training_expiry_list?&${filter}`;
        setApiUrlSpec(apiSpecs);
      } else if (exportDetails.params.list === "expired") {
        let apiSpecs = `${API_BASE_URL}/applicant/profile/export_wp_expired_list?&${filter}`;
        setApiUrlSpec(apiSpecs);
      }

    } else if (exportDetails.page === "pending_details") {

      let filter;
      if(exportDetails.filters.search !== ''){
        filter = `filter[type]=${exportDetails.params.type}&filter[search]=${exportDetails.filters?.search}`;
      }else{
        filter = `filter[type]=${exportDetails.params.type}`;
      }
      let apiSpecs = `${API_BASE_URL}/applicant/profile/export_pending_list?&${filter}`;
      setApiUrlSpec(apiSpecs);

    } else if (exportDetails.page === "over_night_stay") {
      let filter;
      if(exportDetails.filters.search !== '' || exportDetails.filters.type !== ''){
        if(exportDetails.filters.type){
          filter = `filter[over_night_stay]=True&filter[search]=${exportDetails.filters.search}&filter[type]=${exportDetails.filters.type}`;
        }else{
          filter = `filter[over_night_stay]=True&filter[search]=${exportDetails.filters.search}`;
        }
      }
      else{
        filter = `filter[over_night_stay]=True`;
      }

      let apiSpecs = `${API_BASE_URL}/applicant/profile/export_over_stay_list?${filter}`;
      setApiUrlSpec(apiSpecs);
    } else if (exportDetails.page === "live_count_by_contract") {
      let filter;
      if(exportDetails.filters.search !== '' || exportDetails.filters.type !== ''){
        if(exportDetails.filters.type){
          filter = `filter[contractor]=${exportDetails.contractorId}&filter[search]=${exportDetails.filters?.search}&filter[type]=${exportDetails.filters.type}`;
        }
        else{
          filter = `filter[contractor]=${exportDetails.contractorId}&filter[search]=${exportDetails.filters?.search}`;
        }
      }
      else{
        filter = `filter[contractor]=${exportDetails.contractorId}`;
      }
      let apiSpecs = `${API_BASE_URL}/applicant/profile/export_live_count_by_contract?${filter}`;
      setApiUrlSpec(apiSpecs);
    }
  }, [exportDetails]);

  const onExport = () => {
    setshowLoader(true);

    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      url: apiUrlSpec,
    };
    axios(options)
      .then((response) => {
        setshowLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(response.data.data.message);
      })
      .catch((error) => {
        setshowLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(error.response.data.errors.message);
      });
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  return (
    <>
      <button className="export-btn" onClick={() => onExport()}>
        Export
      </button>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-primary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>

      {showLoader && (
        <div className="exportLoader">
          <Loader />
        </div>
      )}
    </>
  );
};

export default ExportButton;
