import React from "react";
import ReactTooltip from 'react-tooltip';

import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";

const DashboardWorkPermitCounterComponent = ({dashboardStats}) => {
	const history = useHistory();
    const navidateToPendingLists = (type) => {
        history.push({
            pathname: VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING,
            search: `?type=${type}&list=workpermit`
        });
    };

	return (
        <div className="col-lg-4 col-md-12 mb-4">
            <h5 className="mb-4">Applicant Work Permit Expiry Status</h5>
            <div className="row pending-approvals">


                <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('staff')}>
                            <i className="iconsminds-male"></i>
                            <p className="card-text mb-0">Staff</p>
                            <p data-tip="Work Permits expiring within one month" className="lead text-center">{dashboardStats && dashboardStats.applicantWorkPermitExpiryStats.staff}</p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('visitor')}>
                            <i className="iconsminds-business-mens"></i>
                            <p className="card-text mb-0">Visitor</p>
                            <p className="lead text-center">{dashboardStats && dashboardStats.applicantWorkPermitExpiryStats.visitor}</p>
                        </div>
                    </div>
                </div> */}
                <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('delivery_regular')}>
                            <i className="iconsminds-box-close"></i>
                            <p className="card-text mb-0">Delivery Regular</p>
                            <p data-tip="Work Permits expiring within one month" className="lead text-center">{dashboardStats && dashboardStats.applicantWorkPermitExpiryStats.deliveryRegular}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('delivery_adhoc')}>
                            <i className="iconsminds-box-with-folders"></i>
                            <p className="card-text mb-0">Delivery Ad-hoc</p>
                            <p className="lead text-center">{dashboardStats && dashboardStats.applicantWorkPermitExpiryStats.deliverYAdhoc}</p>
                        </div>
                    </div>
                </div> */}
            </div>
            <ReactTooltip />
        </div>
    		// <div className="col-sm-6 p-0">
    		// 	<div className="col-md-12"><h5 className="dashboard-heading">Applicant Work Permit Expiry Status</h5></div>

        	// 	<div className="col-6 mb-3 float-left">
            //         <div className="card">
            //             <div className="card-body text-center" onClick={() => navidateToPendingLists('staff')}>
            //                 <i className="iconsminds-male"></i>
            //                 <p className="card-text mb-0">Staff</p>
            //                 <p className="lead text-center">{dashboardStats && dashboardStats.applicantWorkPermitExpiryStats.staff}</p>
            //             </div>
            //         </div>
            //     </div>
			// 	<div className="col-6 mb-3 float-left">
            //         <div className="card">
            //             <div className="card-body text-center" onClick={() => navidateToPendingLists('delivery')}>
            //                 <i className="iconsminds-box-close"></i>
            //                 <p className="card-text mb-0">Delivery Regular</p>
            //                 <p className="lead text-center">{dashboardStats && dashboardStats.applicantWorkPermitExpiryStats.deliveryRegular}</p>
            //             </div>
            //         </div>
            //     </div>
    		// </div>
    		);
};
export default DashboardWorkPermitCounterComponent;

