import React from "react";

const RejectApplication = ({updateInputChange, validation}) => {
  const onInputChange =(val)=>{
   if(val.trim().length > 0){
     updateInputChange(val)
   }else{
     updateInputChange('')
   }
  }

   return (
       <div className="form-row">
           <div className="form-group position-relative error-l-75 col-md-12">
               <label htmlFor="mobileNumber">Reject Note<i>*</i></label>
               <input type="text" className="form-control" name="mobileNumber"
                   id="mobileNumber" placeholder="Reject Note" onChange={(e) => onInputChange(e.target.value)} />
               <p className="error-message">{validation && validation.msg ? validation.msg : ''}</p>
           </div>
       </div>
   );
};

export default RejectApplication;
