import React from "react";
import { DashboardReducer, ACTION_TYPES } from "../reducers/DashboardReducer";

const initialState = {
  sumUpCounters: [],
  splitUpCounters:[],
  contractorCarouselLists:[]
};

export const DashboardContext = React.createContext();
export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(DashboardReducer, initialState);
  const updateSumUpCounters = (counters) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SUMUP_COUNTERS,
      payload: counters
    });
  };

  const updateSplitUpCounters = (counters) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SPLITUP_COUNTERS,
      payload: counters
    });
  };

  const updateContractorCarouselLists = (data) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_CONTRACTOR_CAROUSEL_LISTS,
      payload: data
    });
  };

  return (
    <DashboardContext.Provider
      value={{
       sumUpCounters: state.sumUpCounters,
       splitUpCounters: state.splitUpCounters,
       contractorCarouselLists: state.contractorCarouselLists,
       updateSumUpCounters,
       updateSplitUpCounters,
       updateContractorCarouselLists
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};