import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { validatorModule } from "../../../helpers/helpers";


const PersonalComponent = ({ applicantType, isEdit, onStepComplete, nextStep, manageStepper, nationality, applicantProfile, acRestrictedParam, currentStep }) => {

  const firstInputPersonal = React.useRef();

    const validationFormat = [
        {
            field: "nationality",
            isRequired: true,
            fieldName: "Nationality"
        },
        {
            field: "dateOfBirth",
            isBirthDay: true,
            fieldName: "Date Of Birth"
        },
        {
            field: "email",
            isEmail: true,
            isRequired: true,
            fieldName: "Email Address"
        },
        {
            field: "mobileNumber",
            isPhoneNumber: true,
            isRequired: false,
            fieldName: "Mobile Number"
        },
        {
            field: "passport",
            fieldName: "Passport Number",
            isLength: 4,
            requiredIf: {
                field: 'identification_type',
                value: 'passport'
            }
        },
        {
            field: "finNumber",
            isNumber: true,
            isLength: 3,
            fieldName: "FIN Number",
            requiredIf: {
                field: 'identification_type',
                value: 'fin'
            }
        },
        {
            field: "finAlpha",
            isLength: 1,
            isAlpha: true,
            fieldName: "FIN",
            requiredIf: {
                field: 'identification_type',
                value: 'fin'
            }
        },
        {
            field: "nricAlpha",
            isLength: 1,
            isAlpha: true,
            fieldName: "NRIC",
            requiredIf: {
                field: 'identification_type',
                value: 'nric'
            }
        },
        {
            field: "nricNumber",
            isNumber: true,
            isLength: 3,
            fieldName: "NRIC Number",
            requiredIf: {
                field: 'identification_type',
                value: 'nric'
            }
        },
        {
            field: "fullName",
            minLength: 3,
            fieldName: "Full Name",
            isRequired: true
        }

    ];
    const initialState = {
        applicantType: applicantType,
        identification_type: 'nric', //nric, fin, passport
        fullName: '',
        //lastName: '',
        nricNumber: null,
        nricAlpha: '',
        finNumber: null,
        finAlpha: '',
        passport: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: '',
        nationality: '',
        gender: 'male'
    };

    const initialErrorState = {
        fullName: '',
        nricNumber: '',
        nricAlpha: '',
        finNumber: '',
        finAlpha: '',
        passport: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: '',
        nationality: '',
        gender: ''
    };

    const [formState, setFormState] = React.useState(initialState);
    const [errorState, setErrorState] = React.useState(initialErrorState);
    const onInputChange = ({ name, value }) => {
        let formData = { ...formState };
        formData[name] = value;
        if (name === "identification_type") {
            formData['nricNumber'] = null;
            formData['nricAlpha'] = "";
            formData['finNumber'] = null;
            formData['finAlpha'] = "";
            formData['passport'] = "";
        }
        setFormState(formData);

        // if(name === "dateOfBirth"){
        //   dateField.current.focus();
        // }
    };

    React.useEffect(() => {
        if (isEdit && applicantProfile) {
            const identification_type = (applicantProfile.nricAlpha !== null && applicantProfile.nricAlpha !== "") ? 'nric' : (applicantProfile.finAlpha !== null && applicantProfile.finAlpha !== "") ? 'fin' : 'passport';
            const updatedState = {
                applicantType,
                identification_type,
                fullName: applicantProfile.fullName,
                //lastName: applicantProfile.lastName,
                nricNumber: applicantProfile.nricNumber,
                nricAlpha: applicantProfile.nricAlpha,
                finNumber: applicantProfile.finNumber,
                finAlpha: applicantProfile.finAlpha,
                passport: applicantProfile.passport,
                mobileNumber: applicantProfile.mobileNumber,
                email: applicantProfile.email,
                dateOfBirth: new Date(moment(applicantProfile.dateOfBirth, 'DD/MM/YYYY')),
                nationality: applicantProfile.nationality,
                gender: applicantProfile.gender
            };

            setFormState(updatedState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, applicantProfile]);


    React.useEffect(() => {
      if(currentStep === 1){
        setTimeout(()=>{
          if(firstInputPersonal){
            firstInputPersonal.current.focus();
          }
        },1000)
      }
    },[currentStep]);



    const errorValidate = () => {
        let errors = validatorModule(formState, validationFormat);
        let extraFields = [];
        if (formState.identification_type === 'nric') {
            extraFields.push('finNumber', 'finAlpha', 'passport');
        } else if (formState.identification_type === 'fin') {
            extraFields.push('nricNumber', 'nricAlpha', 'passport');
        } else if (formState.identification_type === 'passport') {
            extraFields.push('nricNumber', 'nricAlpha', 'finNumber', 'finAlpha');
        }
        return _.omit(errors, extraFields);
    };

    const onFocusChange = () => {
        if (_.filter(Object.values(errorState)).length !== 0) {
            let errors = validatorModule(formState, validationFormat);
            setErrorState(errors);
        }
    };

    const onNextClick = () => {
        // let errors = validatorModule(formState, validationFormat);
        // let extraFields = [];
        // if (formState.identification_type === 'nric') {
        //     extraFields.push('finNumber', 'finAlpha', 'passport');
        // } else if (formState.identification_type === 'fin') {
        //     extraFields.push('nricNumber', 'nricAlpha', 'passport');
        // } else if (formState.identification_type === 'passport') {
        //     extraFields.push('nricNumber', 'nricAlpha', 'finNumber', 'finAlpha');
        // }
        // errors = _.omit(errors, extraFields);
        let errors = errorValidate();
        setErrorState(errors);
        if (Object.keys(errors).length === 0) {
            onStepComplete(formState);
            nextStep();
            manageStepper('next');
        }
    };
    return (
        <div id="step-1" className="step700">
            <form id="form-step-1" className="tooltip-label-right">
                <span>Do you have ? &nbsp;</span>
                <div className="form-check-inline">
                    <label className="form-check-label">
                        <input disabled = {acRestrictedParam}  type="radio" className="form-check-input" value="nric" onChange={(e) => onInputChange(e.target)} checked={formState.identification_type === 'nric' ? true : false} name="identification_type" />NRIC Number
          </label>
                </div>
                <div className="form-check-inline">
                    <label className="form-check-label">
                        <input disabled = {acRestrictedParam}  type="radio" className="form-check-input" value="fin" onChange={(e) => onInputChange(e.target)} checked={formState.identification_type === 'fin' ? true : false} name="identification_type" />FIN Number
          </label>
                </div>
                <div className="form-check-inline">
                    <label className="form-check-label">
                        <input disabled = {acRestrictedParam}  type="radio" className="form-check-input" value="passport" onChange={(e) => onInputChange(e.target)} checked={formState.identification_type === 'passport' ? true : false} name="identification_type" />Passport
          </label>
                </div>
                <p></p>

                <div className="form-row">
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.fullName ? 'hasValidationError' : ''}`}>
                        <label htmlFor="firstName">Full Name<i>*</i></label>
                        <input type="text" ref={firstInputPersonal} className="form-control" name="fullName" value={formState.fullName} onBlur={() => onFocusChange()} onChange={(e) => onInputChange(e.target)}
                            id="fullName" readOnly = {acRestrictedParam} placeholder="Full Name" />
                        <p className="error-message">{errorState && errorState.fullName ? errorState.fullName : ''}</p>
                    </div>
                    {formState.identification_type === "passport" &&
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.passport ? 'hasValidationError' : ''}`}>
                        <label htmlFor="passport">Passport Number(Last 4 characters in Passport Number)*</label>
                        <input type="text" maxLength="4" readOnly = {acRestrictedParam} className="form-control  text-uppercase" name="passport" value={formState.passport}
                            onBlur={() => onFocusChange()} id="passport" placeholder="Passport Number" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.passport ? 'Last 4 characters in Passport Number' : ''}</p>
                    </div>
                }
                    {/* <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="lastname">Last Name</label>
                        <input type="text" className="form-control" name="lastName" value={formState.lastName} onChange={(e) => onInputChange(e.target)}
                            id="lastname" placeholder="Last Name" />
                    </div> */}
                </div>


                {formState.identification_type === "nric" &&
                    <div className="form-row">
                        <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.nricNumber ? 'hasValidationError' : ''}`}>
                            <label htmlFor="nricNumber">NRIC Number(Last 3 numeric in NRIC Number)*</label>
                            <input type="text" maxLength="3" className="form-control" name="nricNumber" readOnly = {acRestrictedParam}
                                id="nricNumber" placeholder="NRIC Number ( sample - 234 )" value={formState.nricNumber || ''} onBlur={() => onFocusChange()} onChange={(e) => onInputChange(e.target)} />
                            <p className="error-message">{}</p>
                            <p className="error-message">{errorState && errorState.nricNumber ? 'Last 3 numeric in NRIC Number' : ''}</p>
                        </div>
                        <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.nricAlpha ? 'hasValidationError' : ''}`}>
                            <label htmlFor="nricAlpha">NRIC (Sample (A))*</label>
                            <input type="text" maxLength="1" className="form-control  text-uppercase" name="nricAlpha" readOnly = {acRestrictedParam} value={formState.nricAlpha}
                                onBlur={() => onFocusChange()} id="nricAlpha" placeholder="NRIC (Only alpha)" onChange={(e) => onInputChange(e.target)} />
                            <p className="error-message">{errorState && errorState.nricAlpha ? 'Last alphabet in NRIC Number' : ''}</p>
                        </div>
                    </div>
                }

                {formState.identification_type === "fin" &&
                    <div className="form-row">
                        <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.finNumber ? 'hasValidationError' : ''}`}>
                            <label htmlFor="finNumber">FIN Number(Last 3 numeric in FIN Number)*</label>
                            <input type="text" maxLength="3" readOnly = {acRestrictedParam} className="form-control" name="finNumber" value={formState.finNumber}
                                onBlur={() => onFocusChange()} id="finNumber" placeholder="FIN Number ( sample - 234 )" onChange={(e) => onInputChange(e.target)} />
                            <p className="error-message">{errorState && errorState.finNumber ? 'Last 3 numeric in FIN Number' : ''}</p>
                        </div>
                        <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.finAlpha ? 'hasValidationError' : ''}`}>
                            <label htmlFor="finAlpha">FIN (Sample (A))*</label>
                            <input type="text" maxLength="1" readOnly = {acRestrictedParam} className="form-control text-uppercase" name="finAlpha" value={formState.finAlpha}
                                onBlur={() => onFocusChange()} id="finAlpha" placeholder="FIN (Only alpha)" onChange={(e) => onInputChange(e.target)} />
                            <p className="error-message">{errorState && errorState.finAlpha ? 'Last alphabet in FIN Number' : ''}</p>
                        </div>
                    </div>
                }

                {/* {formState.identification_type === "passport" &&
                    <div className={`form-group position-relative error-l-75 ${errorState.passport ? 'hasValidationError' : ''}`}>
                        <label htmlFor="passport">Passport Number*</label>
                        <input type="text" readOnly = {acRestrictedParam} className="form-control  text-uppercase" name="passport" value={formState.passport}
                            onBlur={() => onFocusChange()} id="passport" placeholder="Passport Number" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.passport ? errorState.passport : ''}</p>
                    </div>
                } */}


                <div className="form-row">
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.mobileNumber ? 'hasValidationError' : ''}`}>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <input type="text" className="form-control" name="mobileNumber" value={formState.mobileNumber}
                            onBlur={() => onFocusChange()} id="mobileNumber" placeholder="Mobile Number" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.mobileNumber ? errorState.mobileNumber : ''}</p>
                    </div>
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.email ? 'hasValidationError' : ''}`}>
                        <label htmlFor="email">Email address<i>*</i></label>
                        <input type="email" className="form-control" name="email" value={formState.email}
                            onBlur={() => onFocusChange()} id="email" placeholder="Your email address" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.email ? errorState.email : ''}</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className={`form-group position-relative mb-1 col-md-6 ${errorState.dateOfBirth ? 'hasValidationError' : ''}`}>
                        <label>Date Of Birth(You must be atleast 16 years)<i>*</i></label>
                        <div className="input-group date">
                            <DatePicker
                                readOnly = {acRestrictedParam}
                                onBlur={() => onFocusChange()}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                maxDate={moment().subtract(16, 'y').toDate()}
                                popperPlacement="top-end"
                                popperClassName="some-custom-class"
                                selected={formState.dateOfBirth}
                                onChange={date => onInputChange({ name: 'dateOfBirth', value: date })}
                                showMonthDropdown
                                useShortMonthInDropdown
                                showYearDropdown
                            />
                            {/* <input className="dateFocusCrack" ref={dateField} /> */}
                        </div>
                        <p className="error-message">{errorState && errorState.dateOfBirth ? errorState.dateOfBirth : ''}</p>
                    </div>

                    <div className={`form-group position-relative error-l-100 col-md-6 ${errorState.nationality ? 'hasValidationError' : ''}`}>
                        <label htmlFor="nationality">Nationality<i>*</i></label>
                        <select disabled= {acRestrictedParam} onBlur={() => onFocusChange()} value={formState.nationality} onChange={e => onInputChange(e.target)} name="nationality" className="form-control select2-single select2-hidden-accessible" data-width="100%" aria-hidden="true">
                            <option value="" label="&nbsp;">Nationality</option>
                            {
                                _.map(nationality, nation => <option key={_.uniqueId('nation-')} value={nation.value}>{nation.display_name}</option>)
                            }
                        </select>
                        <p className="error-message">{errorState && errorState.nationality ? errorState.nationality : ''}</p>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group mb-1  col-md-6">
                        <span>Gender ?<i>*</i>&nbsp;</span>
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input disabled= {acRestrictedParam} type="radio" value="male" className="form-check-input" onChange={(e) => onInputChange(e.target)} name="gender" checked={formState.gender === 'male' ? true : false} />Male
          </label>
                        </div>
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input disabled= {acRestrictedParam} type="radio" value="female" className="form-check-input" onChange={(e) => onInputChange(e.target)} name="gender" checked={formState.gender === 'female' ? true : false} />Female
          </label>
                        </div>
                    </div>

                </div>

            </form>
            <div className="btn-toolbar custom-btn-toolbar text-center">
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onNextClick()}>Next</button>
            </div>
        </div>)
};

export default PersonalComponent;
