import _ from "lodash";
import { VW_ROUTES } from "../constants";

export const ACTION_TYPES = {
    UPDATE_USER: 'updateUser',
    HIDE_MAIN_SIDEBAR: 'hideMainSidebar',
    HIDE_SUB_SIDEBAR: 'hideSubSidebar',
    UPDATE_NOTIFICATIONS: 'updateNotifications',
    MANAGE_MODAL_BACKDROP: 'manageModalBackDrop',
    SHOW_RIGHT_MODAL: 'showRightModal',
    SHOW_MODAL: 'showModal',
    UPDATE_FILE_ALARM_COUNTER: 'updateFileAlarmCounter',
    UPDATE_USER_PERMISSIONS: 'updateUserPermissions',
    UPDATE_PAGE_PERMISSIONS: 'updatePagePermissions',
    MANAGE_LOADER: 'manageLoader',
    UPDATE_INITIAL_PAGE_PARAMS : 'updateInitialPageParams'
};

export const AppReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        case ACTION_TYPES.HIDE_MAIN_SIDEBAR:
            return {
                ...state,
                mainSidebarHidden: action.payload
            }
        case ACTION_TYPES.HIDE_SUB_SIDEBAR:
            return {
                ...state,
                subSidebarHidden: action.payload
            }
        case ACTION_TYPES.UPDATE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }

        case ACTION_TYPES.SHOW_RIGHT_MODAL:
            return {
                ...state,
                showRightModal: action.payload,
                showModalBackDrop: action.payload
            }
        case ACTION_TYPES.SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload,
                showModalBackDrop: action.payload
            }
        case ACTION_TYPES.UPDATE_FILE_ALARM_COUNTER:
            return {
                ...state,
                fireAlarmCounter: action.payload
            }
        case ACTION_TYPES.UPDATE_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.payload
            }
        case ACTION_TYPES.UPDATE_PAGE_PERMISSIONS:
            const { permissions, pathname } = action.payload;
            const pagePermissions = _.keyBy(permissions, 'route')[_.invert(VW_ROUTES)[pathname]] && _.keyBy(permissions, 'route')[_.invert(VW_ROUTES)[pathname]].actions;
            return {
                ...state,
                pagePermissions
            }

        case ACTION_TYPES.MANAGE_LOADER:
            return {
                ...state,
                showLoader: action.payload
            }

        case ACTION_TYPES.UPDATE_INITIAL_PAGE_PARAMS:
          return {
            ...state,
            initialPageParams: action.payload
        }

        default:
            return state
    }
}

