import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import _ from "lodash";

const EditGatePass = ({
  applicantType,
  passData,
  onInputChange,
  errors,
  contracts,
}) => {
  let sanitized_dt =
    passData.fromDateTime === undefined
      ? new Date()
      : passData.fromDateTime.split("+")[0];
  var toLocalFromDateTime = sanitized_dt
    ? moment(sanitized_dt).toDate()
    : moment().toDate();

  return (
    <div className="profile-details-wrapper">
      {applicantType === "delivery_adhoc" && (
        <div className="row">
          <div className="col-md-6">
            <div className="profile-container">
              <div className="profile-label mt-2"> PO Number </div>
              <input
                type="text"
                className="form-control"
                name="poNumber"
                value={passData.poNumber}
                id="poNumber"
                placeholder="PO Number"
                onChange={(e) => onInputChange(e.target)}
              />
            </div>
          </div>
          <div className="col-md-6 pl-0">
            <div className="profile-container">
              <div className="profile-label mt-2"> DO Number </div>
              <input
                type="text"
                className="form-control"
                name="doNumber"
                value={passData.doNumber}
                id="doNumber"
                placeholder="DO Number"
                onChange={(e) => onInputChange(e.target)}
              />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6">
          <div
            className={`profile-container ${
              _.includes(errors, "purposeOfVisit") ? "hasValidationError" : ""
            }`}
          >
            <div className="profile-label mt-2">
              {" "}
              Purpose Of Visit<i>*</i>
            </div>
            <input
              type="text"
              className="form-control"
              name="purposeOfVisit"
              value={passData.purposeOfVisit}
              id="purposeOfVisit"
              placeholder="Purpose of Visit"
              onChange={(e) => onInputChange(e.target)}
            />
          </div>
        </div>
        <div className="col-md-6 pl-0">
          <div
            className={`profile-container ${
              _.includes(errors, "hostName") ? "hasValidationError" : ""
            }`}
          >
            <div className="profile-label mt-2">
              {" "}
              Host Name<i>*</i>
            </div>
            <input
              type="text"
              className="form-control"
              name="hostName"
              value={passData.hostName}
              id="hostName"
              placeholder="Host Name"
              onChange={(e) => onInputChange(e.target)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div
            className={`profile-container dov ${
              _.includes(errors, "fromDateTime") ? "hasValidationError" : ""
            }`}
          >
            <div className="profile-label mt-2">
              Date Of Visit<i>*</i>
            </div>
            <DatePicker
              minDate={moment().toDate()}
              selected={toLocalFromDateTime}
              onChange={(date) =>
                onInputChange({ name: "fromDateTime", value: date })
              }
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="col-md-6 pl-0">
          <div
            className={`profile-container ${
              _.includes(errors, "vehicleNumber") ? "hasValidationError" : ""
            }`}
          >
            <div className="profile-label mt-2">
              Vehicle Number{applicantType === "delivery_adhoc" && <i>*</i>}
            </div>
            <input
              type="text"
              className="form-control"
              name="vehicleNumber"
              value={passData.vehicleNumber}
              id="vehicleNumber"
              placeholder="Vehicle Number"
              onChange={(e) => onInputChange(e.target)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div
            className={`profile-container ${
              _.includes(errors, "contract") ? "hasValidationError" : ""
            }`}
          >
            <div className="profile-label mt-2">
              {" "}
              Contracts<i>*</i>
            </div>
            <select
              value={passData.contract}
              onChange={(e) => onInputChange(e.target)}
              name="contract"
              className="form-control select2-single select2-hidden-accessible"
              data-width="100%"
              aria-hidden="true"
            >
              <option value="">Select</option>
              {_.map(contracts, (contract) => (
                <option key={_.uniqueId("contract-")} value={contract.id}>
                  {contract.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGatePass;
