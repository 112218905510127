import React from "react";

const SearchBar = ({ onSearchInputChange, popstateText }) => {
  const [searchText, setSearchText] = React.useState("");
  const onSubmitSearch = (e) => {
    e.preventDefault();
    onSearchInputChange(searchText)
  };



  React.useEffect(() => {
    if(popstateText){
      setSearchText(popstateText)
    }else{
      setSearchText('')
    }
  }, [popstateText]);

  const onResetSearch = () => {
    setSearchText("");
    onSearchInputChange("");
  };

  const onSearchInput = (val) => {
    if (val) {
      setSearchText(val);
    } else {
      onResetSearch();
    }
  };

  return (
    <form className="search-container" onSubmit={(e) => onSubmitSearch(e)}>
      <input
        type="text"
        className="form-control rounded-0 rounded-left"
        name="search"
        value={searchText}
        id="search"
        placeholder="Search"
        onChange={(e) => onSearchInput(e.target.value)}
      />
      <button type="submit" className="">
        <span className="simple-icon-magnifier"></span>
      </button>
      <button type="button" className="reset" onClick={(e) => onResetSearch()}>
        Reset
      </button>
    </form>
  );
};

export default SearchBar;
