/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import _ from "lodash";
import moment from "moment";
import { API_BASE_URL,SET_PAGE_COUNT } from "../../constants";
import Loader from "../../components/common/Loader/Loader";
import AcsReportFilter from "../../components/common/ReportFilter/AcsReportFilter";

const AcsTransactionReport = () => {
  const [selectedContractor, setSelectedContractor] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [resetPagination, setResetPagination] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalEntries, setTotalEntries] = React.useState(0);
  const [markerId,setMarkerId] = React.useState('');
  const [pageAction, setPageAction] = React.useState('first');
  const [searchQueryString, setSearchQueryString] = React.useState('');
  const [eventTimeFrom, setEventTimeFrom] = React.useState(moment().subtract(1, 'M').toDate());
  const [eventTimeTo, setEventTimeTo] = React.useState(moment().toDate());
  const [applicantType, setApplicantType] = React.useState('');
  const [acsReport, setAcsReport] = React.useState([]);
  const token = localStorage.getItem('access_token');
  const client = useClient();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState('');
  const [disableExport, setdisableExport] = React.useState(true);
  client.config.headers = {
    Authorization: `JWT ${token}`
  };
  const { data: contracts } = useQuery(['organisation', 'contract', {
    page: {
      number: 1,
      size: SET_PAGE_COUNT
    }
  }], { client });

  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'Authorization': `JWT ${token}`
      },
      url: `${API_BASE_URL}/${url}`,
    };
  };

  const onResetInitiated = () => {
    setPageAction("first");
    setMarkerId("");
    setCurrentPageNumber(1);
    setResetPagination(true);
  };

  React.useEffect(() => {
    if(acsReport?.length > 0){
      setdisableExport(false)
    }else{
      setdisableExport(true)
    }
  },[acsReport])

  React.useEffect(() => {
    setIsLoading(true);
    setResetPagination(false);
    let filterItems = {
      'name' : searchQueryString,
      'contract': contracts && contracts && _.groupBy(contracts, "id")[selectedContractor] ? contracts && _.groupBy(contracts, "id")[selectedContractor][0].name : "",
      'applicant_type': applicantType,
      'event_time_from': eventTimeFrom === "" ? "" : moment(eventTimeFrom).format("YYYY-MM-DD"),
      'event_time_to': eventTimeTo === "" ? "" : moment(eventTimeTo).format("YYYY-MM-DD")
    };
    let filter = "";
    for (const [, [key, value]] of Object.entries(Object.entries(filterItems))) {
      if(value !== ""){
        filter += `&filter['${key}']=${value}`;
      }
    }
    if(pageAction !== "first" && pageAction !== "last"){
      filter += `&marker_id=${markerId}`;
    }
    axios(axiosOptions('GET',`reports/acs-transaction?page_action=${pageAction}&page_size=${rowsPerPage}${filter}`)).then(response => {
      setIsLoading(false);
      const {
        data: {
          data: acsTransactionReportData,
          meta: {
            pagination: {
              count: totalEntries
            }
          }
        }
      } = response;
      setAcsReport(acsTransactionReportData);
      setTotalEntries(totalEntries);
    });

  }, [applicantType, searchQueryString, selectedContractor, eventTimeFrom, eventTimeTo, currentPageNumber, rowsPerPage, pageAction]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    }
  };

  const columns = [
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: false,
      cell: applicant => <span className="text-capitalize">{`${applicant.applicant_data ? applicant.applicant_data.full_name : '-'}`}</span>
    },
    {
      name: 'Identification#',
      sortable: false,
      cell: applicant => <span className="text-uppercase text-left">{`${applicant.applicant_data ? applicant.applicant_data.identification_number : '-'}`}</span>
    },
    {
      name: 'Contract',
      cell: applicant => <span className="text-uppercase text-left">{`${(applicant.applicant_data && applicant.applicant_data.contract_details) ? applicant.applicant_data.contract_details.name : '-'}`}</span>,
      sortable: false,
    },
    {
      name: 'Email',
      cell: applicant => <span className="text-lowercase text-left">{`${applicant.applicant_data ? applicant.applicant_data.email : '-'}`}</span>,
      sortable: false,
    },
    {
      name: 'Card#',
      cell: applicant => <span className="text-uppercase text-left">{`${applicant.cardNo}`}</span>,
      sortable: false,
    },
    {
      name: 'Applicant Type',
      cell: applicant => <span className="text-capitalize text-left">{applicant.applicant_data ? _.replace(applicant.applicant_data.applicant_type, '_', ' ') : '-'}</span>
    },
    {
      name: 'Access Control',
      sortable: false,
      cell: applicant => <span className="text-capitalize text-left">{`${applicant.doorName}`}</span>,
    },
    // {
    //   name: 'Reader Name',
    //   sortable: true,
    //   cell: applicant => <span className="text-uppercase text-left">{`${applicant.readerName}`}</span>,
    // },

  //${moment(applicant.eventTime).format("DD/MM/YYYY HH:MM A")}
    // {
    //   name: 'Dev Name',
    //   sortable: false,
    //   cell: applicant => <span className="text-left">{`${applicant.devName}`}</span>,
    // },
    {
      name: 'Entry/Exit',
      cell: applicant => <span className="text-capitalize text-left">{`${applicant.inAndOutType}`}</span>,
      sortable: false
    },
    {
      name: 'Event Type',
      cell: applicant => <span className="text-left">{`${applicant.eventType}`}</span>,
      sortable: false
    },
    {
      name: 'Event Time',
      cell: applicant => <span className="text-left">{`${applicant.eventTime}`}</span>,
      //cell: applicant => <span className="text-left">{`${formatDate(new Date(applicant.eventTime))}`}</span>,
      //moment('12/02/2014','MM/DD/YYYY').format('YYYY/MM/DD')
    }
  ];

  const onChangePage = (page) => {
    let pageActionType = currentPageNumber > page ? "previous" : "next";
    if(page === 1){
      pageActionType = "first";
    }else if(page === Math.ceil(totalEntries/rowsPerPage)){
      pageActionType = "last";
    }
    setPageAction(pageActionType);
    const currentMarkerId = pageActionType === "next" ? acsReport[acsReport.length-1]._id.$oid : acsReport[0]._id.$oid;
    setMarkerId(currentMarkerId);
    setCurrentPageNumber(page);
  }

  const onSearchInputChange = searchQuery => {
    setSearchQueryString(searchQuery);
  };


  const onChangeRowsPerPage = rows => {
    setRowsPerPage(rows);
  }

  const onExport = () => {
    setIsLoading(true);
    let filterItems = {
      'name' : searchQueryString,
      'contract': contracts && contracts && _.groupBy(contracts, "id")[selectedContractor] ? contracts && _.groupBy(contracts, "id")[selectedContractor][0].name : "",
      'applicant_type': applicantType,
      'event_time_from': eventTimeFrom === "" ? "" : moment(eventTimeFrom).format("YYYY-MM-DD"),
      'event_time_to': eventTimeTo === "" ? "" : moment(eventTimeTo).format("YYYY-MM-DD")
    };
    let filter = "";
    for (const [, [key, value]] of Object.entries(Object.entries(filterItems))) {
      if(value !== ""){
        filter += `&filter['${key}']=${value}`;
      }
    }

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${token}`
      },
      url: `${API_BASE_URL}/reports/acs-transaction/export?${filter}`,
    };
    axios(options).then(response => {
      setIsLoading(false);
      setShowSuccessModal(true);
      setShowSuccessMessage(response.data.data.message)
    });
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage('')
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>ACS Transaction Report</h1>
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body remove-header">
              <AcsReportFilter
                onContractorChange={setSelectedContractor}
                onApplicantTypeChange={setApplicantType}
                onEventTimeFromChange={setEventTimeFrom}
                onEventTimeToChange={setEventTimeTo}
                contracts={contracts}
                onSearchInputChange={onSearchInputChange}
                onExport={onExport}
                onResetInitiated={onResetInitiated}
                buttonVisibility={disableExport}
              />
              <DataTable
                subHeader={true}
                //subHeaderComponent={<> <button className="btn btn-primary">Export</button><SearchBar onSearchInputChange={onSearchInputChange} /></>}
                progressPending={isLoading}
                progressComponent={<Loader />}
                columns={columns}
                data={acsReport}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalEntries}
                paginationResetDefaultPage={resetPagination}
              />
            </div>
          </div>
        </div>
      </div>


      <div className={`reportStatusPopup ${showSuccessModal ? 'show' : ''}`}>
        <div className="popUp">
          <div className='content'>
            {showSuccessMessage}
          </div>
          <div className="action">
            <button className="btn btn-primary" onClick={closeReportSuccessPopup}>OK</button>
          </div>
        </div>
  </div>
    </>
  );
};

export default AcsTransactionReport;
