import React from "react";
import _ from "lodash";
import { _mapPayload, parseNodeIdentifiers } from "../../mappers/userRoleMappers";


const ActionsTree = ({ actionsTreeData, updatePermissionsSelected, treePayload }) => {
    const [selectedPermissions, setSelectedPermission] = React.useState([]);
    React.useEffect(() => {
        parseSelectedPermissions(treePayload);
        //createPayloadData();
    },[treePayload]);
    let treeDummyArray = [];
    const createEmptyNode = (code, isParent) => ({
        children: [],
        actions: [],
        code,
        isParent
    });

    const parseSelectedPermissions = payload => {
        const parsedResp = parseNodeIdentifiers(payload);
        setSelectedPermission(parsedResp);
    };

    const payLoadArrayBuilder = (selectedData) => {
        switch (selectedData.length) {
            case 2:
                if (selectedData[0] === 'isNode') {
                    if (treeDummyArray[selectedData[1]] === undefined) {
                        treeDummyArray[selectedData[1]] = createEmptyNode(selectedData[1], true);
                    }
                } else {
                    treeDummyArray[selectedData[0]]['actions'] = [...treeDummyArray[selectedData[0]]['actions'], selectedData[1]];
                }
                break;
            case 3:
                
                if (selectedData[0] === 'isNode') {
                    if (treeDummyArray[selectedData[1]] === undefined) {
                        let parentNode = createEmptyNode(selectedData[1], true);
                        if (parentNode.children[selectedData[2]] === undefined) {
                            parentNode.children[selectedData[2]] = createEmptyNode(selectedData[1], false);
                        }
                        treeDummyArray[selectedData[1]] = parentNode;
                    } else {
                        treeDummyArray[selectedData[1]].children[selectedData[2]] = createEmptyNode(selectedData[2], false);
                    }
                } else {
                    treeDummyArray[selectedData[0]].children[selectedData[1]]['actions'] = [...treeDummyArray[selectedData[0]].children[selectedData[1]]['actions'], selectedData[2]];
                }
                break;
                // case 4:
                //     if (selectedData[0] == 'isNode') {
                //         if (treeDummyArray[selectedData[1]] === undefined) {
                //             let parentNode = createEmptyNode(selectedData[1], true);
                //              parentNode.children[selectedData[2]] = createEmptyNode(selectedData[1], false);
                //              parentNode.children[selectedData[3]] = createEmptyNode(selectedData[2], false);
                //             treeDummyArray[selectedData[1]] = parentNode;
                //         } else if (treeDummyArray[selectedData[2]] === undefined) {
                //                 let parentNode = createEmptyNode(selectedData[2], false);
                //                  parentNode.children[selectedData[3]] = createEmptyNode(selectedData[2], false);
                //                 treeDummyArray[selectedData[1]] = parentNode;
                //             }
                //             treeDummyArray[selectedData[1]].children[selectedData[2]] = createEmptyNode(selectedData[2], false);
                        
                //     } else {
                //         treeDummyArray[selectedData[0]].children[selectedData[1]]['actions'] = [...treeDummyArray[selectedData[0]].children[selectedData[1]]['actions'], selectedData[2]];
                //     }
                //     break;
            default:
                break;
        }
    };

    const createPayloadData = () => {
        treeDummyArray = [];
        const dummy = [];
        const parent = document.getElementById("permissionTree");
        const checkboxes = parent.getElementsByTagName('input');
        for (var i = 0; i < checkboxes.length; ++i) {
            var inputNode = checkboxes[i];
            if (inputNode.checked) {
                dummy.push(inputNode.value);
                const selectedData = _.split(inputNode.value, '~');
                payLoadArrayBuilder(selectedData);
            }
        }
        const payload = _mapPayload(treeDummyArray);
        //console.log('payload',payload)
        updatePermissionsSelected(payload);
        parseSelectedPermissions(payload);
    };
    const onPermissionCheckboxStatusChange = (e) => {
        e.stopPropagation();
        const parent = getClosest(e.target, '.permissionGroupLi');
        const checkboxes = parent.getElementsByTagName('input');
        for (var i = 0; i < checkboxes.length; ++i) {
            var inputNode = checkboxes[i];
            inputNode.checked = e.target.checked;
        }
        createPayloadData();
    };

    const getClosest = (elem, selector) => {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };


    const ActionsComponent = (actions) => {
        const actionsNode = _.map(actions, item => {
            let unquieId = _.uniqueId();
            return (<li key={_.uniqueId('li-')}>
                <div className="action-node custom-checkbox custom-control">
                    <input onClick={(e) => createPayloadData()}  defaultChecked={_.includes(selectedPermissions, item.identifier)} value={item.identifier} type="checkbox" name="permission-checkbox" id={`customckbx-${unquieId}`} className="custom-control-input" />
                    <label className="custom-control-label text-uppercase" htmlFor={`customckbx-${unquieId}`}> {item.title}</label>
                </div>
            </li>);
        });
        return (<ul className="role-tree-ul action-ul" key={_.uniqueId('ul-')}>{actionsNode}</ul>);
    };
    const TreeComponent = (data) => {
        const treeNode = _.map(data, item => {
            let unquieId = _.uniqueId();
            return (<li className="permissionGroupLi" key={_.uniqueId('li-')}>
                <div className="action-node custom-checkbox custom-control">
                    <input defaultChecked={_.includes(selectedPermissions, item.identifier)} value={item.identifier} name="permission-checkbox" onClick={(e) => onPermissionCheckboxStatusChange(e)} type="checkbox" id={`customckbx-${unquieId}`} className="custom-control-input" />
                    <label className="custom-control-label text-uppercase" htmlFor={`customckbx-${unquieId}`}> {item.title}</label>
                    {ActionsComponent(item.actions)}
                </div>
                {item.children && item.children.length > 0 && TreeComponent(item.children)}
            </li>);
        });
        return (<ul className="role-tree-ul access-items-ul" key={_.uniqueId('ul-')}>{treeNode}</ul>);
    };

    return (
        <>
            <div className="row">
                <div id="permissionTree" className="col-12 clt">
                    {_.map(actionsTreeData, item => <TreeComponent key={_.uniqueId()} data={item} />)}
                </div>
            </div>





        </>
    );
};
export default ActionsTree;
