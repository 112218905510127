import React, { Fragment } from "react";
import { useBodyClass } from "../helpers/utils";
import { ToastProvider } from 'react-toast-notifications';



const UserLayout = ({children}) => {
  useBodyClass(['background', 'no-footer', 'ltr', 'rounded']);
  return (
    <Fragment>
      <div className="fixed-background" />
      <main>
        <div className="container"><ToastProvider autoDismissTimeout={5000} autoDismiss={true}>{children}</ToastProvider></div>
      </main>
    </Fragment>
  );
};

export default UserLayout;