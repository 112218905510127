import React from "react";
import _ from "lodash";
import Select from 'react-select';

const UserFormComponent = ({
	title,
	roles,
	formData,
	errors,
	updateInputChange,
	contracts,
	onContractorChange,
	updateRoleInputChange,
	roleType,
	selectedContracts
}) => {

	const parseToSelectFormat = (options, keys) => _.map(options, option => ({ label: option[keys.label], value: option[keys.value] }));
	return (
		<>
			<p>{title}</p>
			<div className="form-group position-relative">
				<label><b>Full Name*</b></label>
				<input type="text" value={formData.fullName} name="fullName" className="form-control" placeholder="Full Name" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "fullName" && errors.errorMsg}</span>
			</div>
			{/* <div className="form-group position-relative">
				<label><b>Last Name(Optional)</b></label>
				<input type="text" value={formData.lastName} name="lastName" className="form-control" placeholder="Last Name" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "lastName" && errors.errorMsg}</span>
			</div> */}
			{/* <div className="form-group position-relative">
				<label><b>Username</b></label>
				<input type="text" value={formData.username} name="username" className="form-control" placeholder="Username" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "username" && errors.errorMsg}</span>
			</div> */}
			<div className="form-group position-relative">
				<label><b>Email*</b></label>
				<input type="text" value={formData.email} name="email" className="form-control" placeholder="Email" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "email" && errors.errorMsg}</span>
			</div>
			<div className="form-group position-relative">
				<label><b>Phone</b></label>
				<input type="text" value={formData.phoneNumber} name="phoneNumber" className="form-control" placeholder="Phone" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "phoneNumber" && errors.errorMsg}</span>
			</div>
			<div className="form-group position-relative">
				<label><b>Role*</b></label>
				<select className="form-control" value={formData.role} placeholder="Role" name="role" onChange={(e) => updateRoleInputChange(e.target)}>
					<option value="">Roles</option>
					{_.map(roles.data, role => <option key={_.uniqueId('_role-option')} data-role={JSON.stringify(role)} value={role.id}>{role.name}</option>)}
				</select>
				<span className="validation-error-message">{errors.field === "role" && errors.errorMsg}</span>
			</div>
			{roleType === "account-coordinator" &&
				<div className="form-group position-relative">
					<label><b>Contractors</b></label>
					<Select value={selectedContracts} onChange={opt => onContractorChange(opt)} isMulti closeMenuOnSelect={false} options={parseToSelectFormat(contracts, { label: 'name', value: 'id' })} name="contract" placeholder="Select" />
					<span className="validation-error-message">{errors.field === "role" && errors.errorMsg}</span>
				</div>
			}
		</>
	);
};

export default UserFormComponent;