import React from "react";
import moment from "moment";
import _ from "lodash";

const ApproveWithCourse = ({ trainings, onInputChange, formState, errors }) => {
    return (
        <div className="row">
            <div className="col-12 py-2"><i>Select a date for <b>SWSIC Training</b> and submit </i></div>
            <div className="form-group position-relative error-l-75 col-sm-12">
                <label htmlFor="trainingDate">Training Date & Time</label>
                <select value={formState.training} onChange={(e) => onInputChange(e.target)} className="form-control" name="training">
                    <option value="">Training Date & Time </option>
                    {_.map(trainings, training => <option key={_.uniqueId('training-date-')} value={training.id}>{`${training.topic} - ${moment(training.trainingDateTime).format('lll')}`}</option>)}
                </select>
                {<p className="error-message pr15">{errors && errors.training ? errors.trainingDateTime : ''}</p>}
            </div>
        </div>
    );
};

export default ApproveWithCourse;
