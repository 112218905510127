import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const NotificationCardItem = ({ item, cardSize }) => {
  const history = useHistory();
  // card Size determine the cards per row..
  const classDec = `align-items-stretch col-xl-${cardSize} col-lg-${cardSize} mb-4 col-md-${cardSize} col-sm-6 col-xs-12`;
  const manageFooter = (data) => {
    return (<div className="card-bottom row m-0">
      {
        _.map(data, item => {
          if (item.isLink === true) {
            return (<div key={_.uniqueId('link-')} className="text-left col p-0"><a className="anchor-white" href={item.value}>{item.key}</a></div>)
          } else {
            return (<div key={_.uniqueId('text-')} className="text-left col p-0">{`${item.key} ${item.value}`}</div>)
          }

        })
      }
    </div>);
  };

  const manageCardIcons = (itemtitle) => {
    var title = itemtitle && itemtitle.toLowerCase().replace(" ", "");

    if( title && title === 'peoplecounter') {
      return ('iconsminds-mens mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'trafficcounter'){
      return ('iconsminds-traffic-light mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'overnightstay'){
      return ('iconsminds-bell mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'staff'){
      return ('iconsminds-male mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'visitor'){
      return ('iconsminds-business-mens mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'delivery'){
      return ('iconsminds-box-close mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'deliveryregular'){
      return ('iconsminds-box-close mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'adhoc-delivery'){
      return ('iconsminds-box-with-folders mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else if(title && title === 'deliveryadhoc'){
      return ('iconsminds-box-with-folders mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }else{
      return ('iconsminds-puzzle mb-0 mr-2 text-white align-text-bottom d-inline-block')
    }
  };

  const handleRedirection = (hasLink, route) => {
    if(hasLink){
      history.push(route);
    }
  }

  return (

    <div onClick={()=> handleRedirection(item.hasLink, item.route)} className={classDec}>
      <div className={`progress-banner card notification-card overflow-hidden ${(item.type === 'alert' && item.count > 0) ? "gradient-red" : ""}`}>
        <div className="justify-content-between d-flex flex-row align-items-center card-body p-4">
          <div className="notification-header-wrapper w-100">
            <div className="notification-header mb-3 d-flex align-items-center justify-content-between">
              <i className={manageCardIcons(item.title)}></i>
              <div className="notificaton-header-count">{item.count}</div>
            </div>
            <div>
              <p className="lead text-white text-capitalize">{item.title}</p>
              <div className="text-small text-white p-0 m-0">{manageFooter(item.metaData)}</div>
            </div>
          </div>
      </div>
    </div>
  </div>
  )
};

export default NotificationCardItem;
