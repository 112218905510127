import React from "react";

const ModalComponent = ({
  show,
  header,
  content,
  showSubmit,
  onSubmitCallback,
  onCloseCallback
}) => (
    <div className={`modal fade ${show ? 'show d-block' : ''}`} id="ModalComponent" tabIndex="-1" role="dialog"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{header}</h5>
            <button type="button" className="close" onClick={() => onCloseCallback()} data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {content}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-primary" onClick={() => onCloseCallback()}>Cancel</button>
            {showSubmit !== false &&
              <button type="button" onClick={() => onSubmitCallback()} className="btn btn-primary" >Submit</button>
            }
          </div>
        </div>
      </div>
    </div>
  );

export default ModalComponent;