/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from 'jsonapi-react';
import _ from "lodash";
import DataTable from 'react-data-table-component';
import { AppContext } from "../../context/AppContext";
//import { useBodyClass } from "../../helpers/utils";
import CompanyFormComponent from "../../components/CompanyFormComponent/CompanyFormComponent";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from 'react-toast-notifications';
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";

const Company = ({location}) => {
    const initialState = {
        name: ''
    };
    const { addToast } = useToasts();


    const { showRightModal, manageRightModal, showModal, manageModal, pagePermissions, initialPageParams, updateInitialPageParams, setPageUrlParams } = React.useContext(AppContext);
    //useBodyClass('modal-open');
    const [mode, setMode] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [currentCompanyId, setCurrentCompanyId] = React.useState(0);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [searchQueryString, setSearchQueryString] = React.useState('');
    const [formState, updateFormState] = React.useState(initialState);

    const [searchGoingOn, setSearchGoingOn] = React.useState(false);
    const [activePage, setactivePage] = React.useState(1);
    const [createCompany] = useMutation(['organisation', 'company']);
    const token = localStorage.getItem('access_token');
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };


    let paginationSettings;
    if (searchGoingOn) {
      paginationSettings = {};
    } else {
      paginationSettings = {
        number: currentPageNumber,
        size: rowsPerPage,
      };
    }

    const companies = useQuery(['organisation', 'company', {
        filter: {
            search: searchQueryString
        },
        page: paginationSettings
    }], { client });


    React.useEffect(() => {
      if (location) {
        updateInitialPageParams(location);
      }
    }, []);

    React.useEffect(() => {
      if (initialPageParams) {
        setCurrentPageNumber(initialPageParams.initialPageNumber);
        setactivePage(initialPageParams.initialPageNumber);
        setSearchQueryString(initialPageParams.initialSearchKey);
      }
    }, [initialPageParams]);

    const [updateCompany] = useMutation(['organisation', 'company', currentCompanyId], { method: 'PUT', client });
    const onInputChange = inputText => {
        setCompanyName(inputText);
        updateFormState({
            ...formState,
            name: inputText
        });
    };
    const manageModalSettings = (status) => {
        manageRightModal(status);
    };

    const deleteCompanyDetails = async () => {
        const { error } = await client.delete(['organisation', 'company', currentCompanyId]);
        if (error === undefined) {
            companies.refetch();
            addToast(DISPLAY_TEXTS.COMPANY_DELETED, { appearance: 'success' });
        } else {
            addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: 'warning' });
        }
        setCurrentCompanyId(0);
        manageModal(false);
    };

    const submitNewCompany = async () => {
        if (formState.name !== '') {
            const { data, error } = await createCompany(formState);
            if (data && error === undefined) {
                companies.refetch();
                addToast(DISPLAY_TEXTS.COMPANY_ADDED, { appearance: 'success' });
            } else if (error !== undefined && error.detail) {
                addToast(error.detail, { appearance: 'error' });
            } else {
                addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: 'warning' });
            }
            manageModalSettings(false);

        }
    };

    const updateCompanyData = async () => {
        const { data, error } = await updateCompany({ ...formState, id: currentCompanyId });
        if (data && error === undefined) {
            companies.refetch();
            addToast(DISPLAY_TEXTS.COMPANY_UPDATED, { appearance: 'success' });
        } else if (error !== undefined && error.detail) {
            addToast(error.detail, { appearance: 'error' });
        } else {
            addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: 'warning' });
        }
        setMode('');
        manageModalSettings(false);
    };

    const addNewCompany = () => {
        setMode("add");
        setCompanyName("");
        manageRightModal(true);
    };

    const updateCompanyDetails = id => {
        setMode('edit');
        setCurrentCompanyId(id);
        const exisitingData = _.keyBy(companies.data, 'id')[id];
        setCompanyName(exisitingData.name);
        updateFormState({
            name: exisitingData.name
        });
        manageModalSettings(true);
    };

    const manageDeletedOption = id => {
        setCurrentCompanyId(id);
        manageModal(true);
    };

    const doCloseModal = () => {
        setCurrentCompanyId(0);
        manageModal(false);
    };

    const customStyles = {

        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '14px'
            },
        }
    };

    const columns = [
        {
            name: 'Company Name',
            selector: 'name',
            sortable: true
        },
        {
            name: 'Actions',
            sortable: false,
            cell: company => <>
                {_.includes(pagePermissions, 'edit') &&  <span className="iconsminds-pen user-role-action-icons edit" onClick={() => updateCompanyDetails(company.id)}></span> }
                {_.includes(pagePermissions, 'delete') &&  <span onClick={() => manageDeletedOption(company.id)} className="simple-icon-trash user-role-action-icons delete"></span>}
                </>,
            right: true
        }
    ];

    let paginationGoing = false;
    const onChangePage = (page) => {
      setSearchGoingOn(false);
      paginationGoing = true;
      setCurrentPageNumber(page);
      const setParams = {
        page: page,
        searchQueryString: searchQueryString,
        initialPageParams: initialPageParams,
      };
      setPageUrlParams(setParams);
    };

    const onChangeRowsPerPage = (rows) => {
      setRowsPerPage(rows);
    };

    let searchGoing = false;
    const onSearchInputChange = (searchQuery) => {
      searchGoing = true;
      setSearchGoingOn(true);
      setSearchQueryString(searchQuery);
      setactivePage(1);
      const setParams = {
        page: 1,
        searchQueryString: searchQuery,
        initialPageParams: initialPageParams,
      };
      setPageUrlParams(setParams);
    };


    window.onpopstate = (e) => {
      if(!searchGoing && !paginationGoing){
        const searchWord = window.location.hash.split("search=")[1];
        const wordString = decodeURI(searchWord);
        if (searchWord) {
          setSearchQueryString(wordString);
        }else{
          setSearchQueryString('');
        }

        const pageNumber = window.location.hash.split("page=")[1];
        if(pageNumber){
          const pageFromUrl = pageNumber.split("&")[0];
          setCurrentPageNumber(Number(pageFromUrl))
        }else{
          setCurrentPageNumber(1)
        }

      }else{
        searchGoing = false;
        paginationGoing = false
      }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">

                    <h1>Companies</h1>
                    {_.includes(pagePermissions, 'create') &&  <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => addNewCompany()}>ADD NEW</button>}
                    <div className="separator mb-5"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                             subHeader={true}
                             subHeaderComponent={<SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/>}
                             progressPending={companies.isLoading}
                             progressComponent={<Loader/>}
                                columns={columns}
                                data={companies.data}
                                customStyles={customStyles}
                                paginationServer={true}
                                striped={true}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                pagination={true}
                                paginationPerPage={rowsPerPage}
                                paginationTotalRows={companies.meta && companies.meta.pagination.count}
                                paginationDefaultPage={activePage}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <ModalComponent
                show={showModal}
                header={<b>Confirm Delete</b>}
                content={<p>Are you sure you want to delete the selected Company details ?</p>}
                onSubmitCallback={deleteCompanyDetails}
                onCloseCallback={doCloseModal}
            />

            <ModalRightComponent
                show={showRightModal}
                header={mode === "add" ? "Add Company" : "Update Company"}
                submitButtonText={mode === "add" ? "Submit" : "Update"}
                classes="normal-right-modal"
                content={
                    <CompanyFormComponent
                        title={mode === "add" ? 'Submitting below form will add "a new company".' : 'Submitting below form will update "the company".'}
                        companyName={companyName}
                        updateInputChange={onInputChange}
                    />
                }
                onSubmitCallback={() => mode === 'add' ? submitNewCompany() : updateCompanyData()}
                onCloseCallback={() => manageModalSettings(false)}
            />
        </>
    );
};

export default Company;
