import React from "react";
import _ from "lodash";

let StepperComponent = ({stepper}) => {
  let circleStartX1 = 35;
  let circleStartX2 = 35;
  let stepperCountX = 31;
  let rectX1 = 46;
  let rectX2 = 195;
  let rectX3 = 210;
  let stepperTextX1 = 68;
  let connectorX1 = 209;
  let connectorX2 = 225;
  let incrementer = 210;
  let stepperItemWidth = 215* stepper.length;

  
  const drawStepper = () => {
    return _.map(stepper, (stepperItem, i) => {
      if(stepperItem.id !== 1){
        circleStartX1 += incrementer;
        circleStartX2 += incrementer;
        stepperCountX += incrementer;
        rectX1 += incrementer;
        rectX2 += incrementer;
        rectX3 += incrementer;
        stepperTextX1 += incrementer;
        connectorX1 += incrementer;
        connectorX2 += incrementer;
      }
      let color = stepperItem.isCompleted ? '#8BC34A' : stepperItem.isCurrent ? "#145388" : "#cccccc";
      let fillColor = stepperItem.isCompleted ? '#8BC34A' : stepperItem.isCurrent ? "#145388" : "#ffffff";
      let textColor = stepperItem.isCompleted ? '#ffffff' : stepperItem.isCurrent ? "#ffffff" : "#cccccc";
      return (<g key={_.uniqueId('g-')}> 
        <circle cx={circleStartX1} cy="30" r="20" stroke={color} strokeWidth="1" fill="#ffffff" />
        <circle cx={circleStartX2} cy="30" r="17" stroke={color} strokeWidth="1" fill={fillColor} />
        <text x={stepperCountX} y="36" fill={textColor}>{stepperItem.id}</text>
        <path d={`M${rectX1} 13 L${rectX2} 13 ${rectX3} 30 ${rectX2} 47 ${rectX1} 47 A 20 20 0 0 0 ${rectX1} 13`} fill={fillColor} stroke={color} strokeWidth="1" />
        <text x={stepperTextX1} y="35" fill={textColor}>{stepperItem.title}</text>
        { stepperItem.id !== stepper.length &&
        <path d={`M${connectorX1} 30 ${connectorX2} 30`} stroke={color} />}
      </g>);
    })
  };
  return (
    <div className="svgContainer">
    <svg viewBox={`0 0 ${stepperItemWidth} 60`}>
      {drawStepper()}
   Sorry, your browser does not support inline SVG.
    </svg>
    </div>
  )
};

export default StepperComponent;