import React from "react";
import _ from "lodash";

const TradeInformationComponent = ({ tradeInformations, previousStep, nextStep, manageStepper, onStepComplete, isEdit, applicantProfile }) => {


    const initialState = {
        tradeInformation:''
    };
    const [tradeInfoId, setTradeInfoId] = React.useState('');
    const [formState, setFormState] = React.useState(initialState);
    React.useEffect(() => {

       // props.manageStepper('next');
    },[]);

    const onPreviousClick = () => {
        previousStep();
        manageStepper('prev');
    };

    const onNextClick = () => {
        onStepComplete(formState);
        nextStep();
        manageStepper('next');
    };

    const handleTradeInfoItemChange = (e) => {
        if(e.target.checked){
            setFormState({
                tradeInformation: e.target.value
            })
            setTradeInfoId(e.target.value);
        }
    };

    React.useEffect(() => {
        if (isEdit && applicantProfile) {
            const updatedState = {
                tradeInformation: applicantProfile.tradeInformation == null ?  '' : applicantProfile.tradeInformation.id
            };
            setFormState(updatedState);
            setTradeInfoId(applicantProfile.tradeInformation == null ?  '' : applicantProfile.tradeInformation.id);
        }
    }, [isEdit, applicantProfile]);

    const getTradeInformations = (tradeInfos) => {
        return _.map(tradeInfos, tradeInfo => <div key={_.uniqueId('tradeInfoItem-')} className="tradeInfoItem"><input checked={tradeInfoId === tradeInfo.id ? true : false}  onChange={(e) => handleTradeInfoItemChange(e)} type="checkbox" value={tradeInfo.id} /><span>{tradeInfo.name}</span></div>)
    };

    return (
        <div id="step-6" className="row">
            <div className="card-columns">
                {
                    _.map(tradeInformations, tradeInfo =>
                        <div key={_.uniqueId('tradeInfo-')} className="tradeInfoBox card"><div className="tradeInfoBoxContainer"><b>{tradeInfo.name}</b>{getTradeInformations(tradeInfo.tradeInformation)}</div></div>)
                }
            </div>
            <div className="btn-toolbar custom-btn-toolbar text-center w-100">
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onPreviousClick()}>Previous</button>
                <button disabled={tradeInfoId === '' ? true : false} type="button" className="btn btn-primary btn-sm" onClick={() => onNextClick()}>Next</button>
            </div>
    </div>);
};

export default TradeInformationComponent;
