import React from "react";
import ReactTooltip from 'react-tooltip';

import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";

const DashboardTrainingCounterComponent = ({dashboardStats}) => {
	const history = useHistory();
    const navidateToPendingLists = (type) => {
        history.push({
            pathname: VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING,
            search: `?type=${type}&list=training`,
        });
    };

	return (
    		<div className="col-lg-4 col-md-12 mb-4 p-0">
    			<div className="col-md-12"><h5 className="dashboard-heading">Applicant Training Expiry Status</h5></div>

        		<div className="col-6 mb-3 float-left">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('staff')}>
                            <i className="iconsminds-male"></i>
                            <p className="card-text mb-0">Staff</p>
                            <p data-tip="Training expiring within one month" className="lead text-center">{dashboardStats && dashboardStats.applicantTrainingExpiryStats.staff}</p>
                        </div>
                    </div>
                </div>
				<div className="col-6 mb-3 float-left">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('delivery_regular')}>
                            <i className="iconsminds-box-close"></i>
                            <p className="card-text mb-0">Delivery Regular</p>
                            <p data-tip="Training expiring within one month" className="lead text-center">{dashboardStats && dashboardStats.applicantTrainingExpiryStats.deliveryRegular}</p>
                        </div>
                    </div>
                </div>
                <ReactTooltip />
    		</div>
    		);
};
export default DashboardTrainingCounterComponent;

