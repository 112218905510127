/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import _ from "lodash";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import UserFormComponent from "../../components/UserFormComponent/UserFormComponent";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import { parseAPIResponseError } from "../../helpers/helpers";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
import { SET_PAGE_COUNT } from "../../constants";

const UserManagement = ({location}) => {
  const initialState = {
    fullName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: 0,
    contractsId: [],
  };
  const { addToast } = useToasts();


  const {
    showRightModal,
    manageRightModal,
    showModal,
    manageModal,
    pagePermissions,
    initialPageParams,
    updateInitialPageParams,
    setPageUrlParams,
  } = React.useContext(AppContext);
  const [mode, setMode] = React.useState("");
  const [validationMessage, setValidationMessage] = React.useState({});
  const [currentUserId, setCurrentUserId] = React.useState(0);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQueryString, setSearchQueryString] = React.useState('');
  const [formState, updateFormState] = React.useState(initialState);
  const [roleType, setRoleType] = React.useState("");
  const [selectedContracts, setSelectedContracts] = React.useState([]);
  const [createUser] = useMutation(["accounts", "users"]);
  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(1);
  const token = localStorage.getItem("access_token");
  const allowNewUser = localStorage.getItem("allowNewUser");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {};
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const users = useQuery(
    [
      "accounts",
      "users",
      {
        filter: {
          search: searchQueryString,
        },
        page: paginationSettings,
      },
    ],
    { client }
  );

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
  }, []);

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(initialPageParams.initialPageNumber);
      setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
    }
  }, [initialPageParams]);

  const contracts = useQuery(
    [
      "organisation",
      "contract",
      {
        page: {
          number: 1,
          size: SET_PAGE_COUNT,
        },
      },
    ],
    { client }
  );
  const roles = useQuery(["accounts", "roles"], { client });
  const [updateUser] = useMutation(["accounts", "users", currentUserId], {
    method: "PUT",
    client,
  });
  const onInputChange = (target) => {
    updateFormState({
      ...formState,
      [target.name]: target.value,
    });
  };

  const onContractorChange = (options) => {
    let contractors = _.map(options, (option) => option.value);
    //console.log(options);
    setSelectedContracts(options);
    updateFormState({
      ...formState,
      contractsId: contractors,
    });
  };

  const manageModalSettings = (status) => {
    manageRightModal(status);
    if (status) {
      addBodyClass("modal-open");
    } else {
      removeBodyClass("modal-open");
    }
  };

  const deleteUserDetails = async () => {
    const { error } = await client.delete(["accounts", "users", currentUserId]);
    if (error === undefined) {
      users.refetch();
      addToast(DISPLAY_TEXTS.USER_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setCurrentUserId(0);
    manageModal(false);
    removeBodyClass("modal-open");
  };

  const submitNewUser = async () => {
    const { data, error } = await createUser(formState);
    if (data && error === undefined) {
      users.refetch();
      addToast(DISPLAY_TEXTS.USER_ADDED, { appearance: "success" });
      manageModalSettings(false);
    } else if (error !== undefined && error.detail) {
      const errorMsg = parseAPIResponseError(error);
      if (initialState.hasOwnProperty(errorMsg.field)) {
        setValidationMessage(errorMsg);
      } else {
        addToast(error.detail, { appearance: "error" });
      }
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
  };

  const updateUserData = async () => {
    const { data, error } = await updateUser({
      ...formState,
      id: currentUserId,
    });
    if (data && error === undefined) {
      users.refetch();
      addToast(DISPLAY_TEXTS.USER_UPDATED, { appearance: "success" });
    } else if (error !== undefined && error.detail) {
      addToast(error.detail, { appearance: "error" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setMode("");
    removeBodyClass("modal-open");
    manageModalSettings(false);
  };

  const addNewUser = () => {
    setMode("add");
    updateFormState(initialState);
    setValidationMessage({});
    manageRightModal(true);
    addBodyClass("modal-open");
  };

  const updateUserDetails = async (id) => {
    setMode("edit");
    setCurrentUserId(id);
    setValidationMessage({});
    const exisitingData = _.keyBy(users.data, "id")[id];
    var dataset = _.keyBy(roles.data, "id");
    setRoleType(dataset[exisitingData.role.id].slug);
    const exisitingContractsId = _.map(contracts.data, (contract) => {
      if (_.includes(exisitingData.contractsId, contract.id)) {
        return {
          label: contract.name,
          value: contract.id,
        };
      }
    });
    setSelectedContracts(exisitingContractsId);
    updateFormState({
      fullName: exisitingData.fullName,
      email: exisitingData.email,
      phoneNumber: exisitingData.phoneNumber,
      role: exisitingData.role.id,
      contractsId: exisitingData.contractsId,
    });
    manageModalSettings(true);
  };

  const manageDeletedOption = (id) => {
    setCurrentUserId(id);
    addBodyClass("modal-open");
    manageModal(true);
  };

  const doCloseModal = () => {
    setCurrentUserId(0);
    removeBodyClass("modal-open");
    manageModal(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const columns = [
    {
      name: "Full Name",
      sortable: false,
      cell: (user) => (
        <span className="text-capitalize">{`${user.fullName}`}</span>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    // {
    //     name: 'Username',
    //     selector: 'username',
    //     sortable: true
    // },
    {
      name: "Role",
      selector: "role.name",
      sortable: false,
    },
    {
      name: "Phone",
      selector: "phoneNumber",
      sortable: false,
    },
    {
      name: "Actions",
      sortable: true,
      cell: (user) => (
        <>
          {_.includes(pagePermissions, "edit") && (
            <span
              className="iconsminds-pen user-role-action-icons edit"
              onClick={() => updateUserDetails(user.id)}
            ></span>
          )}
          {_.includes(pagePermissions, "delete") && (
            <span
              onClick={() => manageDeletedOption(user.id)}
              className="simple-icon-trash user-role-action-icons delete"
            ></span>
          )}
        </>
      ),
      right: true,
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }

      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const updateRoleInputChange = (target) => {
    var dataset = target.options[target.selectedIndex].dataset;
    const { id, slug } = JSON.parse(dataset.role);
    setRoleType(slug);
    if (slug !== "account-coordinator") {
      setSelectedContracts([]);
      updateFormState({
        ...formState,
        contractsId: [],
      });
    }
    //if(slug !== "account")
    updateFormState({
      ...formState,
      role: id,
    });
  };
  //console.log(pagePermissions)
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>User Management</h1>

          {/* <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                        <ol className="breadcrumb pt-0">
                            <li className="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="#">Library</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Data</li>
                        </ol>
                    </nav> */}
          {_.includes(pagePermissions, "create") && allowNewUser && (
            <button
              type="button"
              className="btn btn-primary btn-sm float-right"
              onClick={() => addNewUser()}
            >
              ADD NEW
            </button>
          )}
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">User Lists</h5>
              <DataTable
                subHeader={true}
                subHeaderComponent={
                  <SearchBar
                    onSearchInputChange={onSearchInputChange}
                    popstateText={searchQueryString}
                  />
                }
                columns={columns}
                data={users.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                progressPending={users.isLoading}
                progressComponent={<Loader />}
                paginationTotalRows={users.meta && users.meta.pagination.count}
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <p>Are you sure you want to delete the selected User details ?</p>
        }
        onSubmitCallback={deleteUserDetails}
        onCloseCallback={doCloseModal}
      />

      <ModalRightComponent
        show={showRightModal}
        header={mode === "add" ? "Add User" : "Update User"}
        submitButtonText={mode === "add" ? "Submit" : "Update"}
        classes="normal-right-modal"
        content={
          <UserFormComponent
            title={
              mode === "add"
                ? "Submitting below form will add a new user."
                : "Submitting below form will update the user."
            }
            updateInputChange={onInputChange}
            validationMessage={validationMessage}
            roles={roles}
            errors={validationMessage}
            formData={formState}
            contracts={contracts.data}
            onContractorChange={onContractorChange}
            updateRoleInputChange={updateRoleInputChange}
            roleType={roleType}
            selectedContracts={selectedContracts}
          />
        }
        onSubmitCallback={() =>
          mode === "add" ? submitNewUser() : updateUserData()
        }
        onCloseCallback={() => manageModalSettings(false)}
      />
    </>
  );
};

export default UserManagement;
