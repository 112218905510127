/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from 'jsonapi-react';
import _ from "lodash";
import axios from 'axios';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { API_BASE_URL, SET_PAGE_COUNT } from "../../constants";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import StepperComponent from "../../components/common/StepperComponent/StepperComponent";
import StepWizard from 'react-step-wizard';
import PersonalComponent from "../../components/common/PersonalComponent/PersonalComponent";
import AdditionalInfoComponent from "../../components/common/AdditionalInfoComponent/AdditionalInfoComponent";
import VerificationComponent from "../../components/common/VerificationComponent/VerificationComponent";
import FinalStepperComponent from "../../components/common/FinalStepperComponent/FinalStepperComponent";
import TradeInformationComponent from "../../components/common/TradeInformationComponent/TradeInformationComponent";

const ManageStaff = () => {
    const initialStepper = [
        {
            id: 1,
            title: 'Personal Details',
            isCurrent: true,
            isCompleted: false
        }, {
            id: 2,
            title: 'Additional Info',
            isCurrent: false,
            isCompleted: false
        }, {
            id: 3,
            title: 'Trade Info',
            isCurrent: false,
            isCompleted: false
        }, {
            id: 4,
            title: 'Verification Info',
            isCurrent: false,
            isCompleted: false
        }, {
            id: 5,
            title: 'Completed',
            isCurrent: false,
            isCompleted: false
        }];
    const { addToast } = useToasts();
    const [wizardInstance, setWizardInstance] = React.useState();
    const history = useHistory();
    const [currentStep, setCurrentStep] = React.useState(1);
    const [stepper, setStepper] = React.useState(initialStepper);
    const [nationality, setNationality] = React.useState([]);
    const [formState, setFormState] = React.useState({});
    const token = localStorage.getItem('access_token');
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };
    const [createVisitor] = useMutation(['applicant', 'profile']);
    const companies = useQuery(['organisation', 'company',{page: {
        number: 1,
        size: SET_PAGE_COUNT
      }}], { client });
    const contracts = useQuery(['organisation', 'contract',{page: {
        number: 1,
        size: SET_PAGE_COUNT
      }}], { client });
    const {data: tradeInformations} = useQuery(['organisation', 'trade-information'], { client });

    React.useEffect(() => {
        const options = {
            method: 'OPTIONS',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `JWT ${token}`
            },
            url: `${API_BASE_URL}/applicant/profile`,
        };
        axios(options).then(response => {
            const { data: {
                data: {
                    actions: {
                        POST: {
                            // applicantType: {
                            //     choices: applicantTypeChoices
                            // },
                            nationality: {
                                choices: nationalityChoices
                            }
                        }
                    }
                }
            } } = response;
            setNationality(nationalityChoices);
        });
    }, []);

    React.useEffect(() => {
        let stepperData = [...stepper];
        const updatedStepperData = _.map(stepperData, item => {
            if (currentStep === stepperData.length) {
                item['isCurrent'] = true;
                item['isCompleted'] = true;
            } else if (item.hasOwnProperty('id') && item.id < currentStep) {
                item['isCurrent'] = false;
                item['isCompleted'] = true;
            } else if (item.hasOwnProperty('id') && item.id === currentStep) {
                item['isCurrent'] = true;
                item['isCompleted'] = false;
            } else {
                item['isCurrent'] = false;
                item['isCompleted'] = false;
            }
            return item;
        });
        setStepper(updatedStepperData);
    }, [currentStep]);

    const manageStepperWizard = (e) => {
        setWizardInstance(e)
        // wizardInstance = e;
    };

    const manageStepper = direction => {
        if (direction === 'prev') {
            setCurrentStep(step => step === 1 ? 1 : step - 1);
        } else {
            setCurrentStep(step => step === stepper.length ? step : step + 1);
        }
    };

    const mergeDataOnStepCompletion = (formData) => {
        const mergedData = { ...formState, ...formData };
        setFormState(mergedData);
    };

    const onSubmitForm = async () => {
        let extraFields = ['identification_type', 'purpose'];
        if (formState.identification_type === 'nric') {
            extraFields.push('finNumber', 'finAlpha', 'passport');
        } else if (formState.identification_type === 'fin') {
            extraFields.push('nricNumber', 'nricAlpha', 'passport');
        } else if (formState.identification_type === 'passport') {
            extraFields.push('nricNumber', 'nricAlpha', 'finNumber', 'finAlpha');
        }
        var formData = _.omit(formState, extraFields);
        formData['workPermitExpiry'] = formData.workPermitExpiry !== "" ? moment(formData.workPermitExpiry).format("DD-MM-YYYY"): null;
        formData['dateOfBirth'] = moment(formData.dateOfBirth).format("DD-MM-YYYY");
        const { error } = await createVisitor(formData);
        if (error === undefined) {
            wizardInstance.nextStep();
            manageStepper('next');
            addToast(DISPLAY_TEXTS.STAFF_ADDED, { appearance: 'success' });
        } else {
            addToast(error.detail, { appearance: 'error' });
        }

    };

    const onBackButtonClick = () => {
      history.goBack();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>New Staff</h1>
                    <button type="button" className="btn btn-primary btn-sm float-right" onClick={onBackButtonClick}>BACK</button>
                    <div className="separator mb-5"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body overflow-hidden">
                            <StepperComponent stepper={stepper} />
                            <StepWizard instance={(e) => manageStepperWizard(e)} className="customStepperWizard" initialStep={1} >
                                <PersonalComponent applicantType={"staff"} onStepComplete={mergeDataOnStepCompletion} nationality={nationality} manageStepper={manageStepper} acRestrictedParam={false} currentStep={currentStep}/>
                                <AdditionalInfoComponent identificationType={formState.identification_type} applicantType={"staff"} companies={companies.data} contracts={contracts.data} onStepComplete={mergeDataOnStepCompletion} manageStepper={manageStepper} acRestrictedParam={false} currentStep={currentStep}/>
                                <TradeInformationComponent onStepComplete={mergeDataOnStepCompletion} manageStepper={manageStepper} tradeInformations={tradeInformations} currentStep={currentStep}/>
                                <VerificationComponent tradeInformations={tradeInformations} applicantType={"staff"} companies={companies.data} contracts={contracts.data} nationality={nationality} onFinish={onSubmitForm} verificationData={formState} manageStepper={manageStepper}/>
                                <FinalStepperComponent manageStepper={manageStepper} />
                            </StepWizard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageStaff;
