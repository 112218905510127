/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import { AppContext } from "../../context/AppContext";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from 'react-toast-notifications';
import { SET_PAGE_COUNT } from "../../constants";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";
import { validatorModule } from "../../helpers/helpers";
import RejectApplication from "../../components/common/RejectApplication/RejectApplication";
import ApproveWithCourse from "../../components/common/ApproveWithCourse/ApproveWithCourse";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
import { parseIdentificationType } from "../../helpers/helpers";
import ExportButton from "../../components/common/Export/Export";


const ApplicantsPending = ({ location }) => {
  const history = useHistory();
  const header = {
    staff: 'Staff',
    visitor: 'Visitors',
    delivery_regular: 'Delivery Regular',
    delivery_adhoc: 'Delivery Adhoc'
  };
  const validationFormat = [
    {
      field: "trainingDateTime",
      isRequired: true,
      fieldName: "Training Date"
    }];
  const { addToast } = useToasts();

  const [exportData, setexportData] = React.useState({});
  const [pageParams, setPageParams] = React.useState({});
  const { showModal, manageModal, currentUser, initialPageParams, updateInitialPageParams, setPageUrlParams } = React.useContext(AppContext);
  const [currentApplicant, setCurrentApplicant] = React.useState();
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rejectValidation, setRejectValidation] = React.useState({ msg: '' });
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [rejectData, setRejectData] = React.useState({ reject_note: '' });
  const [formState, updateFormState] = React.useState({ training: '' });
  const [validationMessage, setValidationMessage] = React.useState({});
  const token = localStorage.getItem('access_token');
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`
  };
  const [searchQueryString, setSearchQueryString] = React.useState('');
  const [rejectApplicantRequest] = useMutation(['applicant', 'profile', currentApplicant, 'reject'], { method: 'PUT', client });
  const [approveApplicantRequest] = useMutation(['applicant', 'profile', currentApplicant, 'approve'], { method: 'PUT', client });
  const [updateApplicantTraining] = useMutation(['applicant', 'profile', currentApplicant, 'training'], { client });

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(0);

  React.useEffect(() => {

    if (location) {
      updateInitialPageParams(location);
    }

    let params = queryString.parse(location.search);
    setPageParams(params);
    setexportData(
      {
        page:'pending_details',
        params:pageParams,
        filters:{
          search : '',
        }
      }
    )
}, []);

React.useEffect(() => {
  if(initialPageParams){
    setCurrentPageNumber(initialPageParams.initialPageNumber);
    setactivePage(initialPageParams.initialPageNumber);
    setSearchQueryString(initialPageParams.initialSearchKey)
  }

},[initialPageParams])

React.useEffect(() => {
  setexportData(
    {
      page:'pending_details',
      params:pageParams,
      filters:{
        search : searchQueryString
      }
    }
  )
}, [searchQueryString, pageParams]);


  const filter = (pageParams.type === "visitor" || pageParams.type === "delivery_adhoc") ? {
    type: pageParams.type,
    search: searchQueryString
  } : {
    type: pageParams.type,
    status: 'pending',
    search: searchQueryString
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {};
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }
  const applicants = useQuery(['applicant', pageParams.type === "visitor" || pageParams.type === "delivery_adhoc" ? 'visitor-adhoc-pending' : 'profile', {
    filter,
    page: paginationSettings,
    include: "company,contract"
  }], { client });


  const { data: trainings } = useQuery(['training', 'schedule', 'upcoming',{page: {
    number: 1,
    size: SET_PAGE_COUNT
  }}], { client });

  const onActionChange = (action, id) => {
    setCurrentApplicant(id);
    if (action === 'approve') {
      manageApproveModalSettings(true);
    } else {
      manageModal(true);
    }
  }

  const onInputChange = target => {
    updateFormState({
      ...formState,
      [target.name]: target.value
    });
  };

  const approveApplicant = () => {
    const approveData = {
      approve: true,
      id: currentApplicant
    }
    const { error } = approveApplicantRequest(approveData);
    applicants.refetch();
    doCloseModal();
    if (error === undefined) {
      addToast(DISPLAY_TEXTS.APPLICANT_APPROVED, { appearance: 'success' });
    } else {
      addToast(error.detail, { appearance: 'error' });
    }
  };

  const confirmApproveApplicant = async () => {
    let errors = validatorModule(formState, validationFormat);
    setValidationMessage(errors);
    if (Object.keys(errors).length === 0) {
      const { data, error } = await updateApplicantTraining({ ...formState, applicant: currentApplicant });
      if (data && error === undefined) {
        approveApplicant();
      } else if (error !== undefined && error.detail) {
        addToast(error.detail, { appearance: 'error' });
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: 'warning' });
      }
    }
    updateFormState({ training: '' });
  };

  const rejectApplicant = () => {
    if (rejectData.reject_note === '') {
      setRejectValidation({ msg: 'Reject note required.' })
    } else {
      const { error } = rejectApplicantRequest(rejectData);
      applicants.refetch();
      doCloseModal();
      if (error === undefined) {
        addToast(DISPLAY_TEXTS.APPLICANT_REJECTED, { appearance: 'success' });
      } else {
        addToast(error.detail, { appearance: 'error' });
      }
    }
  };

  const doCloseModal = () => {
    setCurrentApplicant('');
    removeBodyClass('modal-open');
    manageModal(false);
    setShowApproveModal(false);
    updateFormState({ training: '' });
  };

  const viewApplicant = (id, type) => {
    history.push({
      pathname: VW_ROUTES.APPLICANTS_APPLICANT_VIEW,
      search: `?id=${id}&type=${type}&from=pending_applicants`,
    });
  };

  const customStyles = {

    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    }
  };

  const columns = [
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: true,
      style:"text-transform:capitalize"
    },

    {
      name: 'Identification#',
      sortable: true,
      cell: applicant => <span className="text-uppercase text-left">{parseIdentificationType(applicant)}</span>
    },

    // {
    //   name: 'Package number',
    //   selector: 'contract.name',
    //   sortable: true,
    //   style:"text-transform:capitalize",
    // },


    // {
    //   name: 'Mobile#',
    //   selector: 'mobileNumber',
    //   sortable: true
    // },
    // {
    //   name: 'Email',
    //   selector: 'email',
    //   sortable: true
    // },
    // {
    //   name: 'Vehicle#',
    //   selector: 'vehicleNumber',
    //   sortable: false
    // },
    {
      name: 'Designation',
      selector: 'designation',
      sortable: true
    },
    {
      name: 'Company name',
      selector: 'company.name',
      sortable: true
    },
    {
      name: 'Actions',
      sortable: false,
      cell: applicant => <div className="actionContainer">
        <span className="approve-reject simple-icon-eye user-role-action-icons view mr-2" onClick={() => viewApplicant(applicant.id, applicant.applicantType)}></span>
      {(pageParams.type=== "staff" || pageParams.type==="delivery_regular") && (currentUser.role_slug !== "pub-jacobs") && <>
        <span className="approve-reject approve simple-icon-check" onClick={() => onActionChange('approve', applicant.id)}></span>
        <span className="approve-reject reject simple-icon-close" onClick={() => onActionChange('reject', applicant.id)}></span>
        </>}
      </div>,
      // <select onChange={(e) => onActionChange(e, applicant.id)}>
      //   <option value="">--Select--</option>
      //   <option value={'approve'}>Approve</option>
      //   <option value={'reject'}>Reject</option>
      // </select>,
      right: true
    }
  ];



  const onRejectInputChange = value => {
    setRejectData({
      id: currentApplicant,
      reject_note: value
    })
  };

  const manageApproveModalSettings = (status) => {
    setShowApproveModal(status);
    if (status) {
      addBodyClass('modal-open');
    } else {
      removeBodyClass('modal-open');
    }
  }

  let paginationGoing = false;
    const onChangePage = page => {
      setSearchGoingOn(false);
      paginationGoing = true;
      setCurrentPageNumber(page);
      const setParams = {
        page : page,
        searchQueryString : searchQueryString,
        initialPageParams: initialPageParams
      }
      setPageUrlParams(setParams)
    }

    const onChangeRowsPerPage = rows => {
        setRowsPerPage(rows);
    }

    let searchGoing = false;
    const onSearchInputChange = (searchQuery) => {
      searchGoing = true;
      setSearchGoingOn(true);
      setSearchQueryString(searchQuery);
      setactivePage(1);
      const setParams = {
        page : 1,
        searchQueryString : searchQuery,
        initialPageParams: initialPageParams
      }
      setPageUrlParams(setParams)
    };


    window.onpopstate = (e) => {
      if(!searchGoing && !paginationGoing){
        const searchWord = window.location.hash.split("search=")[1];
        const wordString = decodeURI(searchWord);
        if (searchWord) {
          setSearchQueryString(wordString);
        }else{
          setSearchQueryString('');
        }
        const pageNumber = window.location.hash.split("page=")[1];
        if(pageNumber){
          const pageFromUrl = pageNumber.split("&")[0];
          setCurrentPageNumber(Number(pageFromUrl))
        }else{
          setCurrentPageNumber(1)
        }
      }else{
        searchGoing = false;
        paginationGoing = false
      }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>{header[pageParams.type]} Pending</h1>
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <DataTable
              subHeader={true}
              subHeaderComponent={<><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/><ExportButton exportDetails={exportData}/></>}
              progressPending={applicants.isLoading}
              progressComponent={<Loader/>}
                columns={columns}
                data={applicants.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={applicants.meta && applicants.meta.pagination.count}
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>


      <ModalComponent
        show={showModal}
        header={<b>Confirm Reject</b>}
        content={<RejectApplication
          updateInputChange={onRejectInputChange}
          validation={rejectValidation} />
        }
        onSubmitCallback={rejectApplicant}
        onCloseCallback={doCloseModal}
      />

      <ModalComponent
        show={showApproveModal}
        header={<b>Confirm Approve</b>}
        content={
          (pageParams.type === 'staff' || pageParams.type === 'delivery_regular') ?
            <ApproveWithCourse formState={formState} trainings={trainings} errors={validationMessage} onInputChange={onInputChange} />
            : "Are you sure you want to approve?"
        }
        onSubmitCallback={(pageParams.type === 'staff' || pageParams.type === 'delivery_regular') ? confirmApproveApplicant : approveApplicant}
        onCloseCallback={doCloseModal}
      />

    </>
  );
};

export default ApplicantsPending;
