import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const NotificationAccountStatus = ({ item, cardSize }) => {
  const classDec = `align-items-stretch col-xl-${cardSize} col-lg-${cardSize} mb-4 col-md-${cardSize} col-sm-6 col-xs-12`;
  const [syStatus, setSyStatus] = React.useState({});

  React.useEffect(() => {
    if (item) {
      setSyStatus(item.systemStats);
    }
  }, [item]);

  return (
    <div className={classDec}>
      <div className="user-limit card">
      <div className="d-flex card-wrap">

        <div className="graph">
          <h1 className="text-white text-capitalize">Applicants</h1>
          <div className="circle">

          {syStatus?.applicantCount ?
            <CircularProgressbar
              value={(syStatus?.applicantCount / syStatus?.applicantLimit)*100}
              text={`${((syStatus?.applicantCount / syStatus?.applicantLimit)*100).toFixed()}%`}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: `${(syStatus?.applicantCount / syStatus?.applicantLimit)*100 < 70 ? "#3eb327":"#ef2366"}`,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Customize transition animation
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  // Rotate the path
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: "#d6d6d6",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Rotate the trail
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the text
                text: {
                  // Text color
                  fill: "#fff",
                  // Text size
                  fontSize: "30px",
                },
                // Customize background - only used when the `background` prop is true
                background: {
                  fill: "#3e98c7",
                },
              }}
            />
            :""}
          </div>

          <div className="details">
            <p>Count : {syStatus?.applicantCount} </p>
            <p>Limit : {syStatus?.applicantLimit}</p>
          </div>
        </div>
        <div className="graph">
        <h1 className="text-white text-capitalize">Users</h1>
          <div className="circle">

            {syStatus?.userCount ?
            <CircularProgressbar
              value={(syStatus?.userCount / syStatus?.userLimit)*100}
              text={`${((syStatus?.userCount / syStatus?.userLimit)*100).toFixed()}%`}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: `${(syStatus?.userCount / syStatus?.userLimit)*100 < 70 ? "#3eb327":"#ef2366"}`,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Customize transition animation
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  // Rotate the path
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: "#d6d6d6",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Rotate the trail
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the text
                text: {
                  // Text color
                  fill: "#fff",
                  // Text size
                  fontSize: "30px",
                },
                // Customize background - only used when the `background` prop is true
                background: {
                  fill: "#3e98c7",
                },
              }}
            />
            :""}
          </div>

          <div className="details">
            <p>Count : {syStatus?.userCount}</p>
            <p>Limit : {syStatus?.userLimit}</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default NotificationAccountStatus;
