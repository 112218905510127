import _ from "lodash";
import moment from 'moment'
import leadingzero from 'leadingzero';

export const parseAPIResponseError = error => {
  if (error && error.source && error.source.pointer) {
    return {
      field: error.source.pointer.split('/').pop(),
      errorMsg: error.detail
    }
  }
  return {
    field: '',
    errorMsg: ''
  };
};

const validationChecker = (fieldValue, validationType, validationCondition, formValues, validationChecker) => {
  let errorMessage = '';
  switch (validationType) {
    case 'isSameAs':
      errorMessage = formValues[validationChecker.field] === formValues[validationChecker.isSameAs.field] ? '' : `${validationChecker.fieldName} should be same as ${validationChecker.isSameAs.fieldName}!`;
      break;
    case 'isEmail':
      //eslint-disable-next-line
      var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,25})$/;
      errorMessage = validationCondition === true && regEmail.test(fieldValue) === false ? `${validationChecker.fieldName} is not valid!` : '';
      break;
    case 'isPhoneNumber':
      //eslint-disable-next-line
      const regPhone = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      //const regPhone = /^[6-9]\d{9}$/;
      //var phoneNum = fieldValue.replace(/[^\d]/g, '');
      // if(!fieldValue.match(regPhone)){
      errorMessage = fieldValue.match(regPhone) ? '' : `${validationChecker.fieldName} is not valid!`;
      // errorMessage = validationCondition === true && (phoneNum.length > 6 && phoneNum.length < 11) === false ? `${validationChecker.fieldName} is not valid!` : '';
      //}
      //errorMessage =  validationCondition === true && regPhone.test(fieldValue) ? `Is not a phone number` : '';
      break;
    case 'requiredIf':
      errorMessage = formValues[validationCondition.field] === validationCondition.value ? fieldValue && fieldValue.length === 0 ? `${validationChecker.fieldName} is required!` : '' : '';
      break;
    case 'minLength':
      errorMessage = validationCondition > fieldValue.length ? `${validationChecker.fieldName} must contain atleast ${validationCondition} characters!` : '';
      break;
    case 'isLength':
      if (fieldValue !== undefined && fieldValue !== null) {
        errorMessage = parseInt(validationCondition) !== fieldValue.toString().length ? `${validationChecker.fieldName} must contain ${validationCondition} character(s)!` : '';
      }
      break;
    case 'isNumber':
      const regexNumber = /^[0-9]*$/;
      errorMessage = validationCondition === true && regexNumber.test(fieldValue) === false ? `${validationChecker.fieldName} must be a number!` : '';
      break;
    case 'isAlpha':
      const regexAlpha = /^[A-Za-z]*$/;
      errorMessage = validationCondition === true && regexAlpha.test(fieldValue) === false ? `${validationChecker.fieldName} must be an alphabet!` : '';
      break;
    case 'isRequired':
      if (fieldValue !== undefined && fieldValue !== null) {
        errorMessage = validationCondition === true && fieldValue.length === 0 ? `${validationChecker.fieldName} is required!` : "";
      }
      break;
    case 'isBirthDay':
      const sixteenYearsAgo = moment().subtract(16, "years");
      if (fieldValue.length === 0) {
        errorMessage = validationCondition === true ? "Date Of Birth is required!" : "";
      } else {
        const dob = moment(fieldValue);
        if (!dob.isValid()) {
          errorMessage = validationCondition === true ? "Date Of Birth is not valid!" : "";
        }
        else if (sixteenYearsAgo.isAfter(dob)) {
          errorMessage = "";
        }
        else {
          errorMessage = "You must be atleast 16 years!"
        }
      }
      break;
    default:
      break;
  }
  return errorMessage;
};

export const validatorModule = (formValues, validations) => {
  let errors = [];
  _.map(validations, validator => {
    let item = formValues[validator.field];
    Object.keys(validator).forEach(val => {
      if (val !== 'field' && val !== 'fieldName') {
        let response = validationChecker(item, val, validator[val], formValues, validator);
        if (response !== '') {
          errors[validator.field] = response;
        }
      }

    });
  });
  return errors;

};

export const parseIdentificationType = user => {
  const regex = /^NULL?/gi;
  let resp =  (user.nricAlpha !== null && user.nricAlpha !== "")? `${leadingzero(user.nricNumber,3)}${user.nricAlpha}(NRIC)` : (user.finAlpha !== null && user.finAlpha !== "") ? `${leadingzero(user.finNumber,3)}${user.finAlpha}(FIN)` : `${user.passport}(Passport)`;
  return resp.match(regex) === null ? resp : '-';
};
