import React from "react";
import moment from "moment";
import _ from "lodash";

const VerificationComponent = ({
    verificationData,
    previousStep,
    onFinish,
    manageStepper,
    nationality,
    companies,
    contracts,
    applicantType,
    isEdit,
    tradeInformations
}) => {
    const onPreviousClick = () => {
        previousStep();
        manageStepper('prev');
    };

    const onNextClick = () => {
        onFinish();
    };

    const country = _.keyBy(nationality, "value");
    const company = _.keyBy(companies, "id");
    const contract = _.keyBy(contracts, "id");

    const getTradeInformation = (tradeInformations, id) => {
        var result = _.find(tradeInformations, { 'tradeInformation': [{ id }] })
        if (result) {
            const resp = _.keyBy(result.tradeInformation, "id");
            return resp[id].name;
        }
    }

    //console.log(verificationData)
    return (
        <div id="step-4" className="step700">
            <form id="form-step-4" className="tooltip-label-right">
                <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Full Name</label>
                        <div className="text-capitalize">{verificationData.fullName}</div>
                    </div>
                    <div className="form-group position-relative error-l-75 col-md-6">
                        {verificationData.nricNumber !== null && verificationData.nricNumber !== '' &&
                            <>
                                <label htmlFor="purpose-of-visit" className="font-weight-bold">NRIC Number</label>
                                <div className="text-uppercase">{verificationData.nricNumber + '' + verificationData.nricAlpha}</div>
                            </>}

                        {verificationData.finNumber !== null && verificationData.finNumber !== '' && <>
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">FIN Number</label>
                            <div className="text-uppercase">{verificationData.finNumber + '' + verificationData.finAlpha}</div>
                        </>}

                        {verificationData.passport !== null && verificationData.passport !== '' && <>
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">Passport Number</label>
                            <div className="text-uppercase">{verificationData.passport}</div>
                        </>}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Mobile Number</label>
                        <div>{verificationData.mobileNumber}</div>
                    </div>
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Email</label>
                        <div>{verificationData.email}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Date Of Birth</label>
                        <div>{moment(verificationData.dateOfBirth).format("DD/MM/YYYY")}</div>
                    </div>
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Gender</label>
                        <div className="text-capitalize">{verificationData.gender}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Nationality</label>
                        <div className="text-capitalize">{country[verificationData.nationality] && country[verificationData.nationality].display_name}</div>
                    </div>
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Vehicle Number</label>
                        <div className="text-uppercase">{verificationData.vehicleNumber}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Employer/Company Name</label>
                        <div className="text-capitalize">{company[verificationData.company] && company[verificationData.company].name}</div>
                    </div>
                    { (applicantType !== "visitor" && applicantType !== "delivery_adhoc") &&
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">TWRP Contract Name</label>
                        <div className="text-capitalize">{contract[verificationData.contract] && contract[verificationData.contract].name}</div>
                    </div>}
                </div>

                {applicantType === "delivery_adhoc" && !isEdit &&
                    <div className="row">
                        <div className="form-group position-relative error-l-75 col-md-6">
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">PO Number</label>
                            <div className="text-capitalize">{verificationData.poNumber}</div>
                        </div>
                        <div className="form-group position-relative error-l-75 col-md-6">
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">DO Number</label>
                            <div className="text-capitalize">{verificationData.doNumber}</div>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Job Appointment/Designation</label>
                        <div className="text-capitalize">{verificationData.designation}</div>
                    </div>
                    {(verificationData.identification_type === "fin") &&
                        <div className="form-group position-relative error-l-75 col-md-6">
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">Expiry Date of Work Permit</label>
                            <div>{moment(verificationData.workPermitExpiry).format("DD/MM/YYYY")}</div>
                        </div>
                    }
                </div>

                {(applicantType === "visitor" || applicantType === "delivery_adhoc") && !isEdit && <div className="row">
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Date Of Visit</label>
                        <div>{verificationData.validFromDate !== '' ? `${moment(verificationData.validFromDate).format("DD/MM/YYYY")}` : '-'}</div>
                    </div>
                    <div className="form-group position-relative error-l-75 col-md-6">
                        <label htmlFor="purpose-of-visit" className="font-weight-bold">Host Name</label>
                        <div className="text-capitalize">{verificationData.hostName}</div>
                    </div>
                </div>
                }
                {(applicantType === "visitor" || applicantType === "delivery_adhoc") && !isEdit &&
                    <div className="row">
                        <div className="form-group position-relative error-l-75 col-md-6">
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">Purpose Of Visit</label>
                            <div className="text-capitalize">{verificationData.purpose}</div>
                        </div>
                    </div>
                }

                {(applicantType === "staff" || applicantType === "delivery_regular") &&
                    <div className="row">
                        <div className="form-group position-relative error-l-75 col-md-6">
                            <label htmlFor="purpose-of-visit" className="font-weight-bold">Trade Information</label>
                            <div className="text-capitalize">{getTradeInformation(tradeInformations, verificationData.tradeInformation)}</div>
                        </div>
                    </div>
                }

            </form>
            <div className="btn-toolbar custom-btn-toolbar text-center">
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onPreviousClick()}>Previous</button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onNextClick()}>Finish</button>
            </div>
        </div>)
};

export default VerificationComponent;
