/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";
import {parseIdentificationType} from "../../helpers/helpers";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
import ExportButton from "../../components/common/Export/Export";
import { AppContext } from "../../context/AppContext";

const ContractorBasedApplicantLists = ({ location }) => {
  const [exportData, setexportData] = React.useState({});
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [applicantType, setApplicantType] = React.useState(undefined);
  const [searchQueryString, setSearchQueryString] = React.useState('');
  const [contractor, setContractor] = React.useState('');

  const { initialPageParams, updateInitialPageParams, setPageUrlParams } = React.useContext(AppContext);

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(1);

  const token = localStorage.getItem('access_token');
  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {};
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const {data:applicantsData, meta, isFetching } = useQuery(['applicant', 'profile', {
    filter: {
      contractor,
      live: 'True',
      search: searchQueryString,
      type: applicantType
    },
    page: paginationSettings,
    include: "company,contract"
  }], { client });

  const viewApplicant = (id, type) => {
    history.push({
      pathname: VW_ROUTES.APPLICANTS_APPLICANT_VIEW,
      search: `?id=${id}&type=${type}&from=dashboard`,
    });
  };

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }

    let params = queryString.parse(location.search);
    setContractor(params.id);

    setexportData(
      {
        page:'live_count_by_contract',
        contractorId:params.id,
        filters:{
          search : searchQueryString,
          type: applicantType
        }
      }
    )
  }, []);


  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(initialPageParams.initialPageNumber);
      setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
      setApplicantType(initialPageParams.applicantType?initialPageParams.applicantType:undefined);
    }
  }, [initialPageParams]);


  React.useEffect(() => {
    setexportData(
      {
        page:'live_count_by_contract',
        contractorId:contractor,
        filters:{
          search : searchQueryString,
          type: applicantType
        }
      }
    )
  }, [searchQueryString, applicantType, contractor]);


  const customStyles = {

    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    }
  };

  // const parseIdentificationType = user => {
  //   return user.nricAlpha !== "" ? `${user.nricNumber}${user.nricAlpha}(NRIC)` : user.finAlpha !== "" ? `${user.finNumber}${user.finAlpha}(FIN)` : `${user.passport}(Passport)`
  // };

  const columns = [
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: true,
      style:'text-transform:capitalize'
    },
    {
      name: 'Type',
      cell: applicant => <span className="text-capitalize">{applicant.applicantType.replace(/_/g, " ")}</span>,
      sortable: false
    },
    {
      name: 'Identification#',
      sortable: false,
      cell: applicant => <span className="text-uppercase text-left">{parseIdentificationType(applicant)}</span>
    },
    {
      name: 'Contract',
      selector: 'contract.name',
      sortable: true
    },
    {
      name: 'Company',
      selector: 'company.name',
      sortable: true
    },
    {
      name: 'Designation',
      selector: 'designation',
      sortable: true
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true
    },
    {
      name: 'Actions',
      sortable: true,
      cell: applicant => <><span className="simple-icon-eye user-role-action-icons view" onClick={() => viewApplicant(applicant.id, applicant.applicantType)}></span> </>,
      right: true
    }
  ];

  let paginationGoing = false;
    const onChangePage = (page) => {
      setSearchGoingOn(false);
      paginationGoing = true;
      setCurrentPageNumber(page);
      const setParams = {
        page: page,
        searchQueryString: searchQueryString,
        initialPageParams: initialPageParams,
        applicantType : applicantType
      };
      setPageUrlParams(setParams);
    };

    const onChangeRowsPerPage = (rows) => {
      setRowsPerPage(rows);
    };

    let searchGoing = false;
    const onSearchInputChange = (searchQuery) => {
      searchGoing = true;
      setSearchGoingOn(true);
      setSearchQueryString(searchQuery);
      setactivePage(1);
      const setParams = {
        page: 1,
        searchQueryString: searchQuery,
        initialPageParams: initialPageParams,
        applicantType : applicantType
      };
      setPageUrlParams(setParams);
    };


    window.onpopstate = (e) => {
      if(!searchGoing && !paginationGoing){
        const searchWord = window.location.hash.split("search=")[1];
        const wordString = decodeURI(searchWord);
        if (searchWord) {
          setSearchQueryString(wordString);
        }else{
          setSearchQueryString('');
        }
        const applicantType = window.location.hash.split("applicant=")[1];
        if(applicantType){
          const applicant = applicantType.split("&")[0];
          setApplicantType(applicant)
        }else{
          setApplicantType(undefined)
        }
        const pageNumber = window.location.hash.split("page=")[1];
        if(pageNumber){
          const pageFromUrl = pageNumber.split("&")[0];
          setCurrentPageNumber(Number(pageFromUrl))
        }else{
          setCurrentPageNumber(1)
        }
      }else{
        searchGoing = false;
        paginationGoing = false
      }
    };

    const searchByApplicantType = (e) =>{
      searchGoing = true
      if(e.target.value !== ""){
        setApplicantType(e.target.value);
        const setParams = {
          page: 1,
          searchQueryString: searchQueryString,
          initialPageParams: initialPageParams,
          applicantType: e.target.value
        };
        setPageUrlParams(setParams);

      }else{
        setApplicantType(undefined);
        const setParams = {
          page: 1,
          searchQueryString: searchQueryString,
          initialPageParams: initialPageParams,
        };
        setPageUrlParams(setParams);
      }
    }
  const FilterSection = () => {
    return (<div className="advance-filter mr-1">
      <select value={applicantType} onChange={e => searchByApplicantType(e)} className="form-control">
        <option value="">Filter By Type</option>
        <option value="visitor">Visitor</option>
        <option value="staff">Staff</option>
        <option value="delivery_adhoc">Delivery Adhoc</option>
        <option value="delivery_regular">Delivery Regular</option>
      </select>
    </div>);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>Applicants Lists By Contractor</h1>
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <DataTable
                subHeader={true}
                subHeaderComponent={<><FilterSection/><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/> <ExportButton exportDetails={exportData}/></>}
                columns={columns}
                data={applicantsData}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                progressPending={isFetching}
                progressComponent={<Loader />}
                paginationTotalRows={meta && meta.pagination.count}
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ContractorBasedApplicantLists;
