/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import _ from "lodash";
import moment from "moment";
import { parseIdentificationType } from "../../helpers/helpers";
import { AppContext } from "../../context/AppContext";
import { API_BASE_URL,SET_PAGE_COUNT } from "../../constants";
import Loader from "../../components/common/Loader/Loader";
import BCAReportFilter from "../../components/common/ReportFilter/BCAReportFilter";



const BCAReport = () => {
  const [nationality, setNationality] = React.useState([]);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState('');
  const [selectedNationality, setSelectedNationality] = React.useState(undefined);
  const [selectedContractor, setSelectedContractor] = React.useState(undefined);
  const [selectedCompany, setSelectedCompany] = React.useState(undefined);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQueryString, setSearchQueryString] = React.useState(undefined);
  const [applicantType, setApplicantType] = React.useState(undefined);
  const { manageLoader } = React.useContext(AppContext);
  const [disableExport, setdisableExport] = React.useState(true);
  const [reportFromDate, setReportFromDate] = React.useState(moment(moment().subtract(1, 'M').toDate()).format("YYYY-MM-DD"));
  const [reportToDate, setReportToDate] = React.useState(moment().format("YYYY-MM-DD"));




  const token = localStorage.getItem('access_token');
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`
  };
  const { data: companies } = useQuery(['organisation', 'company', {
    page: {
      number: 1,
      size: SET_PAGE_COUNT
    },
  }], { client });
  const { data: contracts } = useQuery(['organisation', 'contract', {
    page: {
      number: 1,
      size: SET_PAGE_COUNT
    }
  }], { client });

  React.useEffect(() => {
    const options = {
      method: 'OPTIONS',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'Authorization': `JWT ${token}`
      },
      url: `${API_BASE_URL}/applicant/profile`,
    };
    axios(options).then(response => {
      const { data: {
        data: {
          actions: {
            POST: {
              nationality: {
                choices: nationalityChoices
              }
            }
          }
        }
      } } = response;
      setNationality(nationalityChoices);
    });
  }, []);




  let filter;

  if(reportFromDate && reportToDate){
    filter = {
      type: applicantType,
      search: searchQueryString,
      nationality: selectedNationality,
      contractor: selectedContractor,
      company: selectedCompany,
    }
  }else{
    filter = {
      type: applicantType,
      search: searchQueryString,
      nationality: selectedNationality,
      contractor: selectedContractor,
      company: selectedCompany
    }
  }

  const { data: applicants, meta, isFetching } = useQuery(['reports', 'bca', {
    filter: filter,
    start_date : reportToDate?reportFromDate:undefined,
    end_date : reportToDate,
    page: {
      number: currentPageNumber,
      size: rowsPerPage
    },
    include: "company,contract"
  }], { client });

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    }
  };


  const columns = [
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: true,
      cell: applicant => <span className="text-capitalize">{`${applicant.fullName}`}</span>
    },
    {
      name: 'Identification#',
      sortable: true,
      cell: applicant => <span className="text-uppercase text-left">{parseIdentificationType(applicant)}</span>
    },
    {
      name: 'Mobile#',
      selector: 'mobileNumber',
      sortable: true
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Designation',
      selector: 'designation',
      sortable: true
    },
    // {
    //   name: 'Applicant Type',
    //   cell: applicant => <span className="m-auto text-capitalize">{_.replace(applicant.applicantType, '_', ' ')}</span>
    // },
    {
      name: 'Nationality',
      sortable: true,
      cell: applicant => _.keyBy(nationality, 'value') && _.keyBy(nationality, 'value')[applicant.nationality] && _.keyBy(nationality, 'value')[applicant.nationality].display_name
    },
    {
      name: 'Company',
      sortable: true,
      cell: applicant => applicant.company && applicant.company.name
    },
    {
      name: 'Contractor',
      sortable: true,
      cell: applicant => applicant.contract && applicant.contract.name
    },
    {
      name: 'Status',
      cell: applicant => <span className="text-capitalize">{applicant.status}</span>
    },
  ];

  // const onFilterChange = option => {
  //   let date
  //   if(option.value){
  //     date = moment(option.value).format("YYYY-MM-DD")
  //   }else{
  //     date = undefined
  //   }

  //   switch (option.name) {
  //     case 'fromDate':
  //         setReportFromDate(date)
  //         setReportToDate(undefined)
  //         break;
  //     case 'toDate':
  //         setReportToDate(date)
  //         break;
  //     default:
  //         break;
  //   }
  // };

  const startDateChange = value => {
    setReportFromDate(moment(value).format("YYYY-MM-DD"));
  }

  const endDateChange = value => {
    setReportToDate(moment(value).format("YYYY-MM-DD"))
  }

  const onChangePage = (page) => {
    setCurrentPageNumber(page)
  }

  const onSearchInputChange = searchQuery => {
    if(searchQuery){
      setSearchQueryString(searchQuery);
    }else{
      setSearchQueryString(undefined);
    }

  };

  const onChangeRowsPerPage = rows => {
    setRowsPerPage(rows);
  }

  const intiateExportFn = () => {
    onExport()
  };

  React.useEffect(() => {
    if(applicants?.length > 0){
      setdisableExport(false)
    }else{
      setdisableExport(true)
    }
  },[applicants])



  const onExport = () => {
    let filter = `start_date=${reportFromDate}&end_date=${reportToDate}`;
    if (searchQueryString !== "" && searchQueryString !== undefined) {
      filter += `&filter[search]=${searchQueryString}`;
    }
    if (selectedNationality !== undefined) {
      filter += `&filter[nationality]=${selectedNationality}`;
    }
    if (selectedContractor !== undefined) {
      filter += `&filter[contractor]=${selectedContractor}`;
    }
    if (selectedCompany !== undefined) {
      filter += `&filter[company]=${selectedCompany}`;
    }

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${token}`
      },
      url: `${API_BASE_URL}/reports/bca/export?${filter}`,
    };
    axios(options).then(response => {
      manageLoader(false);
      setShowSuccessModal(true);
      setShowSuccessMessage(response.data.data.message)
    });
  };


  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage('')
  };

  const disableExportButton =() => {
    setdisableExport(true);
  }

  const enableExport = () => {
    if(applicants?.length > 0){
      setdisableExport(false);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>BCA Report</h1>
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body remove-header">
              <BCAReportFilter
                onNationalityChange={setSelectedNationality}
                onCompanyChange={setSelectedCompany}
                onContractorChange={setSelectedContractor}
                onApplicantTypeChange={setApplicantType}
                nationality={nationality}
                companies={companies}
                contracts={contracts}
                onSearchInputChange={onSearchInputChange}
                onExport={intiateExportFn}
                buttonVisibility={disableExport}
                exportButtonForceDisabled = {disableExportButton}
                enableExport = {enableExport}
                onStartDateChange={startDateChange}
                onEndDateChange={endDateChange}
              />
              <DataTable
                subHeader={true}
                //subHeaderComponent={<> <button className="btn btn-primary">Export</button><SearchBar onSearchInputChange={onSearchInputChange} /></>}
                progressPending={isFetching}
                progressComponent={<Loader />}
                columns={columns}
                data={applicants}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={meta && meta.pagination.count}
              />
            </div>
          </div>
        </div>
      </div>

  <div className={`reportStatusPopup ${showSuccessModal ? 'show' : ''}`}>
        <div className="popUp">
          <div className='content'>
            {showSuccessMessage}
          </div>
          <div className="action">
            <button className="btn btn-primary" onClick={closeReportSuccessPopup}>OK</button>
          </div>
        </div>
  </div>
    </>
  );
};

export default BCAReport;
