import React from "react";
import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";

const DashboardPendingCounter = ({ dashboardStats }) => {
    const history = useHistory();
    const navidateToPendingLists = (type) => {
        history.push({
            pathname: VW_ROUTES.APPLICANTS_PENDING,
            search: `?type=${type}`,
        });
    };

    return (
        <div className="col-lg-6 col-md-12 mb-4">
            <h5 className="mb-4">Pending Approvals</h5>
            <div className="row pending-approvals">


                <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('staff')}>
                            <i className="iconsminds-male"></i>
                            <p className="card-text mb-0">Staff</p>
                            <p className="lead text-center">{dashboardStats && dashboardStats.applicantPendingStats.staff}</p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('visitor')}>
                            <i className="iconsminds-business-mens"></i>
                            <p className="card-text mb-0">Visitor</p>
                            <p className="lead text-center">{dashboardStats && dashboardStats.applicantPendingStats.visitor}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('delivery_regular')}>
                            <i className="iconsminds-box-close"></i>
                            <p className="card-text mb-0">Delivery Regular</p>
                            <p className="lead text-center">{dashboardStats && dashboardStats.applicantPendingStats.deliveryRegular}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 mb-3">
                    <div className="card">
                        <div className="card-body text-center" onClick={() => navidateToPendingLists('delivery_adhoc')}>
                            <i className="iconsminds-box-with-folders"></i>
                            <p className="card-text mb-0">Delivery Ad-hoc</p>
                            <p className="lead text-center">{dashboardStats && dashboardStats.applicantPendingStats.deliveryAdhoc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DashboardPendingCounter;