import React from "react";
import _ from  "lodash";
import ActionsTree from "../ActionsTree/ActionsTree";

const UserRoleFormComponent = ({
	title,
	formState,
	payloadData,
	systemResources,
	updateInputChange,
	updatePermissions,
	dashboardTypes

}) => {
	return (
		<>
			<p>{title}</p>
			<div className="form-group position-relative">
				<label><b>Role Name</b></label>
				<input type="text" name="name" value={formState.name} className="form-control" placeholder="Role Name" onChange={(e) => updateInputChange(e.target)} />
			</div>
			<div className="form-group position-relative">
				<label><b>Dashboard</b></label>
				<select value={formState.dashboard} name="dashboard" className="form-control" onChange={(e) => updateInputChange(e.target)} >
					<option value="">Select Dashboard</option>
					{_.map(dashboardTypes, dashboard => <option key={_.uniqueId('dashboard-')} value={dashboard.value}>{dashboard.display_name}</option>)}
				</select>
			</div>
			<div className="form-group position-relative">
				<label><b>Permissions</b></label>
			</div>
			<ActionsTree treePayload={payloadData} actionsTreeData={systemResources} updatePermissionsSelected={updatePermissions} />
		</>
	);
};

export default UserRoleFormComponent;