import React from "react";
import _ from "lodash";
import { useHistory } from 'react-router-dom';
import { VW_ROUTES } from '../../constants';
import NavigationLink from "../../components/common/NavigationLink/NavigationLink";
//import { getMenuItems } from "../../services/appServices";
import {useClient, useQuery } from "jsonapi-react";
import { useLocation } from 'react-router-dom';
import { getCustomMenuItems } from "../../services/appServices";



export const NavigationSidebar = ({subSidebarHidden, hideSubSidebar, user}) => {
    const history = useHistory();
    const location = useLocation();
    const [menuItems, setMenuItems] = React.useState([]);
    const [subMenuItems, setSubMenuItems] = React.useState([]);
  const token = localStorage.getItem('access_token');
    const client = useClient();
    let selectedMenu = 0;
    client.config.headers = {
        Authorization: `JWT ${token}`
     };

    const {data, error} = useQuery(['system','menu',user.role],{client});
    if(error !== undefined && error.detail === "Signature has expired."){
        history.push(VW_ROUTES.LOGIN);
    }
    React.useEffect(() => {
        if(data && data.config){
            setMenuItems([...data.config, ...getCustomMenuItems()]);}
    }, [data]);

    // const onSubMenuNavigationItemClick = (e, route) => {
    //     history.push(VW_ROUTES[selectedMenu.route]);
    // };


    let menuRef = React.useRef();

    React.useEffect(() => {
        const checkIfClickedOutside = e => {
            if (!subSidebarHidden && menuRef.current && !menuRef.current.contains(e.target)) {
                hideSubSidebar(true);
            }
          }
          document.addEventListener("mousedown", checkIfClickedOutside)
         return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
          }
        // eslint-disable-next-line
    }, [subSidebarHidden]);


    const onNavigationItemClick = (e, id) => {
        e.preventDefault();
        selectedMenu = _.keyBy(menuItems, 'id')[id];
        setSubMenuItems(selectedMenu.subMenu);
        if(!_.isEmpty(selectedMenu.subMenu)){
            hideSubSidebar(false);
        }else{
            hideSubSidebar(true);
            const toRoute = String(VW_ROUTES[selectedMenu.route] ?? "");
            if(toRoute.includes("[new-tab]"))
                window.open(toRoute.replace("[new-tab]", ""), "_blank")
            else
                history.push(`${VW_ROUTES[selectedMenu.route]}`);
        }
    };

    const onSubmenuNavigationItemClick = (e,route) => {
        e.preventDefault();
        history.push(route);
    };

    const sidebarUI = () => {
        return (_.map(menuItems, menuItem => <NavigationLink currentPage={location.pathname} onNavigationItemClickCallback={onNavigationItemClick} key={_.uniqueId('menu_')} item={menuItem} />));
    };

    const subMenuItem = () => {
        return _.map(subMenuItems, item => (<li key={_.uniqueId('submenu-')}>
            <a href={'dummy'} onClick={(e) => onSubmenuNavigationItemClick(e, VW_ROUTES[item.route])}>
                {/* <i className={item.icon}></i>  */}
                <span
                    className="d-inline-block">{item.title}</span>
            </a>
            {item.subMenu && <ul className="list-unstyled inner-level-menu ml-4">
                {innerSubMenu(item.subMenu)}
            </ul>}
        </li>));
    };

    const innerSubMenu = innerSub => {
        return _.map(innerSub, item => {

            return (<li key={_.uniqueId('submenu-')}>
             <a href={VW_ROUTES[item.route]}>
                <i className={item.icon}></i> <span
                    className="d-inline-block">{item.title}</span>
            </a>
        </li>)});
    };

    return (
        <div className="menu" ref={menuRef}>
            <div className="main-menu default-transition">
                <div className="scroll">
                    <ul className="list-unstyled">
                        {sidebarUI()}
                    </ul>
                </div>
            </div>

            <div className="sub-menu default-transition">
                <div className="scroll">
                    <ul className={`list-unstyled ${subSidebarHidden ? 'd-none' : 'd-block'}`} data-link="applicantlist" id="applicantlist">
                        <li>
                            { /* eslint-disable-next-line */}
                            <a href="#" data-toggle="collapse" data-target="#collapseAuthorization" aria-expanded="true"
                                aria-controls="collapseAuthorization" className="rotate-arrow-icon opacity-50">
                                <i className="simple-icon-arrow-down"></i> <span className="d-inline-block">Sub Menu</span>
                            </a>
                            <div id="collapseAuthorization" className="collapse show">
                                <ul className="list-unstyled inner-level-menu">
                                 {subMenuItem()}
                                </ul>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NavigationSidebar;
