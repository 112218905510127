//import { manageSlidesData } from "../mappers/dashboardMappers";

export const ACTION_TYPES = {
    UPDATE_PERMISSIONS_SELECTED: 'updatePermissionsSelected'
};

export const UserGroupReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_PERMISSIONS_SELECTED:
            return {
                ...state,
                permissionsSelected: action.payload
            }
      
        default:
            return state
    }
}

