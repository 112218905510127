import React from "react";
import _ from "lodash";
import moment from "moment";
import { validatorModule } from "../../../helpers/helpers";
import DatePicker from "react-datepicker";

const AdditionalInfoComponent = ({ identificationType, companies, contracts, previousStep, manageStepper, onStepComplete, nextStep, applicantType, isEdit, applicantProfile, acRestrictedParam, currentStep }) => {
  const AddInfoFirstInput = React.useRef();

  const validationFormat = [
        {
            field: "company",
            isRequired: true,
            fieldName: "Company"
        },
        {
            field: "vehicleNumber",
            type: 'string',
            isRequired: (applicantType === "delivery_adhoc" || applicantType === "delivery_regular")  ? true : false,
            fieldName: "Vehicle Number"
        },
        {
            field: "designation",
            isRequired: true,
            fieldName: "Designation"
        },
        {
            field: "workPermitExpiry",
            isRequired: identificationType === "fin" ? true : false,
            fieldName: "Work Permit Expiry"
        },
        {
            field: "contract",
            isRequired: (applicantType === "staff" || applicantType === "delivery_regular") ? true : false,
            fieldName: "TWRP Contract Name"
        }

    ];
    //console.log('identificationType',identificationType)
    const initialState = {
        company: '',
        designation: '',
        vehicleNumber: '',
        workPermitExpiry: '',
        contract: ''
    };

    const initialErrorState = {
        company: '',
        designation: '',
        vehicleNumber: '',
        workPermitExpiry: '',
        contract: ''
    };

    const [formState, setFormState] = React.useState(initialState);
    const [errorState, setErrorState] = React.useState(initialErrorState);
    const onInputChange = ({ name, value }) => {
        let formData = { ...formState };
        formData[name] = value;
        setFormState(formData);
    };

    const onPreviousClick = () => {
        previousStep();
        manageStepper('prev');
    };

    React.useEffect(() => {
        if (isEdit && applicantProfile) {
            const updatedState = {
                company: applicantProfile.company && applicantProfile.company.id,
                contract: applicantProfile.contract && applicantProfile.contract.id,
                designation: applicantProfile.designation,
                vehicleNumber: applicantProfile.vehicleNumber,
                workPermitExpiry: applicantProfile.workPermitExpiry === null ? '' : new Date(moment(applicantProfile.workPermitExpiry, 'DD/MM/YYYY')),
            };
            setFormState(updatedState);
        }
    }, [isEdit, applicantProfile]);

    React.useEffect(() => {
      if(currentStep === 2){
        setTimeout(()=>{
          if(AddInfoFirstInput){
            AddInfoFirstInput.current.focus();
          }
        },1000)
      }
    },[currentStep])


    const onFocusChange = () => {
        if (_.filter(Object.values(errorState)).length !== 0) {
            let errors = validatorModule(formState, validationFormat);
            setErrorState(errors);
        }
    };

    const onNextClick = () => {
        let errors = validatorModule(formState, validationFormat);
        setErrorState(errors);
        if (Object.keys(errors).length === 0) {
            onStepComplete(formState);
            nextStep();
            manageStepper('next');
        }
    };
    return (

        <div id="step-2" className="step700">
            <form id="form-step-2" className="tooltip-label-right">
                <div className="form-row">
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.vehicleNumber ? 'hasValidationError' : ''}`}>
                        <label htmlFor="vehicleNumber">Vehicle Number{(applicantType === "delivery_adhoc" || applicantType === "delivery_regular") ? "*" : ""}</label>
                        <input type="text" ref={AddInfoFirstInput} className="form-control" name="vehicleNumber" value={formState.vehicleNumber}
                            onBlur={() => onFocusChange()} id="vehicleNumber" placeholder="Vehicle Number" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.vehicleNumber ? errorState.vehicleNumber : ''}</p>
                    </div>
                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.company ? 'hasValidationError' : ''}`}>
                        <label htmlFor="company">Company Name*</label>
                        <select onBlur={() => onFocusChange()} value={formState.company} onChange={(e) => onInputChange(e.target)} name="company" className="form-control select2-single select2-hidden-accessible" data-width="100%" aria-hidden="true">
                            <option value="">Select</option>
                            {_.map(_.orderBy(companies,'name', 'asc'), company => <option key={_.uniqueId('company-')} value={company.id}>{company.name}</option>)}
                        </select>
                        <p className="error-message">{errorState && errorState.company ? errorState.company : ''}</p>
                    </div>
                </div>
                <div className="form-row">


                    <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.designation ? 'hasValidationError' : ''}`}>
                        <label htmlFor="designation">Job Appointment/Designation*</label>
                        <input onBlur={() => onFocusChange()} type="text" className="form-control" name="designation" value={formState.designation}
                            id="designation" placeholder="Job Appointment/Designation" onChange={(e) => onInputChange(e.target)} />
                        <p className="error-message">{errorState && errorState.designation ? errorState.designation : ''}</p>
                    </div>

                    {identificationType === "fin" &&
                        <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.workPermitExpiry ? 'hasValidationError' : ''}`}>
                            <label htmlFor="designation">Work Permit Expiry*</label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                minDate={moment().toDate()}
                                onBlur={() => onFocusChange()}
                                //startDate={new Date("01/01/1950")}
                                selected={formState.workPermitExpiry}
                                onChange={date => onInputChange({ name: 'workPermitExpiry', value: date })}
                            />
                            <p className="error-message">{errorState && errorState.workPermitExpiry ? errorState.workPermitExpiry : ''}</p>
                        </div>

                }

                </div>

                {(applicantType === "staff" || applicantType === "delivery_regular") &&
                    <div className="form-row">
                <div className={`form-group position-relative error-l-100 col-md-6 ${errorState.contract ? 'hasValidationError' : ''}`}>
                        <label htmlFor="email">TWRP Contract Name*</label>
                        <select disabled={acRestrictedParam} onBlur={() => onFocusChange()} value={formState.contract} onChange={(e) => onInputChange(e.target)} name="contract" className="form-control select2-single select2-hidden-accessible" data-width="100%" aria-hidden="true">
                            <option value="">Select</option>
                            {_.map(contracts, contract => <option key={_.uniqueId('contract-')} value={contract.id}>{contract.name}</option>)}
                        </select>
                        <p className="error-message">{errorState && errorState.contract ? errorState.contract : ''}</p>
                    </div>
                </div> }

                {/* {identificationType === "fin" &&
                    <div className="form-row">
                        <div className={`form-group position-relative error-l-75 col-md-6 ${errorState.workPermitExpiry ? 'hasValidationError' : ''}`}>
                            <label htmlFor="designation">Work Permit Expiry</label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                minDate={moment().toDate()}
                                onBlur={() => onFocusChange()}
                                //startDate={new Date("01/01/1950")}
                                selected={formState.workPermitExpiry}
                                onChange={date => onInputChange({ name: 'workPermitExpiry', value: date })}
                            />
                            <p className="error-message">{errorState && errorState.workPermitExpiry ? errorState.workPermitExpiry : ''}</p>
                        </div>
                    </div>
                } */}

            </form>
            <div className="btn-toolbar custom-btn-toolbar text-center">
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onPreviousClick()}>Previous</button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onNextClick()}>Next</button>
            </div>
        </div>);
};

export default AdditionalInfoComponent;
