/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from 'jsonapi-react';
import DataTable from 'react-data-table-component';
import { useHistory } from "react-router-dom";
import { VW_ROUTES } from "../../constants";
import queryString from 'query-string';
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
// import axios from 'axios';
// import { API_BASE_URL } from "../../constants";
import ExportButton from "../../components/common/Export/Export";
import { AppContext } from "../../context/AppContext";

const ExpiryStatus = ({location}) => {


    const { initialPageParams, updateInitialPageParams, setPageUrlParams } = React.useContext(AppContext);
    const [exportData, setexportData] = React.useState({});
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState('');
    const [pageParams, setPageParams] = React.useState({});
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const token = localStorage.getItem('access_token');
    const history = useHistory();
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };
    const [searchQueryString, setSearchQueryString] = React.useState('');
    const [searchGoingOn, setSearchGoingOn] = React.useState(false);
    const [activePage, setactivePage] = React.useState(0);


    const userType = { staff: 'Staffs', delivery: 'Delivery Regular', 'delivery_adhoc': 'Delivery Adhoc','delivery_regular': 'Delivery Regular', 'visitor': 'Visitor'};
    const listType = { workpermit: 'Work Permit Expiry Status', training: 'Training Expiry Status', expired : 'Expired Work Permits'};
    const listName = { workpermit: 'wp_expiry_list', training: 'training_expiry_list', expired : 'expired_work_permit_status'};




    let includes;
    let filters;
    let apiRoute;

    if(listName[pageParams.list] && listName[pageParams.list] === "training_expiry_list"){
      includes = "company,applicant_training"
    }

    if(listName[pageParams.list] && listName[pageParams.list] === "expired_work_permit_status"){
      if(searchQueryString){
        filters = {
          type: pageParams.type,
          wp_expired:"True",
          search: searchQueryString
        }
      }else{
        filters = {
          type: pageParams.type,
          wp_expired:"True",
        }
      }


      apiRoute = ''
    }else {
      filters = {
        type: pageParams.type,
        search: searchQueryString
      }

      apiRoute = listName[pageParams.list];
    }



    let paginationSettings;
    if(searchGoingOn){
      paginationSettings = {}
    }else{
      paginationSettings = {
        number: currentPageNumber,
        size: rowsPerPage
      }
    }

    const staffs = useQuery(['applicant', 'profile',apiRoute, {
        filter: filters,
        page: paginationSettings,
        include:includes
    }], { client });

    let expiryColumn;
    if(listName[pageParams.list] && listName[pageParams.list] === "training_expiry_list"){
      expiryColumn = {
        name: 'Expiry Date',
        selector: 'applicantTraining[0].expiresOn',
        sortable: true,
        width:140
      }
    }else{
      expiryColumn = {
        name: 'Expiry Date',
        selector: 'workPermitExpiry',
        sortable: true,
        width:140
      }
    }
  //



    const viewStaff = id => {
        history.push({
            pathname: VW_ROUTES.APPLICANTS_APPLICANT_VIEW,
            search: `?id=${id}&from=expiry&list=${pageParams.list}&type=${pageParams.type}`,
        });
    };
    React.useEffect(() => {
      if (location) {
        updateInitialPageParams(location);
      }
      let params = queryString.parse(location.search);
      setPageParams(params);
      setexportData({
        page: "expiry",
        params: pageParams,
        filters: {
          search: "",
        },
      });
    }, []);

    React.useEffect(() => {
      if(initialPageParams){
        setCurrentPageNumber(initialPageParams.initialPageNumber);
        setactivePage(initialPageParams.initialPageNumber);
        setSearchQueryString(initialPageParams.initialSearchKey)
      }

    },[initialPageParams]);

    React.useEffect(() => {
      setexportData(
        {
          page:'expiry',
          params:pageParams,
          filters:{
            search : searchQueryString
          }
        }
      )
    }, [searchQueryString, pageParams]);


    const customStyles = {

        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '14px'
            },
        }
    };

    const columns = [
        {
            name: 'Full Name',
            selector: 'fullName',
            sortable: true,
            style:"text-transform:capitalize"
        },

        {
            name: 'Mobile#',
            selector: 'mobileNumber',
            sortable: true
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true
        },
        {
            name: 'Vehicle#',
            selector: 'vehicleNumber',
            sortable: false
        },
        {
            name: 'Designation',
            selector: 'designation',
            sortable: true
        },
        expiryColumn,

        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width:100
        },
        {
            name: 'Actions',
            sortable: true,
            cell: staff => <><span className="simple-icon-eye user-role-action-icons view" onClick={() => viewStaff(staff.id)}></span></>,
            right: true,
            width:50
        }
    ];

    let paginationGoing = false;
    const onChangePage = page => {
      setSearchGoingOn(false);
      paginationGoing = true;
      setCurrentPageNumber(page);
      const setParams = {
        page : page,
        searchQueryString : searchQueryString,
        initialPageParams: initialPageParams
      }
      setPageUrlParams(setParams)
    }

    const onChangeRowsPerPage = rows => {
        setRowsPerPage(rows);
    }

    let searchGoing = false;
    const onSearchInputChange = (searchQuery) => {
      searchGoing = true;
      setSearchGoingOn(true);
      setSearchQueryString(searchQuery);
      setactivePage(1);
      const setParams = {
        page : 1,
        searchQueryString : searchQuery,
        initialPageParams: initialPageParams
      }
      setPageUrlParams(setParams)
    };



    window.onpopstate = (e) => {
      if(!searchGoing && !paginationGoing){
        const searchWord = window.location.hash.split("search=")[1];
        const wordString = decodeURI(searchWord);
        if (searchWord) {
          setSearchQueryString(wordString);
        }else{
          setSearchQueryString('');
        }
        const pageNumber = window.location.hash.split("page=")[1];
        if(pageNumber){
          const pageFromUrl = pageNumber.split("&")[0];
          setCurrentPageNumber(Number(pageFromUrl))
        }else{
          setCurrentPageNumber(1)
        }
      }else{
        searchGoing = false;
        paginationGoing = false
      }
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage('')
  };

    return (
        <>
            <div className="row">
                <div className="col-12">
    <h1>{listType[pageParams.list]} - {userType[pageParams.type]}</h1>
                    <div className="separator mb-5"></div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                            subHeader={true}
                            subHeaderComponent={<><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/><ExportButton exportDetails={exportData}/></>}
                            progressPending={staffs.isLoading}
                            progressComponent={<Loader/>}
                                columns={columns}
                                data={staffs.data}
                                customStyles={customStyles}
                                paginationServer={true}
                                striped={true}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                pagination={true}
                                paginationPerPage={rowsPerPage}
                                paginationTotalRows={staffs.meta && staffs.meta.pagination.count}
                                paginationDefaultPage={activePage}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`reportStatusPopup ${showSuccessModal ? 'show' : ''}`}>
                <div className="popUp">
                  <div className='content'>
                    {showSuccessMessage}
                  </div>
                  <div className="action">
                    <button className="btn btn-primary" onClick={closeReportSuccessPopup}>OK</button>
                  </div>
                </div>
          </div>
        </>
    );
};

export default ExpiryStatus;
