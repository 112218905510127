import React from "react";
import moment from "moment";
import leadingzero from 'leadingzero';

const ViewGatePass = ({ applicantType, passData, currentUser, applicant, nationality }) => {
    //const status = passData.status == 1 ? 'approved' : passData.status == 0 ? 'rejected': 'pending'
    var stillUtcFrom = moment(passData.fromDateTime).toDate();
    var toLocalFromDateTime = moment(stillUtcFrom).utcOffset('+0800').format('DD/MM/YYYY hh:mm:ss A');
    var stillUtcTo = moment(passData.toDateTime).toDate();
    var toLocalToDateTime = moment(stillUtcTo).utcOffset('+0800').format('DD/MM/YYYY hh:mm:ss A');
    return (
        <div className="profile-details-wrapper row">
            <div className="col-md-12">
                {currentUser.dashboard !== "ac_dashboard" && currentUser.role_slug !== "pub-jacobs" &&
                    <div className="row">
                        <div className="col-md-12 col-sm-12 pr-md-0 text-center p-0">
                            <img src={passData.qrCode} alt="" className="img-qr-code img-thumbnail img-rounded" />
                        </div>

                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Full Name </div>
                            <h5 className="profile-label-value font-weight-bold">{applicant && applicant.fullName}</h5>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Email</div>
                            <h5 className="profile-label-value font-weight-bold"> {applicant && applicant.email}</h5>
                        </div>
                    </div>
                    {applicant && applicant.nricNumber !== null &&
                        <div className="col-sm-12 col-md-6">
                            <div className="profile-container">
                                <div className="profile-label mt-2"> NRIC </div>
                                <h5 className="profile-label-value font-weight-bold text-uppercase"> {applicant && leadingzero(applicant.nricNumber, 3) + applicant.nricAlpha} </h5>
                            </div>
                        </div>
                    }

                    {applicant && applicant.finNumber !== null &&
                        <div className="col-sm-12 col-md-6">
                            <div className="profile-container">
                                <div className="profile-label mt-2"> FIN </div>
                                <h5 className="profile-label-value font-weight-bold text-uppercase"> {applicant && leadingzero(applicant.finNumber, 3) + applicant.finAlpha} </h5>
                            </div>
                        </div>
                    }

                    {applicant && (applicant.passport !== null && applicant.passport !== "") &&
                        <div className="col-sm-12 col-md-6">
                            <div className="profile-container">
                                <div className="profile-label mt-2"> Passport</div>
                                <h5 className="profile-label-value font-weight-bold text-uppercase"> {applicant && applicant.passport} </h5>
                            </div>
                        </div>
                    }
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Date of Birth </div>
                            <h5 className="profile-label-value font-weight-bold">{applicant && applicant.dateOfBirth}</h5>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Nationality </div>
                            <h5 className="profile-label-value font-weight-bold">{applicant && nationality && nationality[applicant.nationality] && nationality[applicant.nationality].display_name} </h5>
                        </div>
                    </div>
                    <div className="ccol-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2">Contract </div>
                            <h5 className="profile-label-value font-weight-bold">{passData.contract && passData.contract.name ? passData.contract.name : "-"}</h5>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Valid From </div>
                            <h5 className="profile-label-value font-weight-bold">{toLocalFromDateTime}</h5>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Valid To </div>
                            <h5 className="profile-label-value font-weight-bold"> {toLocalToDateTime}</h5>
                        </div>
                    </div>
                </div>


                {applicantType === "delivery_adhoc" &&
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="profile-container">
                                <div className="profile-label mt-2"> PO Number </div>
                                <h5 className="profile-label-value font-weight-bold"> {passData.poNumber} </h5>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="profile-container">
                                <div className="profile-label mt-2"> DO Number </div>
                                <h5 className="profile-label-value font-weight-bold"> {passData.doNumber} </h5>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Purpose Of Visit </div>
                            <h5 className="profile-label-value font-weight-bold"> {passData.purposeOfVisit}</h5>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2"> Host Name </div>
                            <h5 className="profile-label-value font-weight-bold text-capitalize">{passData.hostName} </h5>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="profile-container">
                            <div className="profile-label mt-2">Vehicle Number </div>
                            <h5 className="profile-label-value font-weight-bold">{passData.vehicleNumber ? passData.vehicleNumber : "-"}</h5>
                        </div>
                    </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="profile-container">
                                <div className="profile-label mt-2">Status </div>
                                <h5 className="profile-label-value font-weight-bold text-capitalize">{passData && passData.status}</h5>
                            </div>
                        </div>
                </div>

                <div className="row">

                    {passData.status === 'rejected' &&
                        <div className="col-sm-12 col-md-12">
                            <div className="profile-container">
                                <div className="profile-label mt-2">Reject Note </div>
                                <h5 className="profile-label-value font-weight-bold text-capitalize">{passData.rejectNote === null ? "-" : passData.rejectNote}</h5>
                            </div>
                        </div>}
                </div>

            </div>
        </div>
    );
};

export default ViewGatePass;
