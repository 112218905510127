import { VW_ROUTES} from "../constants";
//get dashboard sumup counters
export const getSumUpCounters = () => {
    return ([
        {
          "id": 1,
          "title": "people counter",
          "type": "standard",
          "count": 4567,
          "metaData": [{
            "isLink": false,
            "key": "Last Update on:",
            "value": "09:35pm"
          }]
        },
        {
          "id": 2,
          "title": "traffic counter",
          "type": "standard",
          "count": 1234,
          "metaData": [{
            "isLink": false,
            "key": "Last Update on:",
            "value": "09:35pm"
          }]
        },
        {
          "id": 3,
          "title": "alert",
          "type": "alert",
          "count": 1,
          "hasLink": false,
          "metaData": [{
            "isLink": true,
            "key": "View Details",
            "value": "/notfound"
          }]
        }
      ]);
};

//get dashboard splitup counters
export const getSplitUpCounters = (details) => {
  const routeArray = {
    staff: VW_ROUTES.APPLICANTS_STAFF,
    visitor: VW_ROUTES.APPLICANTS_VISITIOR,
    deliveryRegular: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR,
    deliveryAdhoc: VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC
  };

  if(details === undefined){
    return [];
  }
  return Object.keys(details).map(function(key, i){
    return {
      "id": i+1,
      "title": key,
      "type": "standard",
      "count": details[key].approved,
      "hasLink": true,
      "route": routeArray[key],
      "metaData": [{
              "isLink": false,
              "key": "Total:",
              "value": details[key].total
            },
          {
              "isLink": false,
              "key": "Pending:",
              "value": details[key].pending
            }]
    }

    // return ([
    //     {
    //       "id": 1,
    //       "title": "Staff",
    //       "type": "standard",
    //       "count": 678,
    //       "metaData": [{
    //         "isLink": false,
    //         "key": "Total:",
    //         "value": 968
    //       },
    //     {
    //         "isLink": false,
    //         "key": "Approved:",
    //         "value": 745
    //       }]
    //     },
    //     {
    //       "id": 2,
    //       "title": "Visitor",
    //       "type": "standard",
    //       "count": 789,
    //       "metaData": [{
    //         "isLink": false,
    //         "key": "Total:",
    //         "value": 964
    //       },
    //     {
    //         "isLink": false,
    //         "key": "Approved:",
    //         "value": 765
    //       }]
    //     },
    //     {
    //       "id": 3,
    //       "title": "Delivery",
    //       "type": "standard",
    //       "count": 745,
    //       "metaData": [{
    //         "isLink": false,
    //         "key": "Total:",
    //         "value": 964
    //       },
    //     {
    //         "isLink": false,
    //         "key": "Approved:",
    //         "value": 765
    //       }]
    //     },
    //     {
    //       "id": 4,
    //       "title": "Adhoc-delivery",
    //       "type": "standard",
    //       "count": 876,
    //       "metaData": [{
    //         "isLink": false,
    //         "key": "Total:",
    //         "value": 987
    //       },
    //     {
    //         "isLink": false,
    //         "key": "Approved:",
    //         "value": 76
    //       }]
    //     }
    //   ]);
  });
};


//get dashboard contractor slider data
export const getContractorSliderData = () => {
    return ([
        {
          "id": 1,
          "title": "C1A",
          "count": "053"
        },
        {
          "id": 2,
          "title": "C2A",
          "count": "054"
        },
        {
          "id": 3,
          "title": "C3A",
          "count": "055"
        },
        {
          "id": 4,
          "title": "C4A",
          "count": "056"
        },
        {
          "id": 5,
          "title": "C5A",
          "count": "057"
        },
        {
          "id": 6,
          "title": "C6A",
          "count": "058"
        },
        {
          "id": 7,
          "title": "C7A",
          "count": "059"
        },
        {
          "id": 8,
          "title": "C8A",
          "count": "060"
        },
        {
          "id": 9,
          "title": "C9A",
          "count": "061"
        },
        {
          "id": 10,
          "title": "C10A",
          "count": "062"
        },
        {
          "id": 11,
          "title": "C11A",
          "count": "063"
        },
        {
          "id": 12,
          "title": "C12A",
          "count": "064"
        },
        {
          "id": 13,
          "title": "C13A",
          "count": "065"
        },
        {
          "id": 14,
          "title": "C14A",
          "count": "066"
        },
        {
          "id": 15,
          "title": "C15A",
          "count": "067"
        },
        {
          "id": 16,
          "title": "C16A",
          "count": "068"
        },
        {
          "id": 17,
          "title": "C17A",
          "count": "069"
        },
        {
          "id": 18,
          "title": "C18A",
          "count": "070"
        },
        {
          "id": 19,
          "title": "C19A",
          "count": "071"
        },
        {
          "id": 20,
          "title": "C20A",
          "count": "072"
        },
        {
          "id": 21,
          "title": "C21A",
          "count": "073"
        },
        {
          "id": 22,
          "title": "C22A",
          "count": "074"
        },
        {
          "id": 23,
          "title": "C23A",
          "count": "075"
        },
        {
          "id": 24,
          "title": "C24A",
          "count": "076"
        },
        {
          "id": 25,
          "title": "C25A",
          "count": "077"
        },
        {
          "id": 26,
          "title": "C26A",
          "count": "078"
        },
        {
          "id": 27,
          "title": "C27A",
          "count": "079"
        }

      ]);
};


export const getUserRoles = () => {
  return ({
    "links": {
      "first": "http://localhost:8000/api/v1/system/resource?page%5Bnumber%5D=1",
      "last": "http://localhost:8000/api/v1/system/resource?page%5Bnumber%5D=1",
      "next": null,
      "prev": null
    },
    "data": [
      {
        "type": "system",
        "id": "5e401eb5-0a98-4868-a2ad-749cbf9c90fb",
        "attributes": {
          "children": [],
          "code": "dashboard",
          "actions": [
            {
              "name": "View",
              "codename": "view"
            }
          ]
        },
        "links": {
          "self": "http://localhost:8000/api/v1/system/resource/5e401eb5-0a98-4868-a2ad-749cbf9c90fb"
        }
      },
      {
        "type": "system",
        "id": "ca6eae4d-dfab-496b-ac1e-7646eb8b4c08",
        "attributes": {
          "children": [
            {
              "id": "c881653c-966c-4810-ac7b-582585d8d2ec",
              "children": [],
              "code": "staff",
              "url": "http://localhost:8000/api/v1/system/resource/c881653c-966c-4810-ac7b-582585d8d2ec",
              "actions": [
                {
                  "name": "Create",
                  "codename": "create"
                },
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Edit",
                  "codename": "edit"
                },
                {
                  "name": "Delete",
                  "codename": "delete"
                },
                {
                  "name": "Download",
                  "codename": "download"
                },
                {
                  "name": "Print",
                  "codename": "print"
                }
              ]
            },
            {
              "id": "61a4e1e1-9124-4dc9-908c-c13384f4db23",
              "children": [],
              "code": "visitor",
              "url": "http://localhost:8000/api/v1/system/resource/61a4e1e1-9124-4dc9-908c-c13384f4db23",
              "actions": [
                {
                  "name": "Create",
                  "codename": "create"
                },
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Edit",
                  "codename": "edit"
                },
                {
                  "name": "Delete",
                  "codename": "delete"
                },
                {
                  "name": "Download",
                  "codename": "download"
                },
                {
                  "name": "Print",
                  "codename": "print"
                }
              ]
            },
            {
              "id": "e41ce852-4f65-4aa5-988b-248f0184a06b",
              "children": [],
              "code": "delivery-regular",
              "url": "http://localhost:8000/api/v1/system/resource/e41ce852-4f65-4aa5-988b-248f0184a06b",
              "actions": [
                {
                  "name": "Create",
                  "codename": "create"
                },
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Edit",
                  "codename": "edit"
                },
                {
                  "name": "Delete",
                  "codename": "delete"
                },
                {
                  "name": "Download",
                  "codename": "download"
                },
                {
                  "name": "Print",
                  "codename": "print"
                }
              ]
            },
            {
              "id": "56c57dd0-f8f4-42c4-9241-5637b27c6392",
              "children": [],
              "code": "delivery-ad-hoc",
              "url": "http://localhost:8000/api/v1/system/resource/56c57dd0-f8f4-42c4-9241-5637b27c6392",
              "actions": [
                {
                  "name": "Create",
                  "codename": "create"
                },
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Edit",
                  "codename": "edit"
                },
                {
                  "name": "Delete",
                  "codename": "delete"
                },
                {
                  "name": "Download",
                  "codename": "download"
                },
                {
                  "name": "Print",
                  "codename": "print"
                }
              ]
            }
          ],
          "code": "applicants",
          "actions": [
            {
              "name": "Create",
              "codename": "create"
            },
            {
              "name": "View",
              "codename": "view"
            },
            {
              "name": "Edit",
              "codename": "edit"
            },
            {
              "name": "Delete",
              "codename": "delete"
            }
          ]
        },
        "links": {
          "self": "http://localhost:8000/api/v1/system/resource/ca6eae4d-dfab-496b-ac1e-7646eb8b4c08"
        }
      },
      {
        "type": "system",
        "id": "7456fedf-6e3f-48a4-b177-54554503c43b",
        "attributes": {
          "children": [],
          "code": "user-management",
          "actions": [
            {
              "name": "Create",
              "codename": "create"
            },
            {
              "name": "View",
              "codename": "view"
            },
            {
              "name": "Edit",
              "codename": "edit"
            },
            {
              "name": "Delete",
              "codename": "delete"
            }
          ]
        },
        "links": {
          "self": "http://localhost:8000/api/v1/system/resource/7456fedf-6e3f-48a4-b177-54554503c43b"
        }
      },
      {
        "type": "system",
        "id": "a1fe0cc3-87a9-4281-af60-ab94fbed4f03",
        "attributes": {
          "children": [
            {
              "id": "0161a454-183f-4147-9a40-5e12ad382ae6",
              "children": [],
              "code": "roles",
              "url": "http://localhost:8000/api/v1/system/resource/0161a454-183f-4147-9a40-5e12ad382ae6",
              "actions": [
                {
                  "name": "Create",
                  "codename": "create"
                },
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Edit",
                  "codename": "edit"
                },
                {
                  "name": "Delete",
                  "codename": "delete"
                }
              ]
            },
            {
              "id": "fb71ae7e-8d8c-4f45-8161-31e266b1144c",
              "children": [],
              "code": "permissions",
              "url": "http://localhost:8000/api/v1/system/resource/fb71ae7e-8d8c-4f45-8161-31e266b1144c",
              "actions": [
                {
                  "name": "Create",
                  "codename": "create"
                },
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Edit",
                  "codename": "edit"
                },
                {
                  "name": "Delete",
                  "codename": "delete"
                }
              ]
            }
          ],
          "code": "roles-and-permissions",
          "actions": [
            {
              "name": "Create",
              "codename": "create"
            },
            {
              "name": "View",
              "codename": "view"
            },
            {
              "name": "Edit",
              "codename": "edit"
            },
            {
              "name": "Delete",
              "codename": "delete"
            }
          ]
        },
        "links": {
          "self": "http://localhost:8000/api/v1/system/resource/a1fe0cc3-87a9-4281-af60-ab94fbed4f03"
        }
      },
      {
        "type": "system",
        "id": "91693d8d-e5cd-427b-ae48-ef09d52153f0",
        "attributes": {
          "children": [
            {
              "id": "836723b4-dfd9-45fa-89cb-439d50633162",
              "children": [
                {
                  "id": "0ff7befe-ca58-4321-92d6-0c7161b6d067",
                  "children": [],
                  "code": "test-report",
                  "url": "http://localhost:8000/api/v1/system/resource/0ff7befe-ca58-4321-92d6-0c7161b6d067",
                  "actions": [
                    {
                      "name": "View",
                      "codename": "view"
                    }
                  ]
                }
              ],
              "code": "general-reports",
              "url": "http://localhost:8000/api/v1/system/resource/836723b4-dfd9-45fa-89cb-439d50633162",
              "actions": [
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Download",
                  "codename": "download"
                },
                {
                  "name": "Print",
                  "codename": "print"
                }
              ]
            },
            {
              "id": "83baf31e-3e59-4ed0-8cbe-0f226f2c688a",
              "children": [],
              "code": "bca-reports",
              "url": "http://localhost:8000/api/v1/system/resource/83baf31e-3e59-4ed0-8cbe-0f226f2c688a",
              "actions": [
                {
                  "name": "View",
                  "codename": "view"
                },
                {
                  "name": "Download",
                  "codename": "download"
                },
                {
                  "name": "Print",
                  "codename": "print"
                }
              ]
            }
          ],
          "code": "reports",
          "actions": [
            {
              "name": "View",
              "codename": "view"
            }
          ]
        },
        "links": {
          "self": "http://localhost:8000/api/v1/system/resource/91693d8d-e5cd-427b-ae48-ef09d52153f0"
        }
      }
    ],
    "meta": {
      "pagination": {
        "page": 1,
        "pages": 1,
        "count": 5
      }
    }
  });
};

