import React from "react";
import _ from "lodash";
import moment from "moment";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { AppContext } from "../../../context/AppContext";


const AcsReportFilter = ({ onSearchInputChange, onEventTimeFromChange, onContractorChange, onApplicantTypeChange, onEventTimeToChange, contracts, onExport, onResetInitiated, buttonVisibility }) => {
    
    const [searchText, setSearchText] = React.useState('');
    const [fromDate, setFromDate] = React.useState(moment().subtract(1, 'M').toDate());
    const [toDate, setToDate] = React.useState(moment().toDate());
    const [contract, setContract] = React.useState('');
    const [type, setType] = React.useState('');
    const onSubmitSearch = () => {
        onSearchInputChange(searchText);
    };

    const { pagePermissions } = React.useContext(AppContext);
    const parseToSelectFormat = (options, keys) => _.map(options, option => ({label:option[keys.label] , value: option[keys.value]}));
    const applicantType = [
        { label: "Visitor", value: 'visitor' },
        { label: "Staff", value: 'staff' },
        { label: "Delivery Regular", value: 'delivery_regular' },
        { label: "Delivery Adhoc", value: 'delivery_adhoc' },
        { label: "Override Pass", value: 'OVERRIDE_PASS' }
      ];

    const onFilterChange = option => {
        const { name, value, label } = option;
        switch (name) {
            case 'toDate':
                setToDate(moment(value === null ? moment().toDate() : value).format("YYYY-MM-DD"));
                onEventTimeToChange(value === null ? moment().toDate() : value);
                break;
                case 'fromDate':
                    setFromDate(moment(value === null ? moment().subtract(1, 'M').toDate() : value).format("YYYY-MM-DD"))
                    onEventTimeFromChange(value === null ? moment().subtract(1, 'M').toDate() : value);
                    break;
            case 'type':
                setType({label, value});
                onApplicantTypeChange(value === "" ? "" : value);
                break;
            case 'contract':
                setContract({label, value});
                onContractorChange(value === "" ? "" : value);
                break;
            default:
                break;
        }
    };

    const resetFilterOptions = () => {
        setFromDate("");
        onEventTimeFromChange("");
        setToDate("");
        onEventTimeToChange("");
        setType("");
        onApplicantTypeChange("");
        setContract("");
        onContractorChange("");
        onSearchInputChange("");
        setSearchText("");
        onResetInitiated();
    };

    return (
        <div className="reports-filter-section pb-3">
            <div className="row mb-2">
                <div className="col-6">
                    <span>Contractor</span>
                    <Select value={contract} options={parseToSelectFormat(contracts, {label: 'name', value: 'id'})} onChange={opt => onFilterChange({name: 'contract', value: opt.value, label: opt.label})}  name="contract" placeholder="Select" />
                </div>
                <div className="col-6">
                    <span>Applicant Type</span>
                    <Select value={type} options={applicantType} onChange={opt => onFilterChange({name: 'type', value: opt.value, label: opt.label})}  name="type" placeholder="Select" />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-6">
                    <span>Event Date From</span>
                    <DatePicker maxDate={moment().toDate()} selected={fromDate === "" ? "" : new Date(fromDate)} onChange={date => onFilterChange({ name: 'fromDate', value: date, label:'' })} className="form-control" placeholderText="YYYY-MM-DD" dateFormat="yyyy-MM-dd" />
                </div>
                <div className="col-6">
                    <span>Event Date To</span>
                    <DatePicker maxDate={moment().toDate()} selected={toDate === "" ? "" : new Date(toDate)} onChange={date => onFilterChange({ name: 'toDate', value: date, label:'' })} className="form-control" placeholderText="YYYY-MM-DD" dateFormat="yyyy-MM-dd" />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-6">
                    <span>Search</span>
                    <div className="report-search-container">
                    <input type="text" className="form-control" name="search" value={searchText}
                        id="search" placeholder="Type minimum two characters for searching." onChange={(e) => setSearchText(e.target.value)}  />
                    <button className="" onClick={(e) => onSubmitSearch()}><span className="simple-icon-magnifier"></span></button>
                    </div>
                </div>
                <div className="col-6 d-flex align-items-end">
                    {_.includes(pagePermissions, 'download') && <button onClick={onExport} className="btn btn-primary float-right rounded-0" disabled={buttonVisibility}>Export</button>}
                    <button onClick={resetFilterOptions} className="btn btn-primary float-right rounded-0 ml-1">Reset</button>
                </div>
            </div>

        </div>

    );
};

export default AcsReportFilter;
