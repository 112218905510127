import React from "react";
import Slider from "react-slick";
import { useClient } from 'jsonapi-react';
import axios from "axios";
import _ from "lodash";
import { API_BASE_URL } from "../../constants";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const CaptureImageComponent = ({ status, profileImages, applicantId, onPictureCapture }) => {
	//console.log(profileImages)
	const videoElement = document.getElementById('videoElement');
	const canvasElement = document.getElementById('canvasElement');
	const [camOrientation, setCamOrientation] = React.useState(0);
	const token = localStorage.getItem('access_token');
	const client = useClient();
	client.config.headers = {
		Authorization: `JWT ${token}`
	};
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5

	};

	React.useEffect(() => {
		//const localStream = stream;
		// if (status) {
		if (navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({ video: true })
				.then(function (stream) {
					if (status) {
						videoElement.srcObject = stream;
					} else {
						let tracks = stream.getTracks();
						tracks.forEach(function (track) {
							track.stop();
						});
						videoElement.srcObject = null;
					}
				})
				.catch(function (err0r) {
					//console.log("Something went wrong!");
				});
		}
	}, [videoElement, status]);




	//const errBack = () => {};

	const urltoFile = (url, filename, mimeType) => {
		return (fetch(url)
			.then(function (res) { return res.arrayBuffer(); })
			.then(function (buf) { return new File([buf], filename, { type: mimeType }); })
		);
	}

	const uploadProfileImages = files => {
		let formData = new FormData();
		formData.append('applicant', applicantId);

		_.map(files, file => {
			formData.append('avatar', file);
		})

		const config = {
			headers: { 'Authorization': `JWT ${token}`, 'content-type': 'multipart/form-data' }
		}

		const url = `${API_BASE_URL}/applicant/profile/${applicantId}/photo`;
		axios.post(url, formData, config)
			.then(response => {
				//console.log(response);
				onPictureCapture();
			})
			.catch(error => {
				console.log(error);
			});
	};

	const captureImageFromCam = async () => {
    	var ctx=canvasElement.getContext("2d");
		ctx.clearRect(0,0,canvasElement.width,canvasElement.height);
		ctx.fillStyle = "#FFF";
    	ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);
        ctx.save();
        ctx.translate(canvasElement.width/2,canvasElement.height/2);
		ctx.rotate(camOrientation*Math.PI/180);
		ctx.drawImage(videoElement,-videoElement.width/2,-videoElement.height/2);
        ctx.restore();
		var dataURL = canvasElement.toDataURL('image/jpeg', 0.5);
		urltoFile(dataURL, `${_.uniqueId('image-')}.jpeg`, 'image/jpeg')
			.then(async (file) => {
				let formData = new FormData();    //formdata object
				formData.append('applicant', applicantId);   //append the values with key, value pair
				formData.append('avatar', file);
				const config = {
					headers: { 'Authorization': `JWT ${token}`, 'content-type': 'multipart/form-data' }
				}
				const url = `${API_BASE_URL}/applicant/profile/${applicantId}/photo`;
				axios.post(url, formData, config)
					.then(response => {
						//console.log(response);
						onPictureCapture();
					})
					.catch(error => {
						console.log(error);
					});

			});
	};

	const rotateCam = () => {
		setCamOrientation(camOrientation === 360 ? 0 : camOrientation + 90);
	};


	return (
		<>
			<div className="camera-element-wrapper embed-responsive embed-responsive-16by9">
				<video style={{transform: `rotateZ(${camOrientation}deg)`}} autoPlay={true} width="640" height="480" id="videoElement" className="embed-responsive-item"></video>
				<canvas style={{transform: `rotateZ(${camOrientation}deg)`}} id="canvasElement" width="640" height="480"></canvas>
				<div onClick={() => captureImageFromCam()} className="capture-btn"><i className="simple-icon-camera"></i></div>
				<div onClick={() => rotateCam()} className="rotate-btn"><i className="simple-icon-refresh"></i></div>
			</div>
			<div className="row">
				<div className="customFileUpload ml-auto mr-auto mt-2">
					Choose File
					<input type="file" className="hide_file" onChange={(e) => uploadProfileImages(e.target.files)} accept="image/*" id="profileImages" name="profileImages" />
				</div>
				{/* <input type="file" onChange={(e) => uploadProfileImages(e.target.files)} accept="image/*" id="profileImages" name="profileImages" multiple></input> */}
				<div className="col-12">
					<div className="icon-cards-row row">
						<Slider {...settings}>
							{_.map(profileImages, profileImage => (
								<div key={_.uniqueId('prof-image-')} className="captured-profile-image">
									<img className="visitor-image" src={profileImage.avatar} alt="prof" />
								</div>
							))}
						</Slider>
					</div>

				</div>
			</div>
		</>
	);
};

export default CaptureImageComponent;
