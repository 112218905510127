import { manageSlidesData } from "../mappers/dashboardMappers";

export const ACTION_TYPES = {
    UPDATE_SUMUP_COUNTERS: 'updateSumupCounters',
    UPDATE_SPLITUP_COUNTERS: 'updateSplitUpCounters',
    UPDATE_CONTRACTOR_CAROUSEL_LISTS: 'updateContractorCarouselLists'
};

export const DashboardReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_SUMUP_COUNTERS:
            return {
                ...state,
                sumUpCounters: action.payload
            }
        case ACTION_TYPES.UPDATE_SPLITUP_COUNTERS:
            return {
                ...state,
                splitUpCounters: action.payload
            }
        case ACTION_TYPES.UPDATE_CONTRACTOR_CAROUSEL_LISTS:
            return {
                ...state,
                contractorCarouselLists: manageSlidesData(action.payload)
            }
        default:
            return state
    }
}

