import React from "react";
import { LineChart, BarChart } from "../Charts";
import { chartTooltip } from "../Charts/util";
import _ from "lodash";

const DashboardChartArea = ({ weeklyVisitData, monthlyVisitData }) => {
  const [monthGraphData, setmonthGraphData] = React.useState({});
  const [monthGraphMaxim, setMonthGraphMaxim] = React.useState(0);

  const [weeklyGraphData, setweeklyGraphData] = React.useState({});
  const [weeklyGraphDataMaxim, setweeklyGraphDataMaxim] = React.useState(0);

  const [barchartStepSize, setbarchartStepSize] = React.useState(100);

  const [monthChartType, setmonthChartType] = React.useState("sNd");
  const [weekChartType, setweekChartType] = React.useState("sNdLine");

  const getMonthGraphData = (switchData, type) => {
    let dataClone;

    if (type === "monthly" && monthlyVisitData.graphData) {
      setmonthChartType(switchData);
      dataClone = { ...monthlyVisitData.graphData };
      if (switchData === "vNd") {
        setbarchartStepSize(100);
      } else {
        setbarchartStepSize(10000);
      }
    } else if (type === "weekly" && weeklyVisitData.graphData) {
      setweekChartType(switchData);
      dataClone = { ...weeklyVisitData.graphData };
    }

    if (dataClone) {
      if (switchData === "vNd" || switchData === "vNdLine") {
        const filterBy = dataClone.datasets?.filter((item) => {
          return item.label === "Visitor" || item.label === "Delivery Adhoc";
        });
        dataClone.datasets = filterBy;
      } else {
        const filterBy = dataClone.datasets?.filter((item) => {
          return item.label === "Staff" || item.label === "Delivery Regular";
        });
        dataClone.datasets = filterBy;
      }

      let dataArray = [];
      if(dataClone.datasets.length > 0){
        dataClone.datasets.map((item) => {
          return item.data.map((value) => {
            return dataArray.push(value);
          });
        });
      }
      if (type === "monthly") {
        setmonthGraphData(dataClone);
        setMonthGraphMaxim(_.max(dataArray));
      } else {
        setweeklyGraphData(dataClone);
        setweeklyGraphDataMaxim(_.max(dataArray));
      }
    }
  };

  const barChartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 30,
        usePointStyle: true,
        fontSize: 12,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: "rgba(0,0,0,0.1)",
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: Math.floor(barchartStepSize),
            min: 0,
            max: monthGraphMaxim,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: chartTooltip,
  };

  const chartOptions = {
    stepSize: Math.floor(500),
    min: 0,
    max: weeklyGraphDataMaxim,
    padding: 20,
  };

  React.useEffect(() => {
    if (weeklyVisitData.graphData?.datasets) {
      getMonthGraphData("sNdLine", "weekly");
    }

    if (weeklyVisitData.graphData?.datasets) {
      getMonthGraphData("sNd", "monthly");
    }
    // eslint-disable-next-line
  }, [weeklyVisitData, monthlyVisitData]);

  return (
    <div className="row my-4">
      <div className="dashboard-pchart col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5 btm-graph">
        <div className="card">
          <div className="card-inner">
            <div className="header-div">
              <h5 className="mb-4">Weekly Entry</h5>
              <div className="btn-holder">

                <button
                  className={weekChartType === "sNdLine" ? "active" : ""}
                  onClick={() => getMonthGraphData("sNdLine", "weekly")}
                >
                  Staff & Delivery Regular
                </button>

                <button
                  className={weekChartType === "vNdLine" ? "active" : ""}
                  onClick={() => getMonthGraphData("vNdLine", "weekly")}
                >
                  Visitor & Delivery Adhoc
                </button>

              </div>
            </div>
            <div className="graph-cover">
              <LineChart
                chartOptions={chartOptions}
                shadow
                data={weeklyGraphData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-pchart col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5 btm-graph">
        <div className="card">
          <div className="card-inner">
            <div className="header-div">
              <h5 className="mb-4">Last 3 Months Entry</h5>

              <div className="btn-holder">

              <button
                  className={monthChartType === "sNd" ? "active" : ""}
                  onClick={() => getMonthGraphData("sNd", "monthly")}
                >
                  Staff & Delivery Regular
                </button>

                <button
                  className={monthChartType === "vNd" ? "active" : ""}
                  onClick={() => getMonthGraphData("vNd", "monthly")}
                >
                  Visitor & Delivery Adhoc
                </button>

              </div>
            </div>
            <div className="graph-cover">
              <BarChart
                chartOptions={barChartOptions}
                data={monthGraphData === undefined ? {} : monthGraphData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardChartArea;
