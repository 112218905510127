import React from "react";

const CompanyFormComponent = ({
	title,
	companyName,
	updateInputChange
}) => {
	return (
		<>
			<p>{title}</p>
			<div className="form-group position-relative">
				<label><b>Company Name</b></label>
				<input type="text" value={companyName} name="Name" className="form-control" placeholder="Company Name" onChange={(e) => updateInputChange(e.target.value)} />
			</div>
		</>
	);
};

export default CompanyFormComponent;