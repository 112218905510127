const DISPLAY_TEXTS = {

    // Common Texts
    SOMETHING_WRONG: 'Something went wrong',
    //Dashboard

    //User Role
    ROLE_ADDED: 'User role added successfully.',
    ROLE_UPDATED: 'User role updated successfully.',
    ROLE_DELETED: 'User role deleted successfully',
    //Company
    COMPANY_ADDED: 'Company added successfully.',
    COMPANY_UPDATED: 'Company updated successfully.',
    COMPANY_DELETED: 'Company deleted successfully.',
    //Users
    USER_ADDED: 'User added successfully.',
    USER_UPDATED: 'User updated successfully.',
    USER_DELETED: 'User deleted successfully.',

    VISITOR_ADDED: 'Visitor added successfully.',
    VISITOR_UPDATED: 'Visitor updated successfully.',
    VISITOR_DELETED: 'Visitor deleted successfully.',

    STAFF_ADDED: 'Staff added successfully.',
    STAFF_UPDATED: 'Staff updated successfully.',
    STAFF_DELETED: 'Staff deleted successfully.',

    DELIVERY_REGULAR_ADDED: 'Delivery Regular added successfully.',
    DELIVERY_REGULAR_UPDATED: 'Delivery Regular  updated successfully.',
    DELIVERY_REGULAR_DELETED: 'Delivery Regular  deleted successfully.',

    DELIVERY_ADHOC_ADDED: 'Delivery Ad-Hoc added successfully.',
    DELIVERY_ADHOC_UPDATED: 'Delivery Ad-Hoc updated successfully.',
    DELIVERY_ADHOC_DELETED: 'Delivery Ad-Hoc deleted successfully.',

    APPLICANT_APPROVED: 'Applicant approved successfully.',
    APPLICANT_REJECTED: 'Applicant rejected successfully.',

    USER_TRAINING_ADDED: 'User Training added successfully.',
    USER_TRAINING_UPDATED: 'User Training updated successfully.',
    USER_TRAINING_DELETED: 'User Training deleted successfully.',

    PASS_ADDED: 'Gate Pass added successfully.',
    PASS_UPDATED: 'Gate Pass updated successfully.',
    PASSWORD_RESET_MAIL_SUCCESS: 'Password reset e-mail has been sent',

    APPLICANT_PROFILE_CONVERTED: 'Applicant Profile converted successfully',

    APPLICANT_PROFILE_BLOCKED: 'Applicant Profile inactivated successfully',
    APPLICANT_PROFILE_UNBLOCKED: 'Applicant Profile activated successfully',

    TRAINING_STATUS_UPDATED: 'Applicant Training status updated successfully',
    APPLICANT_DELETED: 'Applicant deleted successfully',

    PASSWORD_UPDATED: 'Password changed successfully',

    FILE_ALARM_MESSAGE: 'Fire Alarm Raised',

    APPROVE_GATE_PASS: 'Approved Gate Pass successfully',
    REJECT_GATE_PASS: 'Rejected Gate Pass successfully',
    RESYNC_FAILED: 'Profile re-sync failed',
};

export default DISPLAY_TEXTS;