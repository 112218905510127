/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from 'jsonapi-react';
import DashboardTopNotificationBar from "../../components/DashboardTopNotificationBar/DashboardTopNotificationBar";
import DashboardCounterComponent from "../../components/DashboardCounterComponent/DashboardCounterComponent";
import DashboardContractorSliderComponent from "../../components/DashboardContractorSliderComponent/DashboardContractorSliderComponent";
import DashboardWorkPermitCounterComponent from "../../components/DashboardWorkPermitCounterComponent/DashboardWorkPermitCounterComponent";
import DashboardTrainingCounterComponent from "../../components/DashboardTrainingCounterComponent/DashboardTrainingCounterComponent";
import DashboardPendingCounter from "../../components/DashboardPendingCounter/DashboardPendingCounter";
import { DashboardContext } from "../../context/DashboardContext";
import { AppContext } from "../../context/AppContext";
import DashboardChartArea from "../../components/DashboardChartArea/DashboardChartArea";
// import DashboardChartArea1 from "../../components/DashboardChartArea1/DashboardChartArea1";

import { getSplitUpCounters } from "../../services/dashboardServices";
import { manageSlidesData, mapWeeklyVisitData, mapThreeMonthsVisitData } from "../../mappers/dashboardMappers";
import DashboardexpiredWorkPermitStatusComponent from "../../components/DashboardexpiredWorkPermitStatusComponent/DashboardexpiredWorkPermitStatusComponent";
const Dashboard = () => {
    //const [contractorDetails, setContractorDetails] = React.useState([]);
    const [contractorDetails, setContractorDetails] = React.useState([]);
    const [peopleCounterState, setPeopleCounterState] = React.useState({});
    const [trafficCounterState, setTrafficCounterState] = React.useState({});
    const [alertCounterState, setAlertCounterState] = React.useState({});
    const [weeklyVisitDetails, setWeeklyVisitDetails] = React.useState({
        maxValue: 0,
        graphData: {}
    });
    const [monthlyVisitDetails, setMonthlyVisitDetails] = React.useState({});
    const { splitUpCounters, updateSplitUpCounters } = React.useContext(DashboardContext);
    const { currentUser } = React.useContext(AppContext);
    const token = localStorage.getItem('access_token');
    const client = useClient();
    client.config.headers = {
        Authorization: `JWT ${token}`
    };
    //const contracts = useQuery(['system', 'contract-stats'], { client });
    const { data: dashboardStats } = useQuery(['system', 'dashboard-stats'], { client });
    const {data: weeklyVisitData} = useQuery(['system', 'graph', 'visit', 'weekly'], { client });
    const {data: threeMonthsVisitData} = useQuery(['system', 'graph', 'visit', 'last-three-months'], { client });
    const parseContractData = contracts => {
        return Object.entries(contracts).map(([key, value]) => ({...value,'name': key}));
    };


    React.useEffect(() => {
        const interval = setInterval(() => {
            peopleCounter.refetch();
            trafficCounter.refetch();
            //alertCounter.refetch();
        }, 60000);
        return () => clearInterval(interval);
    }, []);


    React.useEffect(() => {

        if(weeklyVisitData){
        const weeklyVisits = mapWeeklyVisitData(weeklyVisitData.graphData);
        setWeeklyVisitDetails(weeklyVisits);
        }
    }, [weeklyVisitData]);

    React.useEffect(() => {
        if(threeMonthsVisitData){
        const monthlyVisits = mapThreeMonthsVisitData(threeMonthsVisitData.graphData);
        setMonthlyVisitDetails(monthlyVisits);
        }
    }, [threeMonthsVisitData]);

    React.useEffect(() => {
        const resp2 = getSplitUpCounters(dashboardStats && dashboardStats.applicantStats);
        updateSplitUpCounters(resp2);
    }, [dashboardStats]);
    // React.useEffect(() => {
    //     setContractorDetails(manageSlidesData(contracts.data));
    // }, [contracts.data]);
    const peopleCounter = useQuery(['system', 'live', 'people-count'], { client });
    const trafficCounter = useQuery(['system', 'live', 'traffic-count'], { client });
    //const alertCounter = useQuery(['system', 'ism', 'notifications', 'count'], { client });
    const alertCounter = useQuery(['system', 'over-night-stay-count'], { client });



    React.useEffect(() => {
        const contractsData = peopleCounter.data && peopleCounter.data.liveStats && peopleCounter.data.liveStats.contracts;
        setPeopleCounterState({
            peopleCount: peopleCounter.data && peopleCounter.data.liveStats && peopleCounter.data.liveStats.peopleCount,
            lastUpdated: peopleCounter.data && peopleCounter.data.lastUpdated
        });
        setTrafficCounterState(trafficCounter.data);
        setAlertCounterState(alertCounter.data);
        if(contractsData){
            setContractorDetails(manageSlidesData(parseContractData(contractsData)));
        }
    }, [peopleCounter.data, trafficCounter.data, alertCounter.data]);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>Dashboard</h1>
                    <div className="separator mb-5"></div>
                </div>
            </div>
            {currentUser.dashboard === "administrator" && <h5 className="my-5">
                Welcome to Administrator dashboard!
            </h5>}
            {currentUser.dashboard === "ac_dashboard" && <h5 className="my-5">
                Welcome to Account Coordinator dashboard!
            </h5>}
            {(currentUser.dashboard !== "administrator" && currentUser.dashboard !== "ac_dashboard") &&
                <DashboardTopNotificationBar dashboardStats={dashboardStats} dashboard={currentUser.dashboard} pCounter={peopleCounterState} tCounter={trafficCounterState} aCounter={alertCounterState} />
            }
            {currentUser.dashboard === "nsg_dashboard" &&
                <div className="row">

                    <DashboardContractorSliderComponent sliderData={contractorDetails} />

                    <DashboardPendingCounter dashboardStats={dashboardStats} />

                    {/* {currentUser.dashboard === "ac_dashboard" &&
                    <DashboardChartArea1 />
                } */}
                </div>
            }

            {currentUser.dashboard === "nsg_dashboard" &&
                <>
                    <DashboardCounterComponent counters={splitUpCounters} />
                    <div className="row expiry-counter">
                        <DashboardWorkPermitCounterComponent dashboardStats={dashboardStats} />
                        <DashboardTrainingCounterComponent dashboardStats={dashboardStats} />
                        <DashboardexpiredWorkPermitStatusComponent dashboardStats={dashboardStats} />
                    </div>

                    <DashboardChartArea weeklyVisitData={weeklyVisitDetails} monthlyVisitData={monthlyVisitDetails} />
                </>}
        </>
    );
};

export default Dashboard;
